import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import UserRoutes from './UserRoutes'
import NonUserRoutes from './NonUserRoutes'
import { withRouter } from 'react-router-dom'
import { Nav } from 'components'
import { env } from 'lib'

const SwitchRouter = props => {
  const user = useSelector(state => state.user)
  const { screenDimensions } = useSelector(state => state.appState)
  const dispatch = useDispatch()
  const { pathname } = props.location
  const { SCREEN_WIDTH } = env

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    function setScreenDimensions() {
      dispatch({
        type: 'SET_SCREEN_DIMENSION',
        payload: {
          width: window.innerWidth,
          height: window.innerHeight
        }
      })
    }

    let timeout
    setScreenDimensions()
    const handleResize = () => {
      clearTimeout(timeout)

      timeout = setTimeout(() => {
        setScreenDimensions()
      }, 400)
    }
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [dispatch])

  return (
    <div>
      {user ? (
        <React.Fragment>
          {screenDimensions?.width > SCREEN_WIDTH && <Nav />}
          <UserRoutes />
        </React.Fragment>
      ) : (
        <NonUserRoutes />
      )}
    </div>
  )
}

const MainRouter = withRouter(SwitchRouter)

export default MainRouter
