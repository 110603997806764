import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import {
  Dashboard,
  ViewHost,
  ViewGuest,
  Payout,
  Feedback,
  Guests,
  Tours,
  TourGuestsDetails,
  Categories,
  Reports
} from 'screens'

import {
  AddCategory,
  EditCategory
} from 'screens/categories/components'

function UserRoutes() {
  return (
    <Switch>
      <Route exact path='/home' component={Dashboard} />
      <Route exact path='/categories' component={Categories} />
      <Route exact path='/categories/add' component={AddCategory} />
      <Route exact path='/categories/edit' component={EditCategory} />
      <Route exact path='/view/:id/:menu?' component={ViewHost} />
      <Route exact path='/payout' component={Payout} />
      <Route exact path='/guests' component={Guests} />
      <Route exact path='/guest/:id/:menu?' component={ViewGuest} />
      <Route exact path='/feedback' component={Feedback} />
      <Route exact path='/reports' component={Reports} />
      <Route exact path='/tours/:menu?' component={Tours} />
      <Route
        exact
        path='/tour/guests/:id/:menu?'
        component={TourGuestsDetails}
      />
      <Redirect from='*' to='/home' />
    </Switch>
  )
}

export default UserRoutes
