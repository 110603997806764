import { Api } from 'lib'

const deleteCategory = ({ id, action }) => {
  return async dispatch => {
    const res = await Api.delete(`/admin/deleteCategory/${id}`)

    dispatch({
      type: 'LOADING_BUTTON',
      payload: null
    })

    if (!res) return

    action && action(res)
  }
}

export default deleteCategory
