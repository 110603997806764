import React, { useState } from 'react'
import {
  Col,
  Row,
  Text,
  Input,
  MobileHeader,
  Icon,
  MobileDrawer
} from 'components'
import { Table } from './components'
import { useHistory } from 'react-router-dom'

const MobileGuests = props => {
  let { fetchingData, allUsers, onAllPageChange, onSearchChange } =
    props
  const history = useHistory()

  const [openSideBar, setOpenSideBar] = useState(false)
  const toggleSideBar = () => {
    setOpenSideBar(!openSideBar)
  }

  return (
    <React.Fragment>
      <MobileHeader
        fixed
        leftClick={() => toggleSideBar()}
        Left={
          <React.Fragment>
            <Icon type='hamburger-menu' size='20px' />
          </React.Fragment>
        }
        Middle={
          <img
            src={require('assets/trally-mobile.png').default}
            style={{
              width: 70
            }}
            alt='trally-logo'
          />
        }
        middleClick={() => history.push('/')}
        pad='19px 17px 0 20px'
      />
      <div
        style={{ margin: '2rem 1rem', height: 'calc(100vh - 62px)' }}
      >
        <Row>
          <Col>
            <Row>
              <Text
                QS
                size='1.75rem'
                lineHeight='3.75rem'
                weight='700'
              >
                Guests
              </Text>
            </Row>
            <Row marg='2.25rem 0 0 0'>
              <Text QS size='1rem' lineHeight='1.875rem' weight='700'>
                Search Guests
              </Text>
            </Row>
            <Row>
              <Input
                placeholder='Search guests'
                wid='100%'
                borderRadius='30px'
                rounded
                onChange={onSearchChange}
              />
            </Row>
          </Col>
        </Row>
        <Row marg='2rem 0rem'>
          <Table
            caller='mobile'
            viewer='all'
            data={allUsers}
            handler={onAllPageChange}
            fetchingData={fetchingData}
          />
        </Row>
      </div>

      <MobileDrawer
        openSideBar={openSideBar}
        toggleSideBar={toggleSideBar}
      />
    </React.Fragment>
  )
}

export default MobileGuests
