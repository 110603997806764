import React from 'react'
import { Col, Row, Text, Spinner } from 'components'
import {
  BookedTourGuests,
  WatchedTourGuests,
  InterestedTourGuests,
  Menu
} from './components'

const DesktopTourGuests = ({
  fetchingData,
  tourBooked,
  tourWatched,
  tourInterested,
  onBookedSearchChange,
  onWatchedSearchChange,
  onInterestedSearchChange,
  tourId,
  selectedMenu,
  setSelectedMenu,
  history,
  handler
}) => {
  return (
    <div style={{ margin: '8rem 4rem' }}>
      {fetchingData &&
      !tourBooked &&
      !tourWatched &&
      !tourInterested ? (
        <Col>
          <Spinner />
        </Col>
      ) : !tourBooked && !tourWatched && !tourInterested ? (
        <Col centerAll>
          <Text weight='700' size={'16px'} lineHeight='40px' QS>
            No Guests found
          </Text>
        </Col>
      ) : (
        <React.Fragment>
          <Row>
            <Col>
              <Row>
                <Text QS size='32px' lineHeight='140%' weight='bold'>
                  List of Guests for
                  {tourBooked?.tripTitle}
                </Text>
              </Row>
            </Col>
          </Row>

          {tourBooked && tourBooked.isComingSoon ? (
            <InterestedTourGuests
              fetchingData={fetchingData}
              tourInterested={tourInterested}
              onInterestedSearchChange={onInterestedSearchChange}
              handler={handler}
              tripTitle={tourBooked?.tripTitle}
            />
          ) : (
            <React.Fragment>
              <Menu
                selectedMenu={selectedMenu}
                setSelectedMenu={setSelectedMenu}
                tourId={tourId}
                history={history}
              />
              {selectedMenu === 'booked' && (
                <BookedTourGuests
                  fetchingData={fetchingData}
                  tourBooked={tourBooked}
                  onBookedSearchChange={onBookedSearchChange}
                />
              )}

              {selectedMenu === 'watched' && (
                <WatchedTourGuests
                  fetchingData={fetchingData}
                  tourWatched={tourWatched}
                  onWatchedSearchChange={onWatchedSearchChange}
                />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </div>
  )
}

export default DesktopTourGuests
