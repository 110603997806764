import React, { useState } from 'react'
import { FilterButton } from '../../../dashboard/components'
import { Row } from 'components'
import MobileCompletedMenu from './mobileCompletedMenu'
import MobileScheduledMenu from './mobileScheduledMenu'

function MobileToursMenu({ id }) {
  const [activeButton, setActiveButton] = useState('Scheduled')

  const changeFilter = text => {
    setActiveButton(text)
  }

  return (
    <React.Fragment>
      <Row style={{ marginLeft: '1rem' }}>
        {activeButton ? (
          <React.Fragment>
            <FilterButton
              text='Scheduled'
              activeButton={activeButton}
              changeFilter={changeFilter}
            />
            <FilterButton
              text='Completed'
              activeButton={activeButton}
              changeFilter={changeFilter}
            />
          </React.Fragment>
        ) : null}
      </Row>
      {activeButton === 'Scheduled' && (
        <MobileScheduledMenu id={id} />
      )}
      {activeButton === 'Completed' && (
        <MobileCompletedMenu id={id} />
      )}
    </React.Fragment>
  )
}

export default MobileToursMenu
