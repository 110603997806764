import React from 'react'
import { Col, Row, Text, Input } from 'components'
import Table from './Table'
import { theme } from 'lib'

const Tours = props => {
  let {
    fetchingData,
    allTours,
    onAllPageChange,
    onSearchChange,
    handleSelectedTrip
  } = props
  return (
    <div style={{ margin: '3.5rem 0 2.5rem 0' }}>
      <Row>
        <Col>
          <Row>
            <Text
              QS
              size='30px'
              lineHeight='38px'
              weight='700'
              color={theme.BLACK_TRALLY}
            >
              Select the Tours
            </Text>
          </Row>
        </Col>
        <Row center>
          <Row noFlex style={{ marginRight: 12 }}>
            <Text
              QS
              size='20px'
              lineHeight='25px'
              weight='700'
              color={theme.BLACK_TRALLY}
            >
              Search Tours
            </Text>
          </Row>
          <Row>
            <Input
              placeholder={'Search Tours by title / ID'}
              placeholderTextColor={theme.GRAY_91}
              wid={'100%'}
              hasRadius='45px'
              border={theme.GRAY_91}
              style={{
                backgroundColor: theme.WHITE
              }}
              ht='51px'
              weight='400'
              lineHeight='15px'
              pad='0 25px'
              onChange={onSearchChange}
            />
          </Row>
        </Row>
      </Row>
      <Row marg='1rem 0 0 0'>
        <Table
          caller='desktop'
          viewer='all'
          data={allTours}
          handler={onAllPageChange}
          fetchingData={fetchingData}
          handleSelectedTrip={handleSelectedTrip}
        />
      </Row>
    </div>
  )
}

export default Tours
