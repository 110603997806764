import React from 'react'
import styled from 'styled-components'
import { Row, Col, Text, Avatar, Button } from 'components'
import NoProfilePic from 'assets/no-profile-picture.png'
import { env, theme } from 'lib'
import { format } from 'date-fns'

const Container = styled.div`
  padding: 0 2rem 0.5rem;
  margin-top: 2.5rem;
  height: 100%;
`
const Heading = styled.label`
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #22191a;
`
const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #2c2c2c;
`
const DetailContainer = styled.div`
  margin: 0.2rem 1rem;
  display: flex;
  flex-direction: column;
  ${props => props.marg && `margin: ${props.marg}`}
`

const MobileGuestMenu = ({ guest, amplitudeUrl }) => {
  return (
    <Col
      noFlex
      style={{
        textAlign: 'start'
      }}
    >
      {guest ? (
        <Container noFlex bg={theme.BLACk}>
          <Col style={{ margin: '0.2rem 0.7rem 2rem' }}>
            <Avatar
              uri={
                guest.avatar
                  ? guest.avatar + '?time="' + new Date() + '"'
                  : NoProfilePic
              }
              size='128'
            />
            <Button
              black
              noShadow
              pad='0 0.9375rem'
              marg='1rem 0 0'
              style={{
                maxWidth: '8.875rem'
              }}
            >
              <a
                href={amplitudeUrl}
                rel='noreferrer'
                target='_blank'
                style={{
                  userSelect: 'none',
                  msTouchSelect: 'none',
                  msUserSelect: 'none',
                  MozUserSelect: 'none',
                  WebkitUserSelect: 'none'
                }}
              >
                <Text color={theme.WHITE} QS size='0.875rem'>
                  Open Amplitude
                </Text>
              </a>
            </Button>
          </Col>
          <Row>
            <Col style={{ flex: 0.5 }}>
              <DetailContainer>
                <Heading>User ID</Heading>
                <Label>
                  <a
                    rel='noreferrer'
                    href={
                      (env.API_URL.includes('localhost') ||
                      env.API_URL.includes('192.168')
                        ? 'http://localhost:3000'
                        : `https://${
                            env.API_URL.includes('stagingapi.trally')
                              ? 'staging.trally.com'
                              : 'app.trally.com'
                          }`) + `/profile/${guest._id}`
                    }
                    target='_blank'
                    style={{
                      userSelect: 'none',
                      msTouchSelect: 'none',
                      msUserSelect: 'none',
                      MozUserSelect: 'none',
                      WebkitUserSelect: 'none'
                    }}
                  >
                    <Text
                      weight='700'
                      size='14px'
                      lineHeight='19.8px'
                      wid='200px'
                      style={{
                        wordBreak: 'break-all'
                      }}
                      textDecorationLine={'underline'}
                    >
                      {guest._id ? guest._id : '-'}
                    </Text>
                  </a>
                </Label>
              </DetailContainer>
              <DetailContainer>
                <Heading>First Name</Heading>
                <Label>
                  {guest.name && guest?.name?.split(' ')[0]
                    ? guest?.name?.split(' ')[0]
                    : '-'}
                </Label>
              </DetailContainer>
              <DetailContainer>
                <Heading>Last Name</Heading>
                <Label>
                  {guest.name && guest?.name?.split(' ')[1]
                    ? guest?.name?.split(' ')[1]
                    : '-'}
                </Label>
              </DetailContainer>
              <DetailContainer>
                <Heading>Email Address</Heading>
                <Label>{guest.email}</Label>
              </DetailContainer>
              <DetailContainer>
                <Heading>Phone Number</Heading>
                <Label>{guest.phone ? guest.phone : '-'}</Label>
              </DetailContainer>
              <DetailContainer>
                <Heading>Date Joined</Heading>
                <Label>
                  {guest.createdAt
                    ? format(
                        new Date(guest.createdAt),
                        'hh:mm a O, PPP'
                      )
                    : '-'}
                </Label>
              </DetailContainer>
            </Col>
            <Col style={{ flex: 0.5 }}>
              <DetailContainer>
                <Heading>Country Origin</Heading>
                <Label>{guest.country ? guest.country : '-'}</Label>
              </DetailContainer>
              <DetailContainer>
                <Heading>Trips Booked</Heading>
                <Label>
                  {guest.signedUp ? guest.signedUp.length : '0'}
                </Label>
              </DetailContainer>
              <DetailContainer>
                <Heading>Trips Watched</Heading>
                <Label>
                  {guest.tripsWatched
                    ? guest.tripsWatched.length
                    : '0'}
                </Label>
              </DetailContainer>
              <DetailContainer>
                <Heading>Followers</Heading>
                <Label>{guest.followersCount}</Label>
              </DetailContainer>
              <DetailContainer>
                <Heading>Following</Heading>
                <Label>{guest.followingCount}</Label>
              </DetailContainer>
              <DetailContainer>
                <Heading>Reviews Given</Heading>
                <Label>
                  {guest.reviews ? guest.reviews.length : '0'}
                </Label>
              </DetailContainer>
              <DetailContainer>
                <Heading>Average Ratings Given</Heading>
                <Label>
                  {guest.reviews && guest.reviews.length > 0
                    ? parseFloat(
                        (guest.totalRating && guest.totalRating > 0
                          ? guest.totalRating
                          : guest.reviews.reduce(
                              (t, v) => (t += v.rating),
                              0
                            )) / guest.reviews.length
                      ).toFixed(1)
                    : '0'}
                </Label>
              </DetailContainer>
            </Col>
          </Row>
        </Container>
      ) : (
        <Col centerAll wid='100vw' ht='100%'>
          <Text QS weight='700' size='18px'>
            Guest not found
          </Text>
        </Col>
      )}
    </Col>
  )
}

export default MobileGuestMenu
