import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter as Router } from 'react-router-dom'
import MainRouter from 'screens/routes/MainRouter'
import './assets/fonts/Quicksand-Regular.ttf'
import './assets/fonts/Quicksand-Light.ttf'
import './assets/fonts/Quicksand-Medium.ttf'
import './assets/fonts/Quicksand-SemiBold.ttf'
import './assets/fonts/Quicksand-Bold.ttf'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './main/store'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router>
          <MainRouter />
        </Router>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
