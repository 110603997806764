import React from 'react'
import { Col, Text } from 'components'
import { theme } from 'lib'

function StripeStatus({ t, connected }) {
  return (
    <Col
      hasRadius='0.313rem'
      hasBorder={`0.063rem solid ${
        connected ? theme.TRALLY_DARK_TEAL : theme.RED
      }`}
      pad='0.188rem 0.375rem'
      noFlex
      ht='1.438rem'
    >
      <Text
        size='0.75rem'
        lineHeight='1.063rem'
        spacing='0.063rem'
        color={connected ? theme.TRALLY_DARK_TEAL : theme.RED}
        weight='700'
      >
        {connected ? 'Connected' : 'Not Connected'}
      </Text>
    </Col>
  )
}

export default StripeStatus
