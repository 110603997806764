import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef
} from 'react'
import DesktopView from './desktopView'
import MobileView from './mobileView'
import { env, Api } from 'lib'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Index = () => {
  let { menu } = useParams()

  let { id } = useParams()
  const history = useHistory()
  const bookedTimerRef = useRef()
  const watchedTimerRef = useRef()
  const [loading, setLoading] = useState(false)
  const [guest, setGuest] = useState(null)
  const [selectedMenu, setSelectedMenu] = useState('guest')
  const { screenDimensions } = useSelector(state => state.appState)

  //Trip Watched Search
  const [watchedSearchText, setWatchedSearchText] = useState('')
  const [watchedTrips, setWatchedTrips] = useState([])

  const onWatchedSearchChange = useCallback(
    e => {
      let searchText =
        e && e.target ? e.target.value : watchedSearchText

      setWatchedSearchText(searchText)

      clearTimeout(watchedTimerRef.current)
      watchedTimerRef.current = setTimeout(() => {
        setWatchedTrips(
          guest.tripsWatched
            ? guest.tripsWatched.filter(
                t =>
                  t &&
                  ((t.tripTitle &&
                    t.tripTitle
                      .toLowerCase()
                      .includes(searchText.toLowerCase())) ||
                    (t.host &&
                      t.host.name &&
                      t.host.name
                        .toLowerCase()
                        .includes(searchText.toLowerCase())))
              )
            : []
        )
      }, 500)
    },
    [guest, watchedSearchText]
  )

  //Trip Booked Search
  const [bookedSearchText, setBookedSearchText] = useState('')
  const [bookedTrips, setBookedTrips] = useState([])

  const onBookedSearchChange = useCallback(
    e => {
      let searchText =
        e && e.target ? e.target.value : bookedSearchText

      setBookedSearchText(searchText)
      clearTimeout(bookedTimerRef.current)
      bookedTimerRef.current = setTimeout(() => {
        setBookedTrips(
          guest.signedUp
            ? guest.signedUp.filter(
                t =>
                  t &&
                  ((t.tripId &&
                    t.tripId.name &&
                    t.tripId.name
                      .toLowerCase()
                      .includes(searchText.toLowerCase())) ||
                    (t.tripId &&
                      t.tripId.host &&
                      t.tripId.host.name &&
                      t.tripId.host.name
                        .toLowerCase()
                        .includes(searchText.toLowerCase())))
              )
            : []
        )
      }, 500)
    },
    [guest, bookedSearchText]
  )

  useEffect(() => {
    if (!guest) return
    onWatchedSearchChange()
    onBookedSearchChange()
  }, [guest, onWatchedSearchChange, onBookedSearchChange])

  useEffect(() => {
    if (menu && menu.length > 0) {
      setSelectedMenu(menu)
    } else {
      setSelectedMenu('guest')
    }
  }, [menu])

  const getGuestData = useCallback(async () => {
    const res = await Api.get('/admin/getSingleGuest/' + id)
    if (res) {
      setGuest(res)
    } else {
      console.log('error in fetching guest')
    }
    setLoading(false)
  }, [id])

  useEffect(() => {
    setLoading(true)
    getGuestData()
  }, [id, getGuestData])

  const amplitudeUrl = useMemo(
    () =>
      `https://analytics.amplitude.com/trally/project/${env.AMPLITUDE_PROJECT_ID}/search/user_id%3D${id}`,
    [id]
  )

  return (
    <React.Fragment>
      {screenDimensions.width > env.SCREEN_WIDTH ? (
        <React.Fragment>
          <div style={{ marginTop: '80px' }}>
            <DesktopView
              selectedMenu={selectedMenu}
              setSelectedMenu={setSelectedMenu}
              windowWidth={screenDimensions.width}
              id={id}
              history={history}
              guest={guest}
              loading={loading}
              amplitudeUrl={amplitudeUrl}
              watchedTrips={watchedTrips}
              watchedSearchText={watchedSearchText}
              onWatchedSearchChange={onWatchedSearchChange}
              bookedTrips={bookedTrips}
              bookedSearchText={bookedSearchText}
              onBookedSearchChange={onBookedSearchChange}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <MobileView
            selectedMenu={selectedMenu}
            id={id}
            history={history}
            guest={guest}
            loading={loading}
            amplitudeUrl={amplitudeUrl}
            watchedTrips={watchedTrips}
            watchedSearchText={watchedSearchText}
            onWatchedSearchChange={onWatchedSearchChange}
            bookedTrips={bookedTrips}
            bookedSearchText={bookedSearchText}
            onBookedSearchChange={onBookedSearchChange}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Index
