const defaultState = {
  category: null,
  categoriesList: [],
  isFetching: false
}

const categories = (state = defaultState, action) => {
  const { payload, type } = action

  switch (type) {
    case 'SET_CATEGORY':
      return {
        ...state,
        category: payload
      }
    case 'SET_CATEGORIES':
      return {
        ...state,
        categoriesList: payload
      }
    case 'IS_FETCHING':
      return {
        ...state,
        isFetching: payload
      }
    case 'LOGOUT':
      return defaultState

    default:
      return state
  }
}

export default categories
