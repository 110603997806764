import React from 'react'
import { Text } from 'components'
import { env, theme } from 'lib'

const Link = props => (
  <Text
    weight='700'
    size='14px'
    lineHeight='19.8px'
    wid='200px'
    style={{
      wordBreak: 'break-all',
      width: '100%',
      ...props.styles
    }}
    textDecorationLine={'underline'}
  >
    <a
      rel='noreferrer'
      style={{
        color: theme.FULL_BLACK,
        userSelect: 'none',
        msTouchSelect: 'none',
        msUserSelect: 'none',
        MozUserSelect: 'none',
        WebkitUserSelect: 'none'
      }}
      href={
        (env.API_URL.includes('localhost') ||
        env.API_URL.includes('192.168')
          ? 'http://localhost:3000'
          : `https://${
              env.API_URL.includes('stagingapi.trally')
                ? props.isAdmin
                  ? 'stagingadmin.trally.com'
                  : 'staging.trally.com'
                : props.isAdmin
                ? 'admin.trally.com'
                : 'app.trally.com'
            }`) + `${props.url}`
      }
      target='_blank'
    >
      {props.children}
    </a>
  </Text>
)

export default Link
