import React, { useEffect } from 'react'
import ReactPaginate from 'react-paginate'
// import './Pagination.css'
import { Icon } from './'

const Pagination = ({ total, handler, customCss, classProps }) => {
  const onPageChange = page => {
    handler(page.selected + 1)
  }

  useEffect(() => {
    if (customCss) {
      require(`../main/styles/${customCss}`)
    }
  }, [customCss])

  return (
    <div>
      <ReactPaginate
        previousLabel={<Icon type='back' size='0.875rem' />}
        nextLabel={<Icon type='next' size='0.875rem' />}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={total ? total : 1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={selectedItem => onPageChange(selectedItem)}
        containerClassName='container'
        activeClassName='activeLink'
        pageClassName='listItem'
        disabledClassName='disabledButton'
        nextClassName='nextButton'
        previousClassName='prevButton'
        {...classProps}
      />
    </div>
  )
}

export default Pagination
