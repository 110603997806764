import React from 'react'
import { Button, Col, Row, Text, Icon } from 'components'
import { theme } from 'lib'
import CategoryToursTable from './CategoryToursTable'
import DraggableList from 'react-draggable-list'
import { setCategory } from 'actions/categories'

class Category extends React.Component {
  getDragHeight() {
    return 150
  }

  handleSetCategory(data) {
    this.props.commonProps.dispatch(
      setCategory({
        data,
        action: this.props.commonProps.history.push(
          '/categories/edit'
        )
      })
    )
  }

  render() {
    const { item, isFetching, dragHandleProps, commonProps } =
      this.props

    return (
      <Col
        hasBorder={`1px solid ${theme.GRAY_91}`}
        hasRadius='20px'
        pad='22px 36px'
        marg='30px 0px'
        noFlex
        style={{
          overflow: 'hidden',
          background: theme.WHITE,
          maxHeight: '100%'
        }}
      >
        <Row between center>
          <Col noFlex>
            <Text
              QS
              size='40px'
              lineHeight='45px'
              weight='600'
              color={theme.BLACK_TRALLY}
            >
              Category{' '}
              {DraggableList._getIndexOfItemWithKey(
                DraggableList._getKeyFn('_id'),
                commonProps.categoriesList,
                item._id
              ) + 1}
            </Text>
          </Col>
          <di {...dragHandleProps}>
            <Icon type='menu' size='1.5rem' />
          </di>
        </Row>
        <Col>
          <Text
            QS
            size='32px'
            lineHeight='40px'
            weight='600'
            color={theme.BLACK_TRALLY}
            marg='26px 0'
          >
            {item.title}
          </Text>

          <Text
            QS
            size='20px'
            lineHeight='25px'
            weight='400'
            color={theme.BLACK_TRALLY}
          >
            {item.description}
          </Text>
        </Col>

        <React.Fragment>
          <Text
            QS
            size='30px'
            lineHeight='38px'
            weight='700'
            color={theme.BLACK_TRALLY}
            marg='27px 0px 9px'
          >
            Tours
          </Text>
          <CategoryToursTable
            caller='desktop'
            viewer='all'
            fetchingData={isFetching}
            data={item.trips}
            onDeleteTripClicked={commonProps.onDeleteTripClicked}
            category={item}
          />
          <Col>
            <Row
              style={{
                justifyContent: 'flex-end'
              }}
              marg='10px 0 0 0'
            >
              <Button
                bgColor='transparent'
                text='Edit'
                fontSize='18px'
                onClick={() => this.handleSetCategory(item)}
                noShadow
                color={`${theme.BLUE_5}`}
                textStyles={{
                  textDecorationLine: 'underline',
                  lineHeight: '22px',
                  fontWeight: '500'
                }}
                pad='0px 20px 0px 0px'
              />
              <Button
                bgColor='transparent'
                text='Delete'
                fontSize='18px'
                onClick={() => commonProps.onDeleteClicked(item)}
                noShadow
                color={`${theme.BLUE_5}`}
                textStyles={{
                  textDecorationLine: 'underline',
                  lineHeight: '22px',
                  fontWeight: '500'
                }}
                pad='0px 20px 0px 0px'
              />
            </Row>
          </Col>
        </React.Fragment>
      </Col>
    )
  }
}

export default Category
