import { Api } from 'lib'

const editCategory = ({ category, action }) => {
  return async dispatch => {
    const res = await Api.patch(`/admin/editCategory`, { category })

    dispatch({
      type: 'LOADING_BUTTON',
      payload: null
    })

    if (!res) return

    action && action(res)
  }
}

export default editCategory
