import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  withStyles,
  Tooltip
} from '@material-ui/core'
import { Button, Col, Text, Spinner, Icon } from 'components'
import PaginationView from './paginationView'
import { useHistory } from 'react-router-dom'
import NoHostImage from 'assets/host.png'
import { theme } from 'lib'
import { format } from 'date-fns'

const StyledTableCell = withStyles(theme => ({
  head: {
    fontSize: 14,
    fontFamily: 'Quicksand',
    lineHeight: '12px',
    fontWeight: 'bold',
    backgroundColor: '#F4F3F3',
    textAlign: 'center',
    color: '#22191A',
    width: props => (props.caller === 'mobile' ? '20vw' : '')
  },
  body: {
    fontSize: 12,
    textAlign: 'center'
  }
}))(TableCell)

const CustomTable = ({
  caller,
  viewer,
  data,
  handler,
  fetchingData,
  selectPayout,
  selectSinglePayout,
  selectAll,
  checked
}) => {
  const history = useHistory()
  return (
    <React.Fragment>
      <TableContainer component={Paper} style={{}}>
        <Table
          aria-label='customized table'
          style={caller === 'desktop' ? {} : { display: 'contents' }}
        >
          <TableHead>
            <TableRow>
              {selectPayout && (
                <StyledTableCell
                  style={{
                    minWidth: 'auto'
                  }}
                  caller={caller}
                >
                  <Checkbox
                    checked={
                      data &&
                      data.docs &&
                      checked &&
                      (data.docs.length !== 0 ||
                        checked.length !== 0) &&
                      data.docs.length === checked.length
                    }
                    onChange={e => selectAll(e.target.checked)}
                    style={{
                      color: theme.BLUE
                    }}
                  />
                </StyledTableCell>
              )}
              <StyledTableCell
                caller={caller}
                style={
                  caller === 'mobile' && selectPayout
                    ? {
                        minWidth: '16vw'
                      }
                    : {}
                }
              >
                ID
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Account
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                First Name
              </StyledTableCell>
              <React.Fragment>
                {caller === 'desktop' ? (
                  <React.Fragment>
                    <StyledTableCell>Last Name</StyledTableCell>
                    <StyledTableCell>Email Address</StyledTableCell>
                    <StyledTableCell caller={caller}>
                      Request
                    </StyledTableCell>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
              <StyledTableCell caller={caller}>
                Nominal
              </StyledTableCell>
              <React.Fragment>
                {caller === 'desktop' && (
                  <StyledTableCell caller={caller}>
                    Current Balance
                  </StyledTableCell>
                )}
              </React.Fragment>
              <StyledTableCell caller={caller}>
                Status
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {fetchingData ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={9}>
                  <Col>
                    <Spinner />
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : data && data.totalDocs <= 0 ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={9}>
                  <Col centerAll>
                    <img
                      src={NoHostImage}
                      width={caller === 'desktop' ? 130 : 100}
                      alt='no host'
                      style={{
                        marginTop: 50,
                        marginBottom: 10
                      }}
                    />
                    <Text
                      weight='700'
                      size={caller === 'desktop' ? '16px' : '14px'}
                      lineHeight='40px'
                      QS
                    >
                      No {viewer === 'requested' && 'requested'} host
                      found
                    </Text>
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {data && data.docs ? (
                <React.Fragment>
                  {data.docs.map((row, index) => {
                    let latestPayment = row.paymentHistory
                    const verificationStatus =
                      (row && row.stripe && row.stripe.supported
                        ? row.stripe.onboarded && row.stripe.id
                          ? 'stripe_verified'
                          : 'stripe_not_verified'
                        : row &&
                          row.hostDetails &&
                          row.hostDetails.verified &&
                          row.hostDetails.verified.state) ||
                      'not_verified'

                    if (!latestPayment) return <React.Fragment />
                    return (
                      <TableRow key={index}>
                        {selectPayout && (
                          <StyledTableCell caller={caller}>
                            {verificationStatus === 'verified' && (
                              <Checkbox
                                checked={
                                  checked &&
                                  checked.some(c => c === index)
                                }
                                onClick={() =>
                                  selectSinglePayout({
                                    host: row,
                                    index
                                  })
                                }
                                style={{
                                  color: theme.BLUE
                                }}
                              />
                            )}
                          </StyledTableCell>
                        )}
                        <StyledTableCell
                          component='th'
                          scope='row'
                          caller={caller}
                        >
                          {(data?.page - 1) * 10 + (index + 1)}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          <Tooltip
                            title={
                              verificationStatus
                                ? verificationStatus ===
                                  'stripe_verified'
                                  ? 'Verified using Stripe Connect'
                                  : verificationStatus ===
                                    'stripe_not_verified'
                                  ? 'Not Verified'
                                  : verificationStatus === 'verified'
                                  ? 'Verified'
                                  : verificationStatus === 'rejected'
                                  ? 'Rejected'
                                  : 'Not Verified'
                                : 'Not Verified'
                            }
                            placement='top'
                          >
                            <div>
                              {verificationStatus ? (
                                verificationStatus === 'verified' ||
                                verificationStatus ===
                                  'stripe_verified' ? (
                                  <Icon
                                    type='check-active'
                                    size='1.5rem'
                                  />
                                ) : verificationStatus ===
                                  'rejected' ? (
                                  <Icon type='review' size='1.5rem' />
                                ) : (
                                  <Icon
                                    type='circle-yellow'
                                    size='1.5rem'
                                  />
                                )
                              ) : (
                                <Icon
                                  type='circle-yellow'
                                  size='1.5rem'
                                />
                              )}
                            </div>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {row?.name?.split(' ')[0]}
                        </StyledTableCell>
                        <React.Fragment>
                          {caller === 'desktop' ? (
                            <React.Fragment>
                              <StyledTableCell>
                                {row?.name?.split(' ')[1]}
                              </StyledTableCell>
                              <StyledTableCell>
                                {row?.email}
                              </StyledTableCell>
                            </React.Fragment>
                          ) : null}
                        </React.Fragment>
                        <React.Fragment>
                          {caller === 'desktop' && (
                            <StyledTableCell caller={caller}>
                              {format(
                                new Date(latestPayment.createdAt),
                                'dd MMMM yyyy'
                              )}
                            </StyledTableCell>
                          )}
                        </React.Fragment>
                        <StyledTableCell caller={caller}>
                          {parseFloat(
                            parseInt(latestPayment.amount) / 100
                          ).toFixed(2)}
                        </StyledTableCell>
                        <React.Fragment>
                          {caller === 'desktop' && (
                            <StyledTableCell caller={caller}>
                              {parseFloat(
                                parseInt(row?.accountBalance || 0) /
                                  100
                              ).toFixed(2)}
                            </StyledTableCell>
                          )}
                        </React.Fragment>
                        <StyledTableCell caller={caller}>
                          <Text
                            weight='700'
                            size='0.75rem'
                            lineHeight='1.054rem'
                            spacing='0.063rem'
                            marg='0.375rem 0 0 0'
                            color={
                              latestPayment.type ===
                              'successful_request'
                                ? theme.TRALLY_DARK_TEAL
                                : latestPayment.type ===
                                  'rejected_request'
                                ? theme.gray15
                                : verificationStatus &&
                                  verificationStatus === 'verified'
                                ? theme.BLUE_1
                                : theme.warning
                            }
                          >
                            <span
                              style={{
                                fontSize: '0.5rem'
                              }}
                            >
                              ●
                            </span>{' '}
                            {latestPayment.type ===
                            'successful_request'
                              ? 'Success'
                              : latestPayment.type ===
                                'rejected_request'
                              ? 'Rejected'
                              : verificationStatus &&
                                verificationStatus === 'verified'
                              ? 'Pending'
                              : 'Needs review'}
                          </Text>
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          <Button
                            black
                            text='View'
                            onClick={() =>
                              row._id &&
                              history.push(
                                '/view/' + row._id + '/payment'
                              )
                            }
                          />
                        </StyledTableCell>
                      </TableRow>
                    )
                  })}
                </React.Fragment>
              ) : null}
            </TableBody>
          )}
        </Table>
        <PaginationView
          total={data?.totalPages}
          current={data?.page}
          hasNext={data?.hasNextPage}
          hasPrev={data?.hasPrevPage}
          handler={handler}
        />
      </TableContainer>
    </React.Fragment>
  )
}

export default CustomTable
