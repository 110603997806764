import React, { useMemo } from 'react'
import { Col, Input, Text } from 'components'
import { theme } from 'lib'

function HostDetails({ user }) {
  const details = useMemo(() => {
    return [
      {
        title: 'Bank Name',
        placeholder: 'No Bank Name',
        value: user?.hostDetails?.bank
      },
      {
        title: 'Account Number',
        placeholder: 'No Account Number',
        value: user?.hostDetails?.accountNumber
      },
      {
        title: 'Swift Code',
        placeholder: 'No Swift Code',
        value: user?.hostDetails?.swiftCode
      },
      {
        title: 'Bank Address',
        placeholder: 'No Bank Address',
        value: user?.hostDetails?.bankAddress
      },
      {
        title: 'Government ID',
        placeholder: 'No Government ID',
        value: user?.hostDetails?.governmentId
      },
      {
        title: 'Government ID Type',
        placeholder: 'No Government ID Type',
        value: user?.hostDetails?.governmentIdType
      },
      {
        title: 'Phone Number',
        placeholder: 'No Phone Number',
        value: user?.hostDetails?.phone
      },
      {
        title: 'Emergency Number',
        placeholder: 'No Emergency Number',
        value: user?.hostDetails?.emergencyContact
      }
    ]
  }, [user])
  return (
    <Col>
      {details &&
        details.map(d => {
          return (
            <div key={d.title}>
              <Text
                QS
                weight='700'
                size='0.875rem'
                lineHeight='1.0938rem'
                color={theme.gray6}
              >
                {d.title}
              </Text>
              <Input
                value={d.value}
                disabled={true}
                wid='100%'
                hasBorder={`1px solid ${theme.LIGHT_GRAY}`}
                marg='0.4375rem 0 1.875rem'
                placeholder={d.placeholder}
              />
            </div>
          )
        })}
    </Col>
  )
}
export default HostDetails
