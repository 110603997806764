import React, { useMemo } from 'react'
import { Row, Col, Icon, Text } from 'components'
import styled from 'styled-components'
import { theme } from 'lib'
import Activity from '../activity'
import { Tooltip } from '@material-ui/core'
import AccountDetails from '../accountDetails'
import millify from 'millify'

const StatsContainer = styled.div`
  padding: 1rem;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  background-color: ${theme.WHITE};
  width: 100%;
`

function DesktopPaymentMenu({
  windowWidth,
  host,
  setModal,
  setMessageModal,
  stripeSupported
}) {
  const verificationStatus = useMemo(() => {
    return (
      (stripeSupported && stripeSupported === 'yes'
        ? host &&
          host.stripe &&
          host.stripe.supported &&
          host.stripe.onboarded &&
          host.stripe.id
          ? 'stripe_verified'
          : 'stripe_not_verified'
        : host &&
          host.hostDetails &&
          host.hostDetails.verified &&
          host.hostDetails.verified.state) || 'not_verified'
    )
  }, [host, stripeSupported])

  return (
    <Row>
      <Col>
        <StatsContainer>
          <Row between center marg='1rem 0 0'>
            <Text
              weight='700'
              size='0.75rem'
              lineHeight='0.895rem'
              color={theme.gray2}
              textTransform='uppercase'
            >
              Bank Account
            </Text>
            <Row center noFlex>
              <Tooltip
                title={
                  verificationStatus
                    ? verificationStatus === 'stripe_verified'
                      ? 'Verified using Stripe Connect'
                      : verificationStatus === 'stripe_not_verified'
                      ? 'Not Verified'
                      : verificationStatus === 'verified'
                      ? 'Verified'
                      : verificationStatus === 'rejected'
                      ? 'Rejected'
                      : 'Not Verified'
                    : 'Not Verified'
                }
                placement='top'
              >
                <div>
                  {verificationStatus ? (
                    verificationStatus === 'verified' ||
                    verificationStatus === 'stripe_verified' ? (
                      <Icon type='check-active' size='1.5rem' />
                    ) : verificationStatus === 'rejected' ? (
                      <Icon type='review' size='1.5rem' />
                    ) : (
                      <Icon type='circle-yellow' size='1.5rem' />
                    )
                  ) : (
                    <Icon type='circle-yellow' size='1.5rem' />
                  )}
                </div>
              </Tooltip>
              <Text
                QS
                weight='700'
                size='0.875rem'
                lineHeight='1.05rem'
                color={
                  verificationStatus
                    ? verificationStatus === 'verified' ||
                      verificationStatus === 'stripe_verified'
                      ? theme.TRALLY_DARK_TEAL
                      : verificationStatus === 'rejected' &&
                        theme.gray15
                    : theme.warning
                }
                marg='0 0 0 0.375rem'
              >
                {verificationStatus &&
                (verificationStatus === 'verified' ||
                  verificationStatus === 'stripe_verified')
                  ? 'Verified'
                  : 'Needs review'}
              </Text>
            </Row>
          </Row>
          <Row
            ht='0.0625rem'
            hasBorderBottom={`1px solid ${theme.gray18}`}
            wid='100%'
            marg='1rem 0'
          />
          <Row between center>
            <Text
              weight='700'
              size='0.75rem'
              lineHeight='0.895rem'
              color={theme.gray2}
              textTransform='uppercase'
            >
              Current Balance
            </Text>
            <Row center noFlex>
              <Icon
                type='trally-coin'
                size='1.5rem'
                marg='0 0.75rem 0 0'
              />
              <Text
                QS
                weight='700'
                size='2rem'
                lineHeight='2.8rem'
                color={theme.BLACK_TRALLY}
              >
                {millify(
                  parseFloat(
                    host.availableBalance
                      ? host.availableBalance / 100
                      : 0
                  ),
                  {
                    precision: 2
                  }
                )}
              </Text>
            </Row>
          </Row>
          <Row between center marg='1rem 0 0'>
            <Text
              weight='700'
              size='0.75rem'
              lineHeight='0.895rem'
              color={theme.gray2}
              textTransform='uppercase'
            >
              Total Income
            </Text>
            <Row center noFlex>
              <Icon
                type='add-rounded'
                size='1.0625rem'
                marg='0 0.75rem 0 0'
              />
              <Text
                QS
                weight='700'
                size='1.5rem'
                lineHeight='1.8rem'
                color={theme.BLACK_TRALLY}
              >
                {millify(
                  parseFloat(
                    host.accountBalance
                      ? host.accountBalance / 100
                      : 0
                  ),
                  {
                    precision: 2
                  }
                )}
              </Text>
            </Row>
          </Row>
          <Row between center marg='1rem 0 0'>
            <Text
              weight='700'
              size='0.75rem'
              lineHeight='0.895rem'
              color={theme.gray2}
              textTransform='uppercase'
            >
              Total Cash Out
            </Text>
            <Row center noFlex>
              <Icon
                type='money-rounded'
                size='1.0625rem'
                marg='0 0.75rem 0 0'
              />
              <Text
                QS
                weight='700'
                size='1.5rem'
                lineHeight='1.8rem'
                color={theme.BLACK_TRALLY}
              >
                {millify(
                  parseFloat(host.cashout ? host.cashout / 100 : 0),
                  {
                    precision: 2
                  }
                )}
              </Text>
            </Row>
          </Row>
        </StatsContainer>
        <Col marg='4rem 0 0'>
          <Activity
            windowWidth={windowWidth}
            user={host}
            verificationStatus={verificationStatus}
            setModal={setModal}
            setMessageModal={setMessageModal}
          />
        </Col>
      </Col>
      <Col>
        <AccountDetails windowWidth={windowWidth} user={host} />
      </Col>
    </Row>
  )
}

export default DesktopPaymentMenu
