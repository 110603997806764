import React from 'react'
import { Col, Row, Text, Button } from 'components'
import { theme } from 'lib'
import { useHistory } from 'react-router-dom'
import { AllCategories } from './components'

const DesktopCategories = () => {
  const history = useHistory()

  return (
    <React.Fragment>
      <div style={{ margin: '8rem 4rem 0rem' }}>
        <Row between center>
          <Col noFlex>
            <Text
              QS
              size='40px'
              lineHeight='50px'
              weight='600'
              color={theme.BLACK_TRALLY}
            >
              All Categories
            </Text>
          </Col>
          <Col noFlex>
            <Button
              ht='41px'
              text='+ Add New Category'
              onClick={() => history.push('/categories/add')}
              bgColor={theme.BLUE_4}
              color={theme.WHITE}
              textStyles={{
                fontSize: 16,
                fontWeight: '700',
                lineHeight: 19
              }}
              hasRadius='40px'
            />
          </Col>
        </Row>
      </div>
      <AllCategories />
    </React.Fragment>
  )
}

export default DesktopCategories
