import React, { useState } from 'react'
import {
  Col,
  Row,
  Text,
  Input,
  MobileHeader,
  Icon,
  Button,
  MobileDrawer
} from 'components'
import { FilterButton, Table } from './components'
import { useHistory } from 'react-router-dom'
import { theme } from 'lib'

function MobilePayout(props) {
  let {
    activeButton,
    changeFilter,
    fetchingData,
    selectPayout,
    toggleSelectPayout,
    selectSinglePayout,
    selectAll,
    checked,
    approveBulkPayouts
  } = props
  const history = useHistory()

  const [openSideBar, setOpenSideBar] = useState(false)
  const toggleSideBar = () => {
    setOpenSideBar(!openSideBar)
  }

  return (
    <React.Fragment>
      <MobileHeader
        fixed
        leftClick={() => toggleSideBar()}
        Left={
          <React.Fragment>
            <Icon type='hamburger-menu' size='20px' />
          </React.Fragment>
        }
        Middle={
          <img
            src={require('assets/trally-mobile.png').default}
            style={{
              width: 70
            }}
            alt='trally-logo'
          />
        }
        middleClick={() => history.push('/')}
        pad='19px 17px 0 20px'
      />
      <div
        style={{ margin: '2rem 1rem', height: 'calc(100vh - 62px)' }}
      >
        <Row>
          <Col>
            <Row>
              <Text QS size='28px' lineHeight='60px' weight='700'>
                Payouts
              </Text>
            </Row>
            <Row>
              {activeButton ? (
                <React.Fragment>
                  <FilterButton
                    text='Requested'
                    activeButton={activeButton}
                    changeFilter={changeFilter}
                  />
                  <FilterButton
                    text='All Requests'
                    activeButton={activeButton}
                    changeFilter={changeFilter}
                  />
                </React.Fragment>
              ) : null}
            </Row>
            <Row marg='15px 0'>
              {activeButton !== 'Requested' ||
              !props.requestedUsers.docs ||
              props.requestedUsers.docs.length <= 0 ? (
                <React.Fragment></React.Fragment>
              ) : selectPayout ? (
                <Row
                  style={{
                    alignItems: 'flex-end'
                  }}
                >
                  <Button
                    text='Cancel'
                    wid='98px'
                    ht='48px'
                    color={theme.BLACK}
                    bordered
                    borderColor={theme.BLACK}
                    bgColor='transparent'
                    onClick={() => toggleSelectPayout()}
                    marg='0 20px 0 0'
                    noShadow
                  />
                  <Button
                    wid='162px'
                    ht='48px'
                    color={theme.WHITE}
                    bgColor={theme.BLUE}
                    onClick={() => approveBulkPayouts()}
                    noShadow
                  >
                    <Row centerAll>
                      <Text
                        QS
                        weight='700'
                        size='16px'
                        lineHeight='29px'
                        color={theme.WHITE}
                        marg='0 5px 0 0'
                      >
                        Approve All
                      </Text>
                      <Icon type='tick-white' size='17px' />
                    </Row>
                  </Button>
                </Row>
              ) : (
                <Button
                  text='Select Items'
                  wid='143px'
                  ht='48px'
                  color={theme.WHITE}
                  bgColor={theme.BLUE}
                  onClick={() => toggleSelectPayout()}
                  noShadow
                />
              )}
            </Row>
            <Row>
              <Text QS size='16px' lineHeight='30px' weight='700'>
                Search Host
              </Text>
            </Row>
            <Row>
              <Input
                placeholder='Search requesting hosts'
                wid='100%'
                borderRadius='30px'
                rounded
                onChange={props.onSearchChange}
              />
            </Row>
          </Col>
        </Row>
        <Row marg='2rem 0rem'>
          {activeButton === 'Requested' ? (
            <Table
              caller='mobile'
              viewer='requested'
              data={props.requestedUsers}
              handler={props.onRequestedPageChange}
              fetchingData={fetchingData}
              selectPayout={selectPayout}
              selectSinglePayout={selectSinglePayout}
              selectAll={selectAll}
              checked={checked}
            />
          ) : (
            <Table
              caller='mobile'
              viewer='all'
              data={props.allUsers}
              handler={props.onAllPageChange}
              fetchingData={fetchingData}
            />
          )}
        </Row>
      </div>
      <MobileDrawer
        openSideBar={openSideBar}
        toggleSideBar={toggleSideBar}
      />
    </React.Fragment>
  )
}

export default MobilePayout
