import React, { useEffect } from 'react'
import { Input, Icon, Button, Text, Col, Row } from 'components'
import { theme } from 'lib'

function Login({
  email,
  checkEmailError,
  onEmailChange,
  password,
  checkPasswordError,
  onPasswordChange,
  checkLogin,
  handleLogin,
  error
}) {
  useEffect(() => {
    document.body.style.backgroundColor = theme.DARK_TEAL_20
    return () => {
      document.body.style.backgroundColor = theme.WHITE
    }
  }, [])
  return (
    <React.Fragment>
      <Col ht='100vh' bg={theme.DARK_TEAL_20}>
        <Col centerAll marg='0 0 0 0' minHt='256px'>
          <Col noFlex>
            <Text
              QS
              weight='700'
              size='26px'
              lineHeight='32px'
              center
            >
              Log in To Trally
            </Text>
            <Text
              weight='400'
              size='14px'
              lineHeight='18px'
              spacing='0.5px'
              color={theme.GRAY_60}
              marg='8px 0 0'
              center
            >
              Enter your details below
            </Text>
          </Col>

          <Col centerALl noFlex marg='51px 28px 0'>
            <Input
              rounded
              placeholder={'Email'}
              err={checkEmailError}
              value={email}
              onChange={e => onEmailChange(e.target.value)}
              wid='320px'
              errMessage={'Invalid Email'}
            />
            <Input
              rounded
              placeholder={'Password'}
              marg='15px 0 0 0'
              value={password}
              onChange={e => onPasswordChange(e.target.value)}
              err={checkPasswordError}
              wid='320px'
              type='password'
              errMessage={'Invalid Password'}
            />
          </Col>
        </Col>

        <Col noFlex centerAll marg='0 0 28px 0'>
          {error && error.length > 0 && (
            <Row noFlex center marg='10px 0  '>
              <Icon type='close-red' size='9px' />
              <Text
                marg='0 0 0 10px'
                color={theme.RED}
                weight='400'
                size='14px'
                spacing='0.5px'
              >
                {error}
              </Text>
            </Row>
          )}

          <Button
            onClick={handleLogin}
            disabled={
              checkEmailError || checkPasswordError || checkLogin
            }
            color={theme.WHITE}
            black={true}
            text={'Login'}
            wid='320px'
            center
            action='login'
          />
        </Col>
      </Col>
    </React.Fragment>
  )
}

export default Login
