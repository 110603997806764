import React, { useState, useMemo } from 'react'
import {
  Col,
  Text,
  MobileHeader,
  Icon,
  MobileDrawer,
  Spinner,
  SwipeableTab
} from 'components'
import {
  BookedTourGuests,
  WatchedTourGuests,
  InterestedTourGuests
} from './components'
import { useHistory } from 'react-router-dom'

const MobileTourGuests = ({
  fetchingData,
  tourBooked,
  tourWatched,
  onBookedSearchChange,
  onWatchedSearchChange,
  selectedMenu,
  tourId,
  tourInterested,
  onInterestedSearchChange,
  handler
}) => {
  const history = useHistory()

  const [openSideBar, setOpenSideBar] = useState(false)
  const toggleSideBar = () => {
    setOpenSideBar(!openSideBar)
  }

  const tabs = useMemo(() => {
    return [
      {
        name: 'Booked Tours',
        link: `/tour/guests/${tourId}/booked`,
        tab: 'booked'
      },
      {
        name: 'Watched Tours',
        link: `/tour/guests/${tourId}/watched`,
        tab: 'watched'
      }
    ]
  }, [tourId])

  return (
    <React.Fragment>
      <MobileHeader
        fixed
        leftClick={() => history.push('/tours')}
        Left={
          <React.Fragment>
            <Icon type={'back'} size='1.25rem' />
          </React.Fragment>
        }
        Middle={
          <Text
            QS
            weight='700'
            size='1.125rem'
            lineHeight='1.25rem'
            spacing='0.0187rem'
          >
            Guests
          </Text>
        }
        pad='1.1875rem 1.0625rem 0 1.25rem'
      />

      <div style={{ margin: '0 1rem', height: 'calc(100vh - 62px)' }}>
        {fetchingData && !tourBooked && !tourWatched ? (
          <Col>
            <Spinner />
          </Col>
        ) : !tourBooked && !tourWatched ? (
          <Col centerAll>
            <Text weight='700' size={'16px'} lineHeight='40px' QS>
              No Guests found
            </Text>
          </Col>
        ) : (
          <React.Fragment>
            {tourBooked && tourBooked.isComingSoon ? (
              <InterestedTourGuests
                fetchingData={fetchingData}
                tourInterested={tourInterested}
                onInterestedSearchChange={onInterestedSearchChange}
                handler={handler}
                tripTitle={tourBooked?.tripTitle}
                isMobile={true}
              />
            ) : (
              <SwipeableTab
                tabItems={tabs}
                initialSelected={
                  tabs.findIndex(t => t.tab === selectedMenu) || 0
                }
              >
                <BookedTourGuests
                  fetchingData={fetchingData}
                  tourBooked={tourBooked}
                  onBookedSearchChange={onBookedSearchChange}
                  isMobile={true}
                />

                <WatchedTourGuests
                  fetchingData={fetchingData}
                  tourWatched={tourWatched}
                  onWatchedSearchChange={onWatchedSearchChange}
                  isMobile={true}
                />
              </SwipeableTab>
            )}
          </React.Fragment>
        )}
      </div>

      <MobileDrawer
        openSideBar={openSideBar}
        toggleSideBar={toggleSideBar}
      />
    </React.Fragment>
  )
}

export default MobileTourGuests
