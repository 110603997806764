import React from 'react'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox
} from '@material-ui/core'
import {
  Col,
  Text,
  Spinner,
  Link,
  StyledTableCell,
  Pagination
} from 'components'

import NoFeedbackImage from 'assets/host.png'
import { format } from 'date-fns'
import { checkStatus, closestScheduleDate } from 'lib/helpers'

const CustomTable = ({
  caller,
  data,
  handler,
  fetchingData,
  handleSelectedTrip
}) => {
  const handleChange = trip => {
    handleSelectedTrip(trip)
  }

  return (
    <React.Fragment>
      <TableContainer component={Paper} style={{}}>
        <Table
          aria-label='customized table'
          style={caller === 'desktop' ? {} : { display: 'contents' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell caller={caller}>ID</StyledTableCell>

              <React.Fragment>
                <React.Fragment>
                  <StyledTableCell caller={caller}>
                    Tour Name
                  </StyledTableCell>
                  <StyledTableCell caller={caller}>
                    Tour ID
                  </StyledTableCell>
                  <StyledTableCell>Host</StyledTableCell>
                </React.Fragment>
              </React.Fragment>

              <StyledTableCell caller={caller}>
                Actual Date Time
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Status
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Select
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {fetchingData ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={12}>
                  <Col>
                    <Spinner />
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : data && data.totalDocs <= 0 ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={9}>
                  <Col centerAll>
                    <img
                      src={NoFeedbackImage}
                      width={caller === 'desktop' ? 130 : 100}
                      alt='no feedback'
                      style={{
                        marginTop: 50,
                        marginBottom: 10
                      }}
                    />
                    <Text
                      weight='700'
                      size={caller === 'desktop' ? '16px' : '14px'}
                      lineHeight='40px'
                      QS
                    >
                      No tour found
                    </Text>
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {data && data.docs ? (
                <React.Fragment>
                  {data.docs.map((trip, index) => {
                    return (
                      <TableRow key={index}>
                        <StyledTableCell
                          component='th'
                          scope='row'
                          caller={caller}
                        >
                          {(data?.page - 1) * 10 + (index + 1)}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          <Link
                            url={`/explore/${trip._id}`}
                            styles={{
                              width: '100%'
                            }}
                          >
                            {trip.name}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {trip._id}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            isAdmin={true}
                            url={`/view/${trip.host._id}`}
                            styles={{
                              width: '100%'
                            }}
                          >
                            {trip.host.name}
                          </Link>
                        </StyledTableCell>

                        {trip.dates &&
                        trip.dates.length >= 1 &&
                        closestScheduleDate(trip.dates).start ? (
                          <StyledTableCell caller={caller}>
                            {format(
                              new Date(
                                closestScheduleDate(trip.dates).start
                              ),
                              'dd MMMM, p OOO'
                            )}{' '}
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell caller={caller}>
                            -
                          </StyledTableCell>
                        )}
                        <StyledTableCell
                          caller={caller}
                          style={{
                            textTransform: 'capitalize'
                          }}
                        >
                          {checkStatus(trip)}
                        </StyledTableCell>

                        <StyledTableCell caller={caller}>
                          <Checkbox
                            size='small'
                            color='primary'
                            onChange={() => handleChange(trip)}
                            checked={false}
                          />
                        </StyledTableCell>
                      </TableRow>
                    )
                  })}
                </React.Fragment>
              ) : null}
            </TableBody>
          )}
        </Table>
        <Pagination total={data?.totalPages} handler={handler} />
      </TableContainer>
    </React.Fragment>
  )
}

export default CustomTable
