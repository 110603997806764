import React, {
  useState,
  useEffect,
  useCallback,
  useMemo
} from 'react'
import {
  Col,
  Row,
  Text,
  Button,
  Input,
  ResponsiveModal,
  Icon
} from 'components'
import { theme, Api } from 'lib'
import Tours from './Tours'
import SelectedTours from './SelectedTours'
import { editCategory, setCategory } from 'actions/categories'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const EditCategory = () => {
  const { category } = useSelector(({ categories }) => categories)

  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [err, setErr] = useState(false)
  const [fetchingData, setFetchingData] = useState(false)
  const [allTours, setAllTours] = useState([])
  const [allPageNo, setAllPageNo] = useState(1)
  const [search, setSearch] = useState('')
  const [selectedTrips, setSelectedTrips] = useState([])
  const [isUpdated, setIsUpdated] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  const handleSetCategory = useCallback(() => {
    dispatch(setCategory({ data: null }))
  }, [dispatch])

  useEffect(() => {
    if (category) {
      setTitle(category.title)
      setDescription(category.description)
      setSelectedTrips(category.trips)
    }

    return () => {
      handleSetCategory()
    }
  }, [category, handleSetCategory])

  useEffect(() => {
    if (category) {
      async function fetchData() {
        setFetchingData(true)
        const res = await Api.get(
          `/admin/getAllTours/${true}/` +
            allPageNo +
            '?search=' +
            search
        )
        if (res) {
          setAllTours({
            ...res,
            docs:
              res.docs && res.docs.length > 0
                ? res.docs
                    .reverse()
                    .filter(
                      i =>
                        category.trips.filter(j => j._id === i._id)
                          .length === 0
                    )
                : []
          })
        }
        setFetchingData(false)
      }
      fetchData()
    }
  }, [allPageNo, search, category, handleSetCategory])

  const trips = useMemo(
    () => selectedTrips.map(({ _id }) => _id),
    [selectedTrips]
  )

  const onSearchChange = ({ target: { value } }) => {
    setSearch(value)
  }
  const onAllPageChange = page => {
    setAllPageNo(page)
  }

  const handleChange = ({ target }) => {
    if (target.value.length > 70) {
      setErr(true)
      return
    }
    setErr(false)
    setDescription(target.value)
  }

  const handleTitleChange = ({ target }) => {
    setTitle(target.value)
  }

  const handleSelectedTrip = trip => {
    const tempTrip = selectedTrips.find(t => t._id === trip._id)
    if (!tempTrip) {
      setSelectedTrips([trip, ...selectedTrips])
      setAllTours({
        ...allTours,
        docs: allTours.docs.filter(i => i._id !== trip._id)
      })
    }
  }

  const onRemoveSelectedTrip = trip => {
    const tempTrips = selectedTrips.filter(t => t._id !== trip._id)
    setSelectedTrips(tempTrips)
    setAllTours({
      ...allTours,
      docs: [trip, ...allTours.docs]
    })
  }

  const handleEditCategory = async () => {
    dispatch({
      type: 'LOADING_BUTTON',
      payload: 'editCategory'
    })
    dispatch(
      editCategory({
        category: {
          _id: category._id,
          title,
          description,
          trips
        },
        action: res => {
          if (res) {
            setIsUpdated(true)
          }
        }
      })
    )
  }

  return (
    <React.Fragment>
      <div style={{ margin: '8rem 4rem' }}>
        <Row between center>
          <Col noFlex>
            <Text
              QS
              size='30px'
              lineHeight='38px'
              weight='700'
              color={theme.BLACK_TRALLY}
            >
              Add New Category
            </Text>
          </Col>
        </Row>

        <Col style={{ marginTop: '2.5rem' }}>
          <Row
            style={{ alignItems: 'flex-end', marginBottom: '.8rem' }}
          >
            <Text
              QS
              size='20px'
              lineHeight='25px'
              weight='700'
              color={theme.BLACK_TRALLY}
            >
              Category Title
            </Text>
          </Row>
          <Row>
            <Input
              placeholder={'Type your heading'}
              placeholderTextColor={theme.GRAY_91}
              wid={'100%'}
              hasRadius='53px'
              border={theme.GRAY_91}
              style={{
                backgroundColor: theme.WHITE
              }}
              ht='48px'
              weight='400'
              lineHeight='15px'
              pad='0 30px'
              value={title}
              onChange={handleTitleChange}
            />
          </Row>
        </Col>
        <Col style={{ marginTop: '1.5rem' }}>
          <Row
            style={{ alignItems: 'flex-end', marginBottom: '.8rem' }}
          >
            <Text
              QS
              size='20px'
              lineHeight='25px'
              weight='700'
              color={theme.BLACK_TRALLY}
            >
              Description
            </Text>
          </Row>
          <Row>
            <Input
              placeholder={
                'Type your description (this will appear below your title in the home page).'
              }
              placeholderTextColor={theme.GRAY_91}
              wid={'100%'}
              hasRadius='30px'
              border={theme.GRAY_91}
              style={{
                backgroundColor: theme.WHITE
              }}
              ht='113px'
              weight='400'
              lineHeight='15px'
              pad='23px 30px'
              multiline
              err={err}
              value={description}
              onChange={handleChange}
            />
          </Row>

          <Text
            marg='5px 0 0 0'
            QS
            size='12px'
            lineHeight='15px'
            weight='400'
            color={
              description.length > 70 ? theme.RED : theme.GRAY_91
            }
            style={{ alignSelf: 'flex-end' }}
          >
            Characters limit 70
          </Text>
        </Col>
        <SelectedTours
          selectedTrips={selectedTrips}
          setSelectedTrips={setSelectedTrips}
          onRemoveSelectedTrip={onRemoveSelectedTrip}
        />
        <Tours
          fetchingData={fetchingData}
          allTours={allTours}
          onAllPageChange={onAllPageChange}
          onSearchChange={onSearchChange}
          handleSelectedTrip={handleSelectedTrip}
        />
        <Row
          style={{
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Button
            ht='50px'
            wid='170px'
            text='Cancel'
            onClick={() => history.push('/categories')}
            bgColor={'transparent'}
            color={theme.BLACK_TRALLY}
            textStyles={{
              fontSize: 18,
              fontWeight: '700',
              lineHeight: 19
            }}
            hasRadius='27px'
            bordered
            noShadow
            borderColor={theme.BLACK_TRALLY}
            marg='0 50px 0 0'
          />
          <Button
            ht='50px'
            wid='170px'
            text='Save'
            onClick={handleEditCategory}
            bgColor={theme.BLACK_TRALLY}
            color={theme.WHITE}
            textStyles={{
              fontSize: 18,
              fontWeight: '700',
              lineHeight: 19
            }}
            hasRadius='27px'
            noShadow
            action='editCategory'
          />
        </Row>
        <ResponsiveModal
          visible={isUpdated}
          toggleVisible={() => {}}
          desktopModalWid='28rem'
          desktopModalHt='auto'
          desktopModalBorderRadius='2rem'
          isMobile={false}
        >
          <Col pad='2rem 0'>
            <Col noFlex centerAll>
              <Row noFlex>
                <Icon type='check-ouline' size='3rem' />
              </Row>
              <Text
                QS
                weight='600'
                size='1.12rem'
                color={theme.BLACK_TRALLY}
                center
                marg='1.5rem 0'
              >
                You Have Edited Your Category Successfully
              </Text>
              <Text
                QS
                weight='400'
                size='1.125'
                color={theme.BLACK_TRALLY}
                center
              >
                You can edit, delete and view your category.
              </Text>

              <Row centerAll marg='1.5rem 0 0 0'>
                <Button
                  noShadow
                  ht='2.8rem'
                  wid='9rem'
                  black
                  text='Done'
                  onClick={() => {
                    setIsUpdated(false)
                    history.push('/categories')
                  }}
                />
              </Row>
            </Col>
          </Col>
        </ResponsiveModal>
      </div>
    </React.Fragment>
  )
}

export default EditCategory
