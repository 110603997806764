import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles
} from '@material-ui/core'
import { Button, Col, Text, Spinner } from 'components'
import PaginationView from './paginationView'
import { Link } from 'react-router-dom'
import NoFeedbackImage from 'assets/host.png'
import { format } from 'date-fns'

const StyledTableCell = withStyles(theme => ({
  head: {
    fontSize: 14,
    lineHeight: '12px',
    fontWeight: 'bold',
    backgroundColor: '#F4F3F3',
    textAlign: 'center',
    color: '#22191A',
    width: props =>
      props.width
        ? props.width
        : props.caller === 'mobile'
        ? '20vw'
        : ''
  },
  body: {
    fontSize: 12,
    textAlign: 'center'
  }
}))(TableCell)

const CustomTable = ({ caller, data, handler, fetchingData }) => {
  return (
    <React.Fragment>
      <TableContainer component={Paper} style={{}}>
        <Table
          aria-label='customized table'
          style={caller === 'desktop' ? {} : { display: 'contents' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell caller={caller}>ID</StyledTableCell>
              <StyledTableCell caller={caller}>Name</StyledTableCell>
              <StyledTableCell caller={caller}>
                Country Origin
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Trips Watched
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Followers
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Following
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Reviews Given
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Date Joined
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {fetchingData ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={6}>
                  <Col>
                    <Spinner />
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : data && data.totalDocs <= 0 ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={9}>
                  <Col centerAll>
                    <img
                      src={NoFeedbackImage}
                      width={caller === 'desktop' ? 130 : 100}
                      alt='no feedback'
                      style={{
                        marginTop: 50,
                        marginBottom: 10
                      }}
                    />
                    <Text
                      weight='700'
                      size={caller === 'desktop' ? '16px' : '14px'}
                      lineHeight='40px'
                      QS
                    >
                      No guests found
                    </Text>
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {data && data.docs ? (
                <React.Fragment>
                  {data.docs.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <StyledTableCell
                          component='th'
                          scope='row'
                          caller={caller}
                        >
                          {(data?.page - 1) * 10 + (index + 1)}
                        </StyledTableCell>

                        <StyledTableCell caller={caller}>
                          {row && row.name ? row.name : '-'}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {row && row.country ? row.country : '-'}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {row && row.tripsWatched
                            ? row.tripsWatched
                            : '0'}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {row && row.totalFollowers
                            ? row.totalFollowers
                            : '0'}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {row && row.totalFollowing
                            ? row.totalFollowing
                            : '0'}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {row && row.totalReviews
                            ? row.totalReviews
                            : '0'}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {row && row.createdAt
                            ? format(
                                new Date(row.createdAt),
                                'hh:mm a O, PPP'
                              )
                            : '-'}
                        </StyledTableCell>

                        <StyledTableCell caller={caller}>
                          <Link
                            to={
                              row && row._id
                                ? '/guest/' + row._id
                                : ''
                            }
                          >
                            <Button black text='View' />
                          </Link>
                        </StyledTableCell>
                      </TableRow>
                    )
                  })}
                </React.Fragment>
              ) : null}
            </TableBody>
          )}
        </Table>
        <PaginationView
          total={data?.totalPages}
          current={data?.page}
          hasNext={data?.hasNextPage}
          hasPrev={data?.hasPrevPage}
          handler={handler}
        />
      </TableContainer>
    </React.Fragment>
  )
}

export default CustomTable
