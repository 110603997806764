import React, { useMemo } from 'react'
import { SwipeableTab, MobileHeader, Icon, Text } from 'components'
import MobileGuestMenu from './components/menuPages/mobileGuestMenu'
import GuestTripsWatched from './components/menuPages/guestTripsWatched'
import GuestTripsBooked from './components/menuPages/guestTripsBooked'

function MobileView({
  selectedMenu,
  id,
  history,
  guest,
  loading,
  amplitudeUrl,
  watchedTrips,
  watchedSearchText,
  onWatchedSearchChange,
  bookedTrips,
  bookedSearchText,
  onBookedSearchChange
}) {
  const tabs = useMemo(() => {
    return [
      {
        name: 'Details',
        tab: 'guest',
        link: `/guest/${id}`
      },
      {
        name: 'Trips Watched',
        tab: 'tripsWatched',
        link: `/guest/${id}/tripsWatched`
      },
      {
        name: 'Trips Booked',
        tab: 'tripsBooked',
        link: `/guest/${id}/tripsBooked`
      }
    ]
  }, [id])

  return (
    <React.Fragment>
      <MobileHeader
        fixed
        leftClick={() => history.push('/guests')}
        Left={
          <React.Fragment>
            <Icon type={'back'} size='1.25rem' />
          </React.Fragment>
        }
        Middle={
          <Text
            QS
            weight='700'
            size='1.125rem'
            lineHeight='1.25rem'
            spacing='0.0187rem'
          >
            {guest?.name}
          </Text>
        }
        pad='1.1875rem 1.0625rem 0 1.25rem'
      />
      <SwipeableTab
        tabItems={tabs}
        initialSelected={
          tabs.findIndex(t => t.tab === selectedMenu) || 0
        }
      >
        <MobileGuestMenu guest={guest} amplitudeUrl={amplitudeUrl} />
        <GuestTripsWatched
          guest={guest}
          amplitudeUrl={amplitudeUrl}
          fetchingData={loading}
          tripsWatched={watchedTrips ? watchedTrips : []}
          search={watchedSearchText}
          onSearchChange={onWatchedSearchChange}
          isMobile={true}
        />
        <GuestTripsBooked
          guest={guest}
          amplitudeUrl={amplitudeUrl}
          fetchingData={loading}
          tripsBooked={bookedTrips ? bookedTrips : []}
          search={bookedSearchText}
          onSearchChange={onBookedSearchChange}
          isMobile={true}
        />
      </SwipeableTab>
    </React.Fragment>
  )
}

export default MobileView
