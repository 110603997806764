import { setToken, Api } from 'lib'

const login = ({ data, action }) => {
  return async dispatch => {
    const res = await Api.post('/admin/login', data)
    if (!res) return

    const { admin, token, status } = res

    if (status === 'success') {
      dispatch({
        type: 'SET_USER',
        payload: admin
      })

      setToken(token)
    }

    action && action(res)
  }
}

export default login
