import React from 'react'
import { Col, Row, Text, Input } from 'components'
import { Table } from './components'

const DesktopGuests = props => {
  let { fetchingData, allUsers, onAllPageChange, onSearchChange } =
    props
  return (
    <div style={{ margin: '8rem 4rem' }}>
      <Row>
        <Col>
          <Row>
            <Text QS size='32px' lineHeight='140%' weight='bold'>
              Guests
            </Text>
          </Row>
        </Col>
        <Col>
          <Row
            style={{ alignItems: 'flex-end', marginBottom: '0.2rem' }}
          >
            <Text QS size='20px' lineHeight='140%' weight='bold'>
              Search Guests
            </Text>
          </Row>
          <Row>
            <Input
              placeholder={'Search guests'}
              wid={window.innerWidth > 998 ? '80%' : '100%'}
              borderRadius='30px'
              rounded
              onChange={onSearchChange}
            />
          </Row>
        </Col>
      </Row>
      <Row marg='1rem 0rem'>
        <Table
          caller='desktop'
          viewer='all'
          data={allUsers}
          handler={onAllPageChange}
          fetchingData={fetchingData}
        />
      </Row>
    </div>
  )
}

export default DesktopGuests
