import React, { useState, useEffect } from 'react'
import { Col, Row, Text, Input } from 'components'
import { Api } from 'lib'
import Table from '../table'

const DesktopCompletedMenu = props => {
  let { id } = props

  const [loading, setLoading] = useState(false)
  const [completedTours, setCompletedTours] = useState(undefined)
  const [allPageNo, setAllPageNo] = useState(1)
  const [search, setSearch] = useState('')

  useEffect(() => {
    setLoading(true)
    async function fetchData() {
      const res = await Api.get(
        `/users/trips/completed/${id}/${allPageNo}?search=${search}`
      )

      if (res) {
        setCompletedTours(res)
      } else {
        console.log('error in fetching host')
      }
      setLoading(false)
    }

    fetchData()
  }, [allPageNo, search, id])

  const onSearchChange = ({ target: { value } }) => {
    setSearch(value)
  }
  const onAllPageChange = page => {
    setAllPageNo(page)
  }

  return (
    <div>
      <Row>
        <Col>
          <Row
            style={{ alignItems: 'flex-end', marginBottom: '0.2rem' }}
          >
            <Text QS size='20px' lineHeight='140%' weight='bold'>
              Search Completed Tours
            </Text>
          </Row>
          <Row>
            <Input
              placeholder={'Enter tour name to search'}
              wid={window.innerWidth > 998 ? '80%' : '100%'}
              borderRadius='30px'
              rounded
              onChange={onSearchChange}
            />
          </Row>
        </Col>
      </Row>
      <Row marg='1rem 0rem'>
        <Table
          caller='desktop'
          viewer='all'
          data={completedTours}
          handler={onAllPageChange}
          fetchingData={loading}
          isCompleted={true}
        />
      </Row>
    </div>
  )
}

export default DesktopCompletedMenu
