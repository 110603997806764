import React from 'react'
import { Col, Row, Text } from 'components'
import DragableTable from './DragableTable'
import { theme } from 'lib'

const SelectedTours = props => {
  let { selectedTrips, setSelectedTrips, onRemoveSelectedTrip } =
    props

  return (
    <div style={{ margin: '2.5rem 0px' }}>
      <Row>
        <Col>
          <Row>
            <Text
              QS
              size='30px'
              lineHeight='38px'
              weight='700'
              color={theme.BLACK_TRALLY}
            >
              Selected Tours
            </Text>
          </Row>
        </Col>
      </Row>
      <Row marg='1rem 0 0 0'>
        <DragableTable
          caller='desktop'
          viewer='all'
          selectedTrips={selectedTrips}
          setSelectedTrips={setSelectedTrips}
          onRemoveSelectedTrip={onRemoveSelectedTrip}
        />
      </Row>
    </div>
  )
}

export default SelectedTours
