import React from 'react'
import { Modal, ModalSheet } from 'components'

function ResponsiveModal({
  isMobile,
  visible,
  toggleVisible,
  desktopModalWid,
  desktopModalHt,
  desktopModalBorderRadius,
  mobileModalFullHt,
  mobileModalProps,
  desktopClose,
  mobileClose,
  children
}) {
  return (
    <React.Fragment>
      {!isMobile ? (
        <Modal
          visible={visible}
          closeModal={toggleVisible}
          wid={desktopModalWid ? desktopModalWid : '809px'}
          ht={desktopModalHt ? desktopModalHt : '598px'}
          borderRadius={
            desktopModalBorderRadius
              ? desktopModalBorderRadius
              : '9.21px'
          }
          noCloseButton={!desktopClose}
          noModalPadding
        >
          {children ? children : ''}
        </Modal>
      ) : (
        <ModalSheet
          visible={visible}
          toggleModal={toggleVisible}
          fullHt={mobileModalFullHt}
          overflowModal={mobileModalFullHt}
          noCloseButton={mobileClose}
          {...mobileModalProps}
        >
          {children ? children : ''}
        </ModalSheet>
      )}
    </React.Fragment>
  )
}

export default ResponsiveModal
