import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles
} from '@material-ui/core'
import { Button, Col, Text, Spinner } from 'components'
import NoFeedbackImage from 'assets/host.png'
import { format } from 'date-fns'
import { env } from 'lib'

const StyledTableCell = withStyles(theme => ({
  head: {
    fontSize: 14,
    lineHeight: '12px',
    fontWeight: 'bold',
    backgroundColor: '#F4F3F3',
    textAlign: 'center',
    color: '#22191A',
    width: props =>
      props.width
        ? props.width
        : props.caller === 'mobile'
        ? '20vw'
        : ''
  },
  body: {
    fontSize: 12,
    textAlign: 'center'
  }
}))(TableCell)

const CustomTable = ({ caller, data, fetchingData }) => {
  return (
    <React.Fragment>
      <TableContainer component={Paper} style={{}}>
        <Table
          aria-label='customized table'
          style={caller === 'desktop' ? {} : { display: 'contents' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell caller={caller}>ID</StyledTableCell>
              <StyledTableCell caller={caller}>
                Trip Name
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Host Name
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Start Time
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                End Time
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Actual Start Time
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Actual End Time
              </StyledTableCell>
              <StyledTableCell caller={caller}>View</StyledTableCell>
            </TableRow>
          </TableHead>
          {fetchingData ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={6}>
                  <Col>
                    <Spinner />
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : data && data.length <= 0 ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={9}>
                  <Col centerAll>
                    <img
                      src={NoFeedbackImage}
                      width={caller === 'desktop' ? 130 : 100}
                      alt='no feedback'
                      style={{
                        marginTop: 50,
                        marginBottom: 10
                      }}
                    />
                    <Text
                      weight='700'
                      size={caller === 'desktop' ? '16px' : '14px'}
                      lineHeight='40px'
                      QS
                    >
                      No Trips Found
                    </Text>
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {data ? (
                <React.Fragment>
                  {data.map((row, index) => {
                    const schedule =
                      row.tripId &&
                      row.tripId.dates &&
                      row.tripId.dates.find(
                        d => d._id + '' === row.schedule.scheduleId
                      )
                    return (
                      <TableRow key={row._id}>
                        <StyledTableCell
                          component='th'
                          scope='row'
                          caller={caller}
                        >
                          {index + 1}
                        </StyledTableCell>

                        <StyledTableCell caller={caller}>
                          {row && row.tripId && row.tripId.name
                            ? row.tripId.name
                            : '-'}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {row &&
                          row.tripId &&
                          row.tripId.host &&
                          row.tripId.host.name
                            ? row.tripId.host.name
                            : '-'}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {schedule && schedule.start
                            ? format(
                                new Date(schedule.start),
                                'hh:mm a O, PPP'
                              )
                            : '-'}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {schedule && schedule.end
                            ? format(
                                new Date(schedule.end),
                                'hh:mm a O, PPP'
                              )
                            : '-'}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {schedule && schedule.startedAt
                            ? format(
                                new Date(schedule.startedAt),
                                'hh:mm a O, PPP'
                              )
                            : '-'}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {schedule && schedule.endedAt
                            ? format(
                                new Date(schedule.endedAt),
                                'hh:mm a O, PPP'
                              )
                            : '-'}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          <a
                            rel='noreferrer'
                            href={
                              (env.API_URL.includes('localhost') ||
                              env.API_URL.includes('192.168')
                                ? 'http://localhost:3001'
                                : `https://${
                                    env.API_URL.includes(
                                      'stagingapi.trally'
                                    )
                                      ? 'staging.trally.com'
                                      : 'app.trally.com'
                                  }`) + `/explore/${row?.tripId?._id}`
                            }
                            target='_blank'
                            style={{
                              userSelect: 'none',
                              msTouchSelect: 'none',
                              msUserSelect: 'none',
                              MozUserSelect: 'none',
                              WebkitUserSelect: 'none'
                            }}
                          >
                            <Button black text='View' />
                          </a>
                        </StyledTableCell>
                      </TableRow>
                    )
                  })}
                </React.Fragment>
              ) : null}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </React.Fragment>
  )
}

export default CustomTable
