import React, { useEffect, useState, useRef } from 'react'
import { Api, env } from 'lib'
import DesktopGuests from './desktopGuests'
import MobileGuests from './mobileGuests'
import { useSelector } from 'react-redux'

function Guests() {
  const fetchTimerRef = useRef()
  const [fetchingData, setFetchingData] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [allPageNo, setAllPageNo] = useState(1)
  const [search, setSearch] = useState('')
  const [searchChanged, setSearchChanged] = useState(false)
  const { screenDimensions } = useSelector(state => state.appState)

  useEffect(() => {
    async function getData() {
      setFetchingData(true)
      const res = await Api.get(
        '/admin/getAllGuests/' + allPageNo + '?search=' + search
      )
      if (res) {
        setAllUsers({
          ...res,
          docs: res.docs && res.docs.length > 0 ? res.docs : []
        })
      } else {
        console.log('error in fetching guests')
      }
      setFetchingData(false)
    }

    if (searchChanged) {
      setAllPageNo(1)
      setSearchChanged(false)
    } else {
      getData()
    }
  }, [allPageNo, search, searchChanged])

  const onSearchChange = ({ target: { value } }) => {
    clearTimeout(fetchTimerRef.current)
    fetchTimerRef.current = setTimeout(async () => {
      setSearchChanged(true)
      setSearch(value)
    }, 500)
  }

  const onAllPageChange = page => {
    setAllPageNo(page)
  }

  return (
    <React.Fragment>
      {screenDimensions.width > env.SCREEN_WIDTH ? (
        <div style={{ marginTop: '80px' }}>
          <DesktopGuests
            fetchingData={fetchingData}
            allUsers={allUsers}
            onAllPageChange={onAllPageChange}
            onSearchChange={onSearchChange}
          />
        </div>
      ) : (
        <MobileGuests
          fetchingData={fetchingData}
          allUsers={allUsers}
          onAllPageChange={onAllPageChange}
          onSearchChange={onSearchChange}
        />
      )}
    </React.Fragment>
  )
}

export default Guests
