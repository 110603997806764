import React, { useMemo } from 'react'
import { SwipeableTab, MobileHeader, Icon, Text } from 'components'
import { useLocation } from 'react-router-dom'
import MobileHostMenu from './components/menuPages/mobileHostMenu'
import MobilePaymentMenu from './components/menuPages/mobilePaymentMenu'
import MobileAccountDetails from './components/menuPages/mobileAccountDetails'
import MobileToursMenu from './components/menuPages/mobileToursMenu'

function MobileView({
  id,
  history,
  host,
  selectedMenu,
  toggleReset,
  pendingHasTrips,
  goToTrialTrip,
  successFlag,
  approveFlag,
  setApproveFlag,
  onSuccess,
  rejectFlag,
  setRejectFlag,
  deactivateFlag,
  setDeactivateFlag,
  loading,
  activateFlag,
  setActivateFlag,
  setModal,
  setMessageModal,
  stripeSupported
}) {
  const { pathname } = useLocation()

  const tabs = useMemo(() => {
    return [
      {
        name: 'Details',
        tab: 'host',
        link: `/view/${id}`
      },
      {
        name: 'Account',
        tab: 'account',
        link: `/view/${id}/account`
      },
      {
        name: 'Payment',
        tab: 'payment',
        link: `/view/${id}/payment`
      },
      {
        name: 'Tours',
        tab: 'tours',
        link: `/view/${id}/tours`
      }
    ]
  }, [id])

  return (
    <React.Fragment>
      <MobileHeader
        fixed
        leftClick={() =>
          pathname.includes('/payment')
            ? history.push('/payout')
            : history.push('/')
        }
        Left={
          <React.Fragment>
            <Icon type={'back'} size='1.25rem' />
          </React.Fragment>
        }
        Middle={
          <Text
            QS
            weight='700'
            size='1.125rem'
            lineHeight='1.25rem'
            spacing='0.0187rem'
          >
            {host?.name}
          </Text>
        }
        pad='1.1875rem 1.0625rem 0 1.25rem'
      />
      <SwipeableTab
        tabItems={tabs}
        initialSelected={
          tabs.findIndex(t => t.tab === selectedMenu) || 0
        }
      >
        <MobileHostMenu
          toggleReset={toggleReset}
          host={host}
          pendingHasTrips={pendingHasTrips}
          goToTrialTrip={goToTrialTrip}
          id={id}
          successFlag={successFlag}
          approveFlag={approveFlag}
          setApproveFlag={setApproveFlag}
          onSuccess={onSuccess}
          rejectFlag={rejectFlag}
          setRejectFlag={setRejectFlag}
          deactivateFlag={deactivateFlag}
          setDeactivateFlag={setDeactivateFlag}
          loading={loading}
          activateFlag={activateFlag}
          setActivateFlag={setActivateFlag}
        />
        <MobileAccountDetails host={host} />
        <MobilePaymentMenu
          host={host}
          setModal={setModal}
          setMessageModal={setMessageModal}
          stripeSupported={stripeSupported}
        />
        <MobileToursMenu
          id={id}
          host={host}
          setModal={setModal}
          setMessageModal={setMessageModal}
          stripeSupported={stripeSupported}
        />
      </SwipeableTab>
    </React.Fragment>
  )
}

export default MobileView
