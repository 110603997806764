import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { Icon } from 'components'
import circle from 'assets/avatar-circle.png'
import noProfilePicture from 'assets/no-profile-picture.png'
import { Spinner } from 'components'
import { theme } from 'lib'

const Wrapper = styled.div`
  width: ${props => props.size + 'px'};
  height: ${props => props.size + 'px'};
  background-size: ${props => props.size + 'px ' + props.size + 'px'};
  display: flex;
  align-items: center;
  justify-content: center;

  ${props =>
    props.uri &&
    `
    background-image: ${props.noCircle ? '' : `url(${circle})`}; 
    background-repeat: no-repeat;
  `}
`

function Avatar(props) {
  const [err, setErr] = useState(false)
  const { uri, size, noCircle, small, navBar, edit } = props
  const loading = useSelector(
    state => state.appState.loadingButton === 'avatar'
  )

  if (!uri && navBar) {
    return <Icon type='profile' size={'25px'} />
  }

  return (
    <Wrapper
      size={size}
      uri={uri || noProfilePicture}
      noCircle={noCircle}
    >
      {loading && edit && <Spinner />}

      {!loading && !err && (
        <img
          src={uri || noProfilePicture}
          alt='avatar'
          style={{
            width: size - 4,
            height: size - 4,
            borderRadius: props.hasRadius ? props.hasRadius : '50%',
            textIndent: -9999,
            alignSelf: 'center',
            border: `${
              props.noBorder
                ? 'none'
                : small
                ? `1px solid ${theme.WHITE}`
                : `3px solid ${theme.WHITE}`
            }`
          }}
          onError={() => setErr(true)}
        />
      )}
    </Wrapper>
  )
}

export default Avatar
