import React from 'react'
import styled, { css } from 'styled-components'
import { buttonStyles } from 'styles'
import { Text, Icon, Spinner } from '.'
import { theme } from 'lib'
import { useSelector } from 'react-redux'

const StyledButton = styled.button`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  ${props => props.capitalize && 'text-transform: capitalize;'}
  &:active {
    opacity: 0.9;
  }

  ${props => buttonStyles(props, css)}
`

const MyButton = props => {
  const { loadingButton } = useSelector(({ appState }) => appState)
  const loading = loadingButton && loadingButton === props.action

  return (
    <StyledButton {...props} loading={loading ? 'true' : null}>
      {props.icon && (
        <Icon type={props.icon} size={props.text ? '18px' : '20px'} />
      )}
      {loading ? (
        <Spinner />
      ) : props.text ? (
        <Text
          style={{
            fontWeight: 'bold',
            color: props.color
              ? props.color || theme.GRAY_40
              : (props.disabled && theme.GRAY_20) ||
                (props.black ? theme.WHITE : theme.BLACK),
            marginLeft: props.icon ? 13 : 0,
            fontFamily: 'Quicksand',
            fontSize: props.fontSize ? props.fontSize : 14,
            whiteSpace: props.noWrap ? 'nowrap' : 'normal',
            ...props.textStyles
          }}
        >
          {props.text}
        </Text>
      ) : props.children ? (
        props.children
      ) : (
        ''
      )}
    </StyledButton>
  )
}

export default MyButton
