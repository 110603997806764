import { Api } from 'lib'

function marOneRead(payload) {
  return async (dispatch, getState) => {
    const res = await Api.patch('/notifications/markOneRead', {
      notifId: payload
    })
    if (!res) return

    dispatch({
      type: 'MARK_NOTIF_READ',
      payload: payload
    })
  }
}

export default marOneRead
