import React from 'react'
import { Row, Text, Clickable } from 'components'
import { theme } from 'lib'

const menuOptions = [
  {
    title: 'Host Details',
    link: '',
    menu: 'host'
  },
  {
    title: 'Payment Details',
    link: '/payment',
    menu: 'payment'
  },
  {
    title: 'Scheduled Tours',
    link: '/scheduled',
    menu: 'scheduled'
  },
  {
    title: 'Completed Tours',
    link: '/completed',
    menu: 'completed'
  }
]
function Menu({ selectedMenu, setSelectedMenu, hostId, history }) {
  const changeMenu = ({ link, menu }) => {
    setSelectedMenu(menu)
    history.push(`/view/${hostId}${link}`)
  }

  return (
    <Row marg='0 0 4.875rem 0'>
      {menuOptions.map(m => (
        <Clickable
          onClick={() => changeMenu({ link: m.link, menu: m.menu })}
          key={m.menu}
        >
          <Text
            QS
            bold
            size='1.3rem'
            lineHeight='2.8rem'
            marg='0 2.25rem 0 0'
            color={
              m.menu === selectedMenu
                ? theme.FULL_BLACK
                : theme.gray16
            }
          >
            {m.title}
          </Text>
        </Clickable>
      ))}
    </Row>
  )
}

export default Menu
