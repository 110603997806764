import React from 'react'
import { ResponsiveModal, Col, Text, Row, Button } from 'components'
import { theme } from 'lib'

function DeleteModal({
  isMobile,
  isVisible,
  toggleVisible,
  handleDelete
}) {
  return (
    <ResponsiveModal
      visible={isVisible}
      toggleVisible={() => toggleVisible()}
      desktopModalWid='50.6875rem'
      desktopModalHt='24.875rem'
      desktopModalBorderRadius='2.5rem'
      isMobile={isMobile}
    >
      <Col ht='100%' wid='100%' noFlex centerAll>
        <Text
          QS
          weight='600'
          size='1.875rem'
          lineHeight='2.8594rem'
          color={theme.BLACK_TRALLY}
          center
          style={{
            maxWidth: '31.25rem'
          }}
        >
          Are you sure you want to delete the Tour?
        </Text>

        <Row centerAll noFlex marg='4.3125rem 0 0'>
          <Button
            noShadow
            ht='3.4375rem'
            wid='13rem'
            bgColor={'transparent'}
            color={theme.BLACK_TRALLY}
            bordered
            borderColor={theme.BLACK_TRALLY}
            marg='0 1.9375rem 0 0'
            text='No'
            onClick={() => toggleVisible()}
          />
          <Button
            noShadow
            ht='3.4375rem'
            wid='13rem'
            bgColor={theme.PURE_RED}
            color={theme.WHITE}
            text='Yes'
            onClick={handleDelete}
            action='deleteRecordedTour'
          />
        </Row>
      </Col>
    </ResponsiveModal>
  )
}

export default DeleteModal
