import React from 'react'
import { Row, Text, Clickable } from 'components'
import { theme } from 'lib'

const menuOptions = [
  {
    title: 'Booked Tours',
    link: '/booked',
    menu: 'booked'
  },
  {
    title: 'Watched Tours',
    link: '/watched',
    menu: 'watched'
  }
]
function Menu({ selectedMenu, setSelectedMenu, tourId, history }) {
  const changeMenu = ({ link, menu }) => {
    setSelectedMenu(menu)
    history.push(`/tour/guests/${tourId}${link}`)
  }

  return (
    <Row marg='0 0 2.25rem 0'>
      {menuOptions.map(m => (
        <Clickable
          onClick={() => changeMenu({ link: m.link, menu: m.menu })}
          key={m.menu}
        >
          <Text
            QS
            bold
            size='2rem'
            lineHeight='2.8rem'
            marg='2.25rem 2.25rem 0 0'
            color={
              m.menu === selectedMenu
                ? theme.FULL_BLACK
                : theme.gray16
            }
          >
            {m.title}
          </Text>
        </Clickable>
      ))}
    </Row>
  )
}

export default Menu
