import { theme } from '../lib'

const inputStyles = (props, css) => {
  const {
    marg,
    rounded,
    multiline,
    icon,
    limit,
    err,
    search,
    weight,
    ht,
    payment,
    border,
    hasRadius,
    lineHeight,
    pad
  } = props

  const container = `
    height: auto;
    margin: ${marg || 0};
    width: 100%;
  
    ${
      icon ||
      (search &&
        `
      height: 44px;
      align-items: center;
      border-radius: 8px;
      flex-direction: row;
      border: 1px solid ${theme.GRAY_6};
      background-color: ${theme.WHITE};
    `)
    };

    ${
      limit &&
      `
      border-radius: 8px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 65px;
      padding: 0 14px;
      border: 1px solid ${theme.GRAY_6};
    `
    };

    ${
      limit ||
      (icon &&
        err &&
        `
      border: 1px solid ${theme.DANGER};
    `)
    };

    ${
      search &&
      `
      backgroundColor: #f6f6f6;
      border: 0;
    `
    };
  `

  const input = `
    color: ${theme.BLACK};
    background-color: ${theme.gray1};
    border-radius: 8px;
    padding: 0 12px;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    height: 56px;
    font-family: Quicksand;
    ${
      rounded &&
      `
      height: 48px;
      font-size: 16px;
      letter-spacing: 0.12px;
      border-radius: 30px;
      padding: 0 24px;
      background-color: ${theme.Gray7};
    `
    };

    ${
      multiline &&
      `
      height: 164px;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: 0.3px;
      padding: 13px 14px; 
    `
    };

    ${
      limit &&
      `
      padding: 0;
      width: 80%;
      background-color: ${theme.WHITE};
    `
    };

    ${
      icon ||
      (search &&
        `
      height: 43px;
      padding: 0;
      flex: 1;
      background-color: ${theme.WHITE};
    `)
    };

    ${
      search &&
      `
      height: 42px;
      background-color: #f6f6f6;
      font-weight: normal;
      letter-spacing: 0.5px;
      font-family: SFProDisplay-regular;
    `
    };

    ${
      payment &&
      `
      height: 54px;
      background-color: ${theme.gray1};
      border-radius: 8px;
    `
    };

    ${err && !icon && !limit && `border: 1px solid ${theme.DANGER};`};
    ${err && `color: ${theme.DANGER}`};
    ${props.fullWid && `width: 100%;`};
    ${props.weight && `font-weight: ${weight}`};
    ${props.ht && `height: ${ht}`};
    ${props.border && `border: 1px solid ${border}`};
    ${props.hasRadius && `border-radius: ${hasRadius}`};
    ${props.lineHeight && `line-height: ${lineHeight}`};
    ${props.pad && `padding: ${pad}`};
  `

  const textarea = `
    color: ${theme.BLACK};
    background-color: ${theme.gray1};
    font-size: 16px;
    font-weight: normal;
    height: 56px;
    font-family: Quicksand;
    
    ${props.fullWid && `width: 100%;`};
    ${props.weight && `font-weight: ${weight}`};
    ${props.ht && `height: ${ht}`};
    ${props.border && `border: 1px solid ${border}`};
    ${props.hasRadius && `border-radius: ${hasRadius}`};
    ${props.lineHeight && `line-height: ${lineHeight}`};
    ${props.pad && `padding: ${pad}`};
    ${err && `border: 1px solid ${theme.DANGER};`};
  `

  return { container, input, textarea }
}

export default inputStyles
