import React from 'react'
import { Col, Row, Text, Input } from 'components'
import { FilterButton, Table } from './components'

const DesktopReports = props => {
  let {
    fetchingData,
    allUsers,
    onAllPageChange,
    onSearchChange,
    type,
    onTypeChange
  } = props
  return (
    <div style={{ margin: '8rem 4rem' }}>
      <Row>
        <Col>
          <Row>
            <Text QS size='32px' lineHeight='140%' weight='bold'>
              Reports
            </Text>
          </Row>
          <Row>
            <FilterButton
              text='Trips'
              value='trip'
              activeButton={type}
              changeFilter={onTypeChange}
            />
            <FilterButton
              text='Users'
              value='user'
              activeButton={type}
              changeFilter={onTypeChange}
            />
          </Row>
        </Col>
        <Col>
          <Row
            style={{ alignItems: 'flex-end', marginBottom: '0.2rem' }}
          >
            <Text QS size='20px' lineHeight='140%' weight='bold'>
              Search Reports
            </Text>
          </Row>

          <Row>
            <Input
              placeholder={'Search reports'}
              wid={window.innerWidth > 998 ? '80%' : '100%'}
              borderRadius='30px'
              rounded
              onChange={onSearchChange}
            />
          </Row>
        </Col>
      </Row>
      <Row marg='1rem 0rem'>
        <Table
          caller='desktop'
          viewer='all'
          data={allUsers}
          handler={onAllPageChange}
          fetchingData={fetchingData}
          type={type}
        />
      </Row>
    </div>
  )
}

export default DesktopReports
