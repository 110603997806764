import React from 'react'
import styled from 'styled-components'
import { Icon } from 'components'

const HandleWrapper = styled.div`
  vertical-align: bottom;
  display: inline-block;
  cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')};
`

const DragHandler = props => {
  return (
    <HandleWrapper {...props}>
      <Icon type='menu' size='1rem' />
    </HandleWrapper>
  )
}

export default DragHandler
