import React from 'react'
import DragHandler from './DragHandler'
import styled from 'styled-components'
import { Icon, Link, StyledTableCell } from 'components'
import { format } from 'date-fns'
import { theme } from 'lib'
import { checkStatus, closestScheduleDate } from 'lib/helpers'

const StyledStaticTableRow = styled.tr`
  box-shadow: rgb(0 0 0 / 10%) 0px 20px 25px -5px,
    rgb(0 0 0 / 30%) 0px 10px 10px -5px;
  outline: ${theme.BLACK_TRALLY} solid 1px;
  background-color: ${theme.WHITE};
`

export const StaticTableRow = ({ trip, caller, index }) => {
  return (
    <StyledStaticTableRow>
      <React.Fragment>
        <StyledTableCell caller={caller}>
          <DragHandler isDragging />
        </StyledTableCell>

        <StyledTableCell caller={caller}>{index + 1}</StyledTableCell>
        <StyledTableCell caller={caller}>
          <Link url={`/explore/${trip._id}`}>{trip.name}</Link>
        </StyledTableCell>
        <StyledTableCell caller={caller}>{trip._id}</StyledTableCell>
        <StyledTableCell>
          <Link isAdmin={true} url={`/view/${trip.host._id}`}>
            {trip.host.name}
          </Link>
        </StyledTableCell>
        {trip.dates &&
        trip.dates.length >= 1 &&
        closestScheduleDate(trip.dates).start ? (
          <StyledTableCell caller={caller}>
            {format(
              new Date(closestScheduleDate(trip.dates).start),
              'dd MMMM, p OOO'
            )}{' '}
          </StyledTableCell>
        ) : (
          <StyledTableCell caller={caller}>-</StyledTableCell>
        )}
        <StyledTableCell
          caller={caller}
          style={{
            textTransform: 'capitalize'
          }}
        >
          {checkStatus(trip)}
        </StyledTableCell>
        <StyledTableCell caller={caller}>
          <Icon type='close-outline' size='1rem' />
        </StyledTableCell>
      </React.Fragment>
    </StyledStaticTableRow>
  )
}
