import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useHistory, Link, useLocation } from 'react-router-dom'
import { Text, Button, Row, Avatar, Clickable, Col } from './'
import { theme } from 'lib'
import Logo from 'assets/logo.png'
import NoProfilePic from 'assets/no-profile-picture.png'
import { logout } from 'actions/user'

const NavPill = styled(Text)`
  width: 125px;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;

  &:hover {
    background-color: #f9f9f9;
    border-radius: 30px;
  }
`

const NavWrapper = styled.div`
  height: 79px;
  width: 100vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: ${theme.WHITE};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 999;
`

const NavBar = styled.div`
  width: 100vw;
  padding: ${props =>
    props.smallDesktop ? '0 40px 0 20px' : '0 145px'};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  a {
    margin-right: 40px;
    font-weight: bold;
    font-size: 16px;
    width: 80px;
    text-align: center;
    letter-spacing: 0.5px;
    color: ${theme.BLACK};
    font-family: Quicksand;
  }

  .active {
    background-color: ${theme.GRAY_20};
  }
`

const MenuWrapper = styled.div`
  background-color: ${theme.WHITE};
  position: absolute;
  width: 375px;
  max-height: 89vh;
  overflow-y: scroll;
  right: 0;
  top: 50px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  ${props => !props.visible && 'display: none;'}
`

const MenuOption = styled.div`
  cursor: pointer;
  background-color: ${theme.WHITE};
  height: 56px;
  border-top: 1px solid ${theme.gray4};
  border-bottom: 1px solid ${theme.gray4};
  padding: 0 0 0 17.5px;
  display: flex;
  align-items: center;
  ${props =>
    props.lastOption &&
    `
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  `}

  &:active {
    opacity: 0.6;
  }
`
const ActiveLine = styled.div`
  height: 2px;
  width: 28px;
  border-radius: 2px;
  background: linear-gradient(90deg, #4e6bee 0%, #00d6ca 100%);
  margin-top: 8px;
`

// const StyledText = styled(Text)`
//   position: absolute;
//   top: 8px;
//   left: 31px;
//   width: 17px;
//   height: 17px;
//   background-color: ${theme.TRALLY_RED};
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   border: 1px solid ${theme.WHITE};
// `

const Nav = () => {
  const { pathname } = useLocation()
  const [menuVisible, setMenuVisible] = useState(null)
  const [activePill, setActivePill] = useState(pathname)
  const [isHover, setIsHover] = useState(false)
  const user = useSelector(state => state.user)
  const history = useHistory()
  const dispatch = useDispatch()
  const node = useRef()
  const notificationRef = useRef()

  useEffect(() => {
    setActivePill(pathname)
  }, [pathname])

  useEffect(() => {
    if (!menuVisible) {
      document.removeEventListener('mousedown', handleClickOutside)
      return
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuVisible])

  function handleClickOutside(e) {
    if (node?.current?.contains(e.target)) return
    if (notificationRef?.current?.contains(e.target)) return
    setMenuVisible(null)
  }

  function handleLogout() {
    dispatch(logout({ action: history.push('/') }))
  }

  function handleMenuVisible(type) {
    if (type === menuVisible) return setMenuVisible(null)
    setMenuVisible(type)
  }

  function clickActions({ route }) {
    if (route) history.push(`/${route}`)
    handleMenuVisible()
  }
  return (
    <NavWrapper>
      <NavBar smallDesktop={window.innerWidth < 1204}>
        <Row center noFlex>
          <Clickable>
            <Row
              center
              noFlex
              minWid='106px'
              onClick={() => clickActions({ route: 'home' })}
            >
              <img
                src={Logo}
                style={{ height: 30 }}
                alt='trally-logo'
              />
            </Row>
          </Clickable>
        </Row>

        <Row center noFlex>
          {user ? (
            <Row center noFlex relative>
              {isHover && (
                <Col
                  onMouseEnter={() => setIsHover(true)}
                  onMouseLeave={() => setIsHover(false)}
                  noFlex
                  absolute
                  style={{
                    backgroundColor: theme.WHITE,
                    top: '40px',
                    left: '-40px'
                  }}
                  shadow='0px 0px 4px rgba(0, 0, 0, 0.15)'
                  hasRadius='10px'
                  pad='25px'
                >
                  <Link
                    onClick={() => setIsHover(false)}
                    to='/categories'
                    style={{
                      marginRight: '0px',
                      width: '100%',
                      textAlign: 'left'
                    }}
                  >
                    <Text
                      QS
                      weight='400'
                      size='16px'
                      lineHeight='19px'
                      marg='0 0 20px'
                      color={theme.FULL_BLACK}
                    >
                      Categories
                    </Text>
                  </Link>
                  <Link
                    to='/categories'
                    style={{
                      marginRight: '0px',
                      width: '100%',
                      textAlign: 'left'
                    }}
                  >
                    <Text
                      QS
                      weight='400'
                      size='16px'
                      lineHeight='19px'
                      color={theme.FULL_BLACK}
                    >
                      Upload Banner
                    </Text>
                  </Link>
                </Col>
              )}
              <Link
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
              >
                <Col noFlex centerAll>
                  <NavPill
                    QS
                    weight='700'
                    marg={
                      activePill === '/categories' ? '9px 0 0 0' : '0'
                    }
                    color={
                      activePill === '/categories'
                        ? theme.NEUTRAL_DARK
                        : theme.GRAY_3
                    }
                  >
                    Home Page
                  </NavPill>
                  {activePill === '/categories' && <ActiveLine />}
                </Col>
              </Link>
              <Link to='/explore'>
                <Col noFlex centerAll>
                  <NavPill
                    QS
                    weight='700'
                    marg={
                      activePill === '/' || activePill === '/home'
                        ? '9px 0 0 0'
                        : '0'
                    }
                    color={
                      activePill === '/' || activePill === '/home'
                        ? theme.NEUTRAL_DARK
                        : theme.GRAY_3
                    }
                  >
                    Hosts
                  </NavPill>
                  {(activePill === '/' || activePill === '/home') && (
                    <ActiveLine />
                  )}
                </Col>
              </Link>

              <Link to='/guests'>
                <Col noFlex centerAll>
                  <NavPill
                    QS
                    weight='700'
                    marg={
                      activePill === '/guests' ? '9px 0 0 0' : '0'
                    }
                    color={
                      activePill === '/guests'
                        ? theme.NEUTRAL_DARK
                        : theme.GRAY_3
                    }
                  >
                    Guests
                  </NavPill>
                  {activePill === '/guests' && <ActiveLine />}
                </Col>
              </Link>
              <Link to='/payout'>
                <Col noFlex centerAll>
                  <NavPill
                    QS
                    weight='700'
                    marg={
                      activePill === '/payout' ? '9px 0 0 0' : '0'
                    }
                    color={
                      activePill === '/payout'
                        ? theme.NEUTRAL_DARK
                        : theme.GRAY_3
                    }
                  >
                    Payout
                  </NavPill>
                  {activePill === '/payout' && <ActiveLine />}
                </Col>
              </Link>

              <Link to='/feedback'>
                <Col noFlex centerAll>
                  <NavPill
                    QS
                    weight='700'
                    marg={
                      activePill === '/feedback' ? '9px 0 0 0' : '0'
                    }
                    color={
                      activePill === '/feedback'
                        ? theme.NEUTRAL_DARK
                        : theme.GRAY_3
                    }
                  >
                    Feedback
                  </NavPill>
                  {activePill === '/feedback' && <ActiveLine />}
                </Col>
              </Link>

              <Link to='/reports'>
                <Col noFlex centerAll>
                  <NavPill
                    QS
                    weight='700'
                    marg={
                      activePill === '/reports' ? '9px 0 0 0' : '0'
                    }
                    color={
                      activePill === '/reports'
                        ? theme.NEUTRAL_DARK
                        : theme.GRAY_3
                    }
                  >
                    Reports
                  </NavPill>
                  {activePill === '/reports' && <ActiveLine />}
                </Col>
              </Link>

              <Link to='/tours'>
                <Col noFlex centerAll>
                  <NavPill
                    QS
                    weight='700'
                    marg={
                      activePill && activePill.includes('/tours')
                        ? '9px 0 0 0'
                        : '0'
                    }
                    color={
                      activePill && activePill.includes('/tours')
                        ? theme.NEUTRAL_DARK
                        : theme.GRAY_3
                    }
                  >
                    Tours
                  </NavPill>
                  {activePill && activePill.includes('/tours') && (
                    <ActiveLine />
                  )}
                </Col>
              </Link>
              {/* <Clickable
                marg='0 50px 0 0'
                onClick={() => handleMenuVisible('notifications')}
              >
                <Row
                  centerAll
                  noFlex
                  relative
                  wid='50px'
                  ht='50px'
                  bg={
                    user?.notifCount > 0
                      ? `${theme.gray10}`
                      : 'inherit'
                  }
                  hasRadius='50%'
                >
                  <Icon type='notifications' size='32px' />
                  {user?.notifCount > 0 && (
                    <StyledText
                      weight='700'
                      size='10px'
                      lineHeight='14.05px'
                      spacing='0.5px'
                      color={`${theme.WHITE}`}
                    >
                      {user.notifCount}
                    </StyledText>
                  )}
                </Row>
              </Clickable>

              <Notifications
                MenuWrapper={MenuWrapper}
                MenuOption={MenuOption}
                menuVisible={menuVisible}
                clickActions={clickActions}
                notificationRef={notificationRef}
              /> */}
              <Clickable
                onClick={() => handleMenuVisible('settings')}
              >
                <Avatar
                  uri={
                    user.avatar
                      ? user.avatar + '?time="' + new Date() + '"'
                      : NoProfilePic
                  }
                  size='48'
                  navBar
                />
              </Clickable>

              <MenuWrapper
                className='menuWrapper'
                visible={menuVisible === 'settings'}
                ref={node}
              >
                <Col ht='56px' endAll>
                  <Text marg='0 0 12px 23px' inputHeader>
                    SUPPORT
                  </Text>
                </Col>

                <MenuOption lastOption onClick={handleLogout}>
                  <Text QS bold size='16px'>
                    Log out
                  </Text>
                </MenuOption>
              </MenuWrapper>
            </Row>
          ) : (
            <Button
              ht='40px'
              text='Login'
              onClick={() => history.push('/auth')}
              black
            />
          )}
        </Row>
      </NavBar>
    </NavWrapper>
  )
}

export default Nav
