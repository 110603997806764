import React from 'react'
import styled from 'styled-components'
import { Col, Text, Spinner } from 'components'
import Menu from './components/menu'
import DesktopGuestMenu from './components/menuPages/desktopGuestMenu'
import GuestTripsWatched from './components/menuPages/guestTripsWatched'
import GuestTripsBooked from './components/menuPages/guestTripsBooked'

const Container = styled.div`
  padding: ${props =>
    props.smallScreen ? '4rem 1rem' : '4rem 8rem'};
`

const DesktopView = ({
  selectedMenu,
  setSelectedMenu,
  windowWidth,
  id,
  history,
  guest,
  loading,
  amplitudeUrl,
  watchedTrips,
  watchedSearchText,
  onWatchedSearchChange,
  bookedTrips,
  bookedSearchText,
  onBookedSearchChange
}) => {
  return (
    <React.Fragment>
      {guest ? (
        <Container smallScreen={windowWidth <= 960}>
          <Menu
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            guestId={id}
            history={history}
          />

          {selectedMenu && selectedMenu === 'guest' ? (
            <DesktopGuestMenu
              guest={guest}
              amplitudeUrl={amplitudeUrl}
            />
          ) : selectedMenu && selectedMenu === 'tripsWatched' ? (
            <GuestTripsWatched
              guest={guest}
              amplitudeUrl={amplitudeUrl}
              fetchingData={loading}
              tripsWatched={watchedTrips ? watchedTrips : []}
              search={watchedSearchText}
              onSearchChange={onWatchedSearchChange}
            />
          ) : selectedMenu && selectedMenu === 'tripsBooked' ? (
            <GuestTripsBooked
              guest={guest}
              amplitudeUrl={amplitudeUrl}
              fetchingData={loading}
              tripsBooked={bookedTrips ? bookedTrips : []}
              search={bookedSearchText}
              onSearchChange={onBookedSearchChange}
            />
          ) : (
            <React.Fragment />
          )}
        </Container>
      ) : loading ? (
        <Col centerAll wid='100vw' ht='100vh'>
          <Spinner />
        </Col>
      ) : (
        <Col centerAll wid='100vw' ht='100vh'>
          <Text QS weight='700' size='18px'>
            Guest not found
          </Text>
        </Col>
      )}
    </React.Fragment>
  )
}

export default DesktopView
