const watchDuration = seconds => {
  var hours = Math.floor(seconds / 3600)
  var mins = Math.floor((seconds % 3600) / 60)
  var secs = Math.floor((seconds % 3600) % 60)

  var hDisplay =
    hours > 0 ? hours + (hours === 1 ? ' hour, ' : ' hours, ') : ''
  var mDisplay =
    mins > 0 ? mins + (mins === 1 ? ' minute, ' : ' minutes, ') : ''
  var sDisplay =
    secs > 0 ? secs + (secs === 1 ? ' second' : ' seconds') : ''
  return hDisplay + mDisplay + sDisplay
}

export default watchDuration
