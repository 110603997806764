import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Row, Col, Text, Clickable, Icon } from '../'
import { theme, getNotifTime } from 'lib'
import { redirector } from 'core'
import ItemText from './ItemText'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

const NotificationMenu = styled(Col)`
  width: 311px;
  height: 108px;
  position: absolute;
  background-color: ${theme.WHITE};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  z-index: 1;
  left: 48px;
  top: 56px;
  padding: 4px 0;
`

const NotificationMenuOption = styled(Clickable)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  padding: 13px 0 14.5px 0;
  max-height: 50px;
  &:hover {
    background-color: ${theme.gray12};
  }
`

const StyledImg = styled.img`
  width: 48px;
  height: 48px;
  margin-right: 16px;
  border-radius: 48px;
`

// const StyledClickable = styled(Clickable)`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: 42.8px;
//   height: 38px;
//   border-radius: 50%;
//   ${props =>
//     props.opened && `background-color: rgba(34, 25, 26, 0.2);`};
//   ${props =>
//     props.opened && `box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);`};
// `

const Item = ({ data, isMobile }) => {
  const [notificationMenu] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()

  const { users, trip } = data
  let imageSource = users?.length
    ? { uri: users[0].avatar }
    : trip?._id
    ? { uri: trip.host.avatar }
    : require('../../assets/notification-blank.png')

  return (
    <Row
      relative
      center
      bg={data.read ? '#fff' : theme.DARK_TEAL_20}
      borderTop={`1px solid ${theme.gray4}`}
      borderBottom={`1px solid ${theme.gray4}`}
      pad='14px 0 14px 16px'
      wid={isMobile ? '100vw' : '375px'}
      ht='76px'
    >
      <Row noFlex wid='90%'>
        <Clickable
          marg='1px 0 0'
          onClick={() => redirector({ history, data, dispatch })}
        >
          <Row center>
            <StyledImg src={imageSource} />

            <Col ht='40px' between>
              <ItemText item={data} />
              <Text size='12px' color={theme.GRAY_40}>
                {getNotifTime(data.updatedAt)}
              </Text>
            </Col>
          </Row>
        </Clickable>
      </Row>
      {/* <StyledClickable
        marg='0 15px 0 17px'
        opened={notificationMenu}
        onClick={() => setNotificationMenu(!notificationMenu)}
      >
        <Icon
          type='more'
          size='20px'
        />
      </StyledClickable> */}

      {notificationMenu && (
        <NotificationMenu>
          <NotificationMenuOption>
            <Icon
              type='delete-filled'
              size='30px'
              ht='30px'
              marg='0 21.25px'
            />
            <Text
              pad='4px 0 0 0'
              weight='400'
              size='14px'
              lineHeight='16.71px'
              spacing='0.2px'
            >
              Delete this notification
            </Text>
          </NotificationMenuOption>
          <NotificationMenuOption>
            <Icon
              type='notification-stop'
              size='30px'
              ht='30px'
              marg='0 21.25px'
            />
            <Text
              pad='4px 0 0 0'
              center
              weight='400'
              size='14px'
              lineHeight='16.71px'
              spacing='0.2px'
            >
              Stop receiving notifications like this
            </Text>
          </NotificationMenuOption>
        </NotificationMenu>
      )}
    </Row>
  )
}

export default Item
