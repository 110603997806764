const theme = {
  BLACK: '#1E0407',
  FULL_BLACK: '#000000',
  BLACK_TRALLY: '#22191A',
  TRALLY_RED: '#E5295B',
  WHITE: '#ffffff',
  BLUE: '#4E6BEE',
  BLUE_1: '#2F80ED',
  BLUE_3: '#1890FF',
  BLUE_4: '#596AFF',
  BLUE_5: '#007DE2',
  LIGHT_BLUE: '#EDF0FD',
  RED: '#EB5757',
  RED_2: '#F54C3D',
  PURE_RED: '#FF0000',
  GRAY_3: '#8B8B8B',
  GRAY_5: '#E0E0E0',
  GRAY_7: '#F7F7F7',
  GRAY_6: '#F2F2F2',
  GRAY_20: '#BDBDBD',
  GRAY_40: '#828282',
  GRAY_60: '#4F4F4F',
  GRAY_77: '#C4C4C4',
  GRAY_90: '#E3E3E3',
  GRAY_91: '#A7A6A6',
  GRAY_92: '#F0F2F2',
  DANGER: '#E24448',
  CYAN_8: '#DFF0F2',
  TRALLY_DARK_TEAL: '#00D6CA',
  DARK_TEAL_20: '#DFF1F2',
  LIGHT_TEAL_20: '#DBECEA',
  DARK_GREEN: '#185D6E',
  TRALLY_GREEN: '#2AB5B1',
  GREEN: '#01D6CA',
  GREEN_LIGHT: '#C9FFFC',
  gray1: '#FAFAFA',
  gray2: '#ABABAB',
  gray3: '#EEEEEE',
  gray4: '#F1F1F1',
  gray5: 'rgba(30, 4, 7, 0.5)', // used for rounded input placeholder
  gray6: '#707070', // used for normal input placeholder
  gray7: '#F9F9F9',
  gray8: '#F6F6F6',
  gray9: '#989898',
  gray10: '#F0EFEF',
  gray11: '#A9A9A9',
  gray12: '#D3D1D1',
  gray13: '#E5E5E5',
  gray14: '#959595',
  gray15: '#6B6B6B',
  gray16: '#CACACA',
  gray17: '#6B6B6B',
  gray18: '#EAEAEA',
  gray19: '#F7FAFC',
  gray20: '#E3E8ED',
  gray21: '#697885',
  gray22: '#D4D3D3',
  DARK_PINK: '#FF1455',
  LIGHT_GRAY: '#DADADA',
  Gray7: '#F4F3F3',
  alert: '#EB5757',
  warning: '#FAAD14',
  YELLOW: '#FFBF63',
  NEUTRAL_DARK: '#2C2C2C'
}
export default theme
