import React, { useEffect, useState } from 'react'
import { Api, env } from 'lib'
import DesktopReports from './desktopReports'
import MobileReports from './mobileReports'
import { useSelector } from 'react-redux'

function Reports() {
  const [fetchingData, setFetchingData] = useState(false)
  const [allUsers, setAllUsers] = useState([])
  const [allPageNo, setAllPageNo] = useState(1)
  const [type, setType] = useState('trip')
  const [search, setSearch] = useState('')
  const { screenDimensions } = useSelector(state => state.appState)

  useEffect(() => {
    async function fetchData() {
      setFetchingData(true)
      const res = await Api.get(
        '/admin/getAllReports/' +
          type +
          '/' +
          allPageNo +
          '?search=' +
          search
      )
      if (res) {
        setAllUsers(res)
      } else {
        console.log('error in fetching reports')
      }
      setFetchingData(false)
    }
    fetchData()
  }, [allPageNo, search, type])

  const onTypeChange = value => {
    setAllPageNo(1)
    setType(value)
  }
  const onSearchChange = ({ target: { value } }) => {
    setSearch(value)
  }
  const onAllPageChange = page => {
    setAllPageNo(page)
  }

  return (
    <React.Fragment>
      {screenDimensions.width > env.SCREEN_WIDTH ? (
        <div style={{ marginTop: '80px' }}>
          <DesktopReports
            fetchingData={fetchingData}
            allUsers={allUsers}
            onAllPageChange={onAllPageChange}
            onSearchChange={onSearchChange}
            type={type}
            onTypeChange={onTypeChange}
          />
        </div>
      ) : (
        <MobileReports
          fetchingData={fetchingData}
          allUsers={allUsers}
          onAllPageChange={onAllPageChange}
          onSearchChange={onSearchChange}
          type={type}
          onTypeChange={onTypeChange}
        />
      )}
    </React.Fragment>
  )
}

export default Reports
