import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DragHandler from './DragHandler'
import { TableCell, TableRow, withStyles } from '@material-ui/core'

import { Icon, Link, StyledTableCell, Clickable } from 'components'
import { format } from 'date-fns'
import { theme } from 'lib'
import { checkStatus, closestScheduleDate } from 'lib/helpers'

const DraggingRow = withStyles(() => ({
  head: {
    backgroundColor: theme.Gray7,
    color: theme.BLACK_TRALLY,
    width: props =>
      props.width
        ? props.width
        : props.caller === 'mobile'
        ? '20vw'
        : ''
  }
}))(TableCell)

const DraggableTableRow = ({
  trip,
  caller,
  index,
  onRemoveSelectedTrip
}) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging
  } = useSortable({
    id: trip._id
  })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    backgroundColor: theme.GREEN_LIGHT
  }

  return (
    <TableRow ref={setNodeRef} style={style}>
      {isDragging ? (
        <DraggingRow />
      ) : (
        <React.Fragment>
          <StyledTableCell caller={caller}>
            <DragHandler {...attributes} {...listeners} />
          </StyledTableCell>

          <StyledTableCell caller={caller}>
            {index + 1}
          </StyledTableCell>
          <StyledTableCell caller={caller}>
            <Link url={`/explore/${trip._id}`}>{trip.name}</Link>
          </StyledTableCell>
          <StyledTableCell caller={caller}>
            {trip._id}
          </StyledTableCell>
          <StyledTableCell>
            <Link isAdmin={true} url={`/view/${trip.host._id}`}>
              {trip.host.name}
            </Link>
          </StyledTableCell>
          {trip.dates &&
          trip.dates.length >= 1 &&
          closestScheduleDate(trip.dates).start ? (
            <StyledTableCell caller={caller}>
              {format(
                new Date(closestScheduleDate(trip.dates).start),
                'dd MMMM, p OOO'
              )}{' '}
            </StyledTableCell>
          ) : (
            <StyledTableCell caller={caller}>-</StyledTableCell>
          )}
          <StyledTableCell
            caller={caller}
            style={{
              textTransform: 'capitalize'
            }}
          >
            {checkStatus(trip)}
          </StyledTableCell>
          <StyledTableCell caller={caller}>
            <Clickable onClick={() => onRemoveSelectedTrip(trip)}>
              <Icon type='close-outline' size='1rem' />
            </Clickable>
          </StyledTableCell>
        </React.Fragment>
      )}
    </TableRow>
  )
}

export default DraggableTableRow
