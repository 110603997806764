import React, { useMemo, useState } from 'react'
import { Row, Col, Icon, Text, Button, Clickable } from 'components'
import { theme } from 'lib'
import styled from 'styled-components'
import { format } from 'date-fns'

const Divider = styled.div`
  width: 0.0625rem;
  border-left: 1px solid ${theme.gray16};
  margin: 0 0.375rem;
`

const CustomClickable = styled(Clickable)`
  width: ${props => (props.isMobile ? '5.8375rem' : '2.25rem')};
  height: ${props => (props.isMobile ? 'auto' : '2.25rem')};
  border-radius: ${props =>
    props.isMobile ? '1.25rem' : '1.125rem'};
  background-color: ${theme.WHITE};
  margin: ${props =>
    props.isMobile ? '0 0 1.5rem 2.8125rem' : '0 1rem;'};
  ${props => props.isMobile && 'padding: 0.375rem 0.75rem;'}
  filter: drop-shadow(0px 0.25rem 0.5625rem rgba(0, 0, 0, 0.12));
  display: flex;
  align-items: center;
  justify-content: center;
`

function ActivityItem({
  t,
  type,
  details,
  verificationStatus,
  setModal,
  setMessageModal,
  isMobile
}) {
  const [isManualRequest, setIsManualRequst] = useState(false)

  const types = useMemo(() => {
    return {
      undefined: {
        color: theme.gray17,
        text: 'Undetermined',
        status: 'Unknown',
        type: 'minus',
        icon: 'rejected-request'
      },
      pending_request: {
        color: theme.BLUE_3,
        text: 'Request: Cash Out',
        status: 'Pending',
        type: 'minus',
        icon: 'pending-request'
      },
      manual_pending_request: {
        color: theme.YELLOW,
        text: 'Request: Manual Cash Out',
        status: 'Pending',
        type: 'minus',
        icon: 'manual-request'
      },
      rejected_request: {
        color: theme.gray17,
        text: 'Request: Cash Out',
        status: 'Rejected',
        type: 'minus',
        icon: 'rejected-request'
      },
      successful_request: {
        color: theme.BLUE_3,
        text: 'Request: Cash Out',
        status: 'Success',
        type: 'minus',
        icon: 'pending-request'
      },
      outcome: {
        color: theme.RED,
        text: 'Out: Cash Out',
        status: 'Success',
        type: 'minus',
        icon: 'outcome'
      },
      outcome_fee: {
        color: theme.RED,
        text: 'Out: Application Fee',
        status: 'Success',
        type: 'minus',
        icon: 'outcome'
      },
      income_trip: {
        color: theme.TRALLY_DARK_TEAL,
        text: 'In: ',
        status: 'Success',
        type: 'plus',
        icon: 'income'
      },
      income_tip: {
        color: theme.TRALLY_DARK_TEAL,
        text: 'Tip In: ',
        status: 'Success',
        type: 'plus',
        icon: 'income'
      }
    }
  }, [])

  const selectedType = useMemo(() => {
    if (
      type === 'pending_request' &&
      details &&
      details.state &&
      details.state === 'manual'
    ) {
      setIsManualRequst(true)
      return types['manual_pending_request']
    } else {
      setIsManualRequst(false)
    }
    return types[type] ? types[type] : types['undefined']
  }, [type, types, details, setIsManualRequst])

  const approveRequest = () => {
    if (
      (verificationStatus === 'not_verified' ||
        verificationStatus === 'rejected') &&
      details &&
      !details.isApproved
    ) {
      //show first time approval
      setModal({
        visible: true,
        type: 'first_time_approve',
        selectedItem: details
      })
    }
    if (
      verificationStatus === 'verified' &&
      details &&
      !details.isApproved
    ) {
      //show after first time approval
      setModal({
        visible: true,
        type: 'after_first_time_approve',
        selectedItem: details
      })
    } else if (
      verificationStatus === 'verified' &&
      details &&
      details.isApproved
    ) {
      //show after first time confirmation
      setModal({
        visible: true,
        type: 'confirm',
        selectedItem: details
      })
    }
  }

  return (
    <Col>
      <Row
        ht='2.0625rem'
        marg={
          type === 'rejected_request' &&
          details &&
          details.rejection_message &&
          isMobile
            ? '0 0 1rem 0'
            : '0 0 1.5rem 0'
        }
        center
      >
        <Icon type={selectedType?.icon} size='1.8125rem' />
        <Col marg='0 0 0 1rem'>
          <Text
            QS
            weight='700'
            size='0.875rem'
            lineHeight='1.05rem'
            color={theme.NEUTRAL_DARK}
            marg='0 0 0.125rem 0'
          >
            {selectedType?.text}{' '}
            {type && type.includes('income_')
              ? details && details.tripName
                ? details.tripName
                : 'Unknown Tour'
              : ''}
          </Text>
          <Row>
            <Text
              QS
              weight='400'
              size='0.625rem'
              lineHeight='0.875rem'
              color={theme.gray15}
            >
              {format(new Date(details.createdAt), 'dd MMMM, yyyy')}
            </Text>
            <Divider />
            <Text
              QS
              weight='400'
              size='0.625rem'
              lineHeight='0.875rem'
              color={theme.gray15}
            >
              {format(new Date(details.createdAt), 'hh:mm aaa')}
            </Text>
          </Row>
        </Col>
        {type === 'rejected_request' &&
          !isMobile &&
          details &&
          details.rejection_message && (
            <CustomClickable
              onClick={() => {
                setMessageModal({
                  visible: true,
                  message: details.rejection_message
                })
              }}
            >
              <Icon type='message-filled' size='0.9rem' />
            </CustomClickable>
          )}
        <Text
          QS
          weight='700'
          size='1rem'
          lineHeight='1.4rem'
          color={selectedType.color}
          marg='0 2rem 0 0'
        >
          {selectedType.type === 'plus' ? '+' : '-'} $
          {details.amount / 100}
        </Text>

        {isManualRequest ? (
          <Button
            noShadow
            ht='2rem'
            wid='5.3125rem'
            bordered={details && !details.isApproved}
            borderColor={theme.BLACK_TRALLY}
            bg={details && details.isApproved && theme.BLUE}
            color={details && details.isApproved && theme.WHITE}
            text={
              details && details.isApproved ? 'Confirm' : 'Approve'
            }
            onClick={() => approveRequest()}
          />
        ) : (
          <Text
            weight='700'
            size='0.75rem'
            lineHeight='1.0537rem'
            spacing='0.0625rem'
            color={selectedType.color}
          >
            • {details.cancelled ? 'Refunded' : selectedType.status}
          </Text>
        )}
      </Row>
      {type === 'rejected_request' &&
        isMobile &&
        details &&
        details.rejection_message && (
          <CustomClickable
            onClick={() => {
              setMessageModal({
                visible: true,
                message: details.rejection_message
              })
            }}
            isMobile={isMobile}
          >
            <Icon type='message-filled' size='14.4px' />
            <Text
              QS
              weight='700'
              size='0.75rem'
              lineHeight='0.875rem'
              marg='0 0 0 0.5rem'
            >
              Feedback
            </Text>
          </CustomClickable>
        )}
    </Col>
  )
}

export default ActivityItem
