import React, { useState, useEffect } from 'react'
import {
  Button,
  Col,
  Row,
  Text,
  ResponsiveModal,
  Spinner
} from 'components'
import { theme } from 'lib'
import Category from './Category'
import DraggableList from 'react-draggable-list'
import {
  getAllCategories,
  deleteCategory,
  updateCategory,
  editCategory
} from 'actions/categories'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const AllCategories = () => {
  const { categoriesList, isFetching } = useSelector(
    ({ categories }) => categories
  )
  const [isVisible, setIsVisible] = useState(false)
  const [category, setCategory] = useState(undefined)
  const [isDeleted, setIsDeleted] = useState(false)
  const [isTripVisible, setIsTripVisible] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    async function getCategoriesList() {
      dispatch(getAllCategories())
    }

    getCategoriesList()
  }, [isDeleted, dispatch])

  const _onListChange = newList => {
    dispatch({
      type: 'SET_CATEGORIES',
      payload: newList
    })
    newList.forEach((element, index) => {
      handleSortCategory(element, index)
    })
  }

  const handleSortCategory = async (category, index) => {
    dispatch(
      editCategory({
        category: {
          ...category,
          position: index
        },
        action: res => {}
      })
    )
  }

  const onDeleteClicked = category => {
    setCategory(category)
    setIsVisible(true)
  }

  const onDeleteTripClicked = (category, id) => {
    const tempTrips = category.trips.filter(trip => trip._id !== id)
    setCategory({ ...category, trips: tempTrips })

    setIsTripVisible(true)
  }

  const handleDeleteCategory = async () => {
    dispatch({
      type: 'LOADING_BUTTON',
      payload: 'deleteCategory'
    })
    dispatch(
      deleteCategory({
        id: category?._id,
        action: res => {
          if (res) {
            setIsVisible(false)
            setCategory(undefined)
            setIsDeleted(!isDeleted)
          }
        }
      })
    )
  }

  const handleUpdateCategory = async () => {
    dispatch({
      type: 'LOADING_BUTTON',
      payload: 'updateCategory'
    })
    dispatch(
      updateCategory({
        category,
        action: res => {
          if (res) {
            setIsTripVisible(false)
            setCategory(undefined)
            setIsDeleted(!isDeleted)
          }
        }
      })
    )
  }

  return (
    <React.Fragment>
      <div
        style={{
          margin: '0 2rem'
        }}
      >
        <DraggableList
          itemKey='_id'
          template={Category}
          list={categoriesList}
          onMoveEnd={newList => _onListChange(newList)}
          commonProps={{
            isFetching,
            onDeleteClicked,
            onDeleteTripClicked,
            categoriesList,
            dispatch,
            history
          }}
        />
        {isFetching && (
          <Col center marg='100px'>
            <Spinner />
          </Col>
        )}
        {!isFetching &&
          categoriesList &&
          categoriesList.length === 0 && (
            <Col center marg='100px'>
              <Text QS size='20px' lineHeight='19px' weight='400'>
                No Data Available
              </Text>
            </Col>
          )}
        <ResponsiveModal
          visible={isVisible}
          toggleVisible={() => {}}
          desktopModalWid='32rem'
          desktopModalHt='auto'
          desktopModalBorderRadius='2rem'
          isMobile={false}
        >
          <Col pad='3rem 0'>
            <Col noFlex centerAll>
              <Text
                QS
                weight='600'
                size='1.12rem'
                color={theme.BLACK_TRALLY}
                center
                pad='0 0 10px'
                style={{
                  borderBottom: `2px solid ${theme.gray22}`
                }}
              >
                Are you sure you want to delete the category?
              </Text>
              <Text
                QS
                weight='400'
                size='1.125'
                color={theme.BLACK_TRALLY}
                center
                marg='1.5rem 0'
              >
                Once deleted, it will have to be added again.
              </Text>

              <Row centerAll>
                <Button
                  noShadow
                  ht='2.8rem'
                  wid='9rem'
                  bgColor={'transparent'}
                  color={theme.BLACK_TRALLY}
                  bordered
                  borderColor={theme.BLACK_TRALLY}
                  marg='0 1.5rem 0 0'
                  text='No'
                  onClick={() => {
                    setIsVisible(false)
                  }}
                />
                <Button
                  noShadow
                  ht='2.8rem'
                  wid='9rem'
                  bgColor={theme.PURE_RED}
                  color={theme.WHITE}
                  text='Yes'
                  onClick={handleDeleteCategory}
                  action='deleteCategory'
                />
              </Row>
            </Col>
          </Col>
        </ResponsiveModal>
        <ResponsiveModal
          visible={isTripVisible}
          desktopModalWid='32rem'
          desktopModalHt='auto'
          desktopModalBorderRadius='2rem'
          isMobile={false}
        >
          <Col pad='3rem 0'>
            <Col noFlex centerAll>
              <Text
                QS
                weight='600'
                size='1.12rem'
                color={theme.BLACK_TRALLY}
                center
                pad='0 0 10px'
                style={{
                  borderBottom: `2px solid ${theme.gray22}`
                }}
              >
                Are you sure you want to delete the Tour?
              </Text>
              <Text
                QS
                weight='400'
                size='1.125'
                color={theme.BLACK_TRALLY}
                center
                marg='1.5rem 0'
              >
                Once deleted, it will have to be added again.
              </Text>

              <Row centerAll>
                <Button
                  noShadow
                  ht='2.8rem'
                  wid='9rem'
                  bgColor={'transparent'}
                  color={theme.BLACK_TRALLY}
                  bordered
                  borderColor={theme.BLACK_TRALLY}
                  marg='0 1.5rem 0 0'
                  text='No'
                  onClick={() => {
                    setIsTripVisible(false)
                  }}
                />
                <Button
                  noShadow
                  ht='2.8rem'
                  wid='9rem'
                  bgColor={theme.PURE_RED}
                  color={theme.WHITE}
                  text='Yes'
                  onClick={handleUpdateCategory}
                  action='updateCategory'
                />
              </Row>
            </Col>
          </Col>
        </ResponsiveModal>
      </div>
    </React.Fragment>
  )
}

export default AllCategories
