import React from 'react'
import { Row, Col, Text, Input } from 'components'
import Table from '../watchedTable'

const GuestTripsWatched = props => {
  let {
    fetchingData,
    tripsWatched,
    search,
    onSearchChange,
    isMobile
  } = props

  return (
    <Col noFlex pad={isMobile ? '2.5rem 1.25rem 0' : '0'}>
      <Row style={{ alignItems: 'flex-end', marginBottom: '0.2rem' }}>
        <Text QS size='20px' lineHeight='140%' weight='bold'>
          Search Watched Trips
        </Text>
      </Row>
      <Row>
        <Input
          value={search}
          placeholder={'Enter trip or host name to search'}
          wid={window.innerWidth > 998 ? '80%' : '100%'}
          borderRadius='30px'
          rounded
          onChange={onSearchChange}
        />
      </Row>
      <Row marg='1rem 0rem'>
        <Table
          caller='desktop'
          data={tripsWatched}
          fetchingData={fetchingData}
        />
      </Row>
    </Col>
  )
}

export default GuestTripsWatched
