import { TableCell, withStyles } from '@material-ui/core'
import { theme } from 'lib'

const StyledTableCell = withStyles(() => ({
  head: {
    fontSize: 14,
    lineHeight: '12px',
    fontWeight: 'bold',
    backgroundColor: theme.Gray7,
    color: theme.BLACK_TRALLY,
    textAlign: 'center',
    width: props =>
      props.width
        ? props.width
        : props.caller === 'mobile'
        ? '20vw'
        : ''
  },
  body: {
    fontSize: 12,
    textAlign: 'center'
  }
}))(TableCell)

export default StyledTableCell
