import React, { useMemo } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles
} from '@material-ui/core'
import { Button, Col, Text, Spinner } from 'components'
import PaginationView from './paginationView'
import { format } from 'date-fns'
import { env, theme } from 'lib'

const StyledTableCell = withStyles(theme => ({
  head: {
    fontSize: 14,
    lineHeight: '12px',
    fontWeight: 'bold',
    backgroundColor: '#F4F3F3',
    color: '#22191A',
    width: props =>
      props.width
        ? props.width
        : props.caller === 'mobile'
        ? '20vw'
        : ''
  },
  body: {
    fontSize: 12
  }
}))(TableCell)

const CustomTable = ({
  caller,
  data,
  handler,
  fetchingData,
  isCompleted
}) => {
  const tripUrl = useMemo(
    () =>
      (env.API_URL.includes('localhost') ||
      env.API_URL.includes('192.168')
        ? 'http://localhost:3000'
        : `https://${
            env.API_URL.includes('stagingapi.trally')
              ? 'staging.trally.com'
              : 'app.trally.com'
          }`) + `/explore`,
    []
  )

  return (
    <React.Fragment>
      <TableContainer component={Paper} style={{}}>
        <Table
          aria-label='customized table'
          style={caller === 'desktop' ? {} : { display: 'contents' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell caller={caller}>ID</StyledTableCell>
              <StyledTableCell caller={caller}>
                Trip Name
              </StyledTableCell>
              <StyledTableCell>Start Time</StyledTableCell>
              <StyledTableCell>End Time</StyledTableCell>
              {isCompleted && (
                <React.Fragment>
                  <StyledTableCell>Actual Start Time</StyledTableCell>
                  <StyledTableCell>Actual End Time</StyledTableCell>
                </React.Fragment>
              )}
              <StyledTableCell caller={caller}>
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {fetchingData || !data ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={12}>
                  <Col>
                    <Spinner />
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : data && data.totalDocs <= 0 ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={9}>
                  <Col centerAll>
                    <Text
                      weight='700'
                      size={caller === 'desktop' ? '16px' : '14px'}
                      lineHeight='40px'
                      QS
                    >
                      No tours found
                    </Text>
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {data && data.docs ? (
                <React.Fragment>
                  {data.docs.map((trip, index) => {
                    return (
                      <TableRow key={index}>
                        <StyledTableCell
                          component='th'
                          scope='row'
                          caller={caller}
                        >
                          {(data?.page - 1) * 10 + (index + 1)}
                        </StyledTableCell>

                        <StyledTableCell caller={caller}>
                          {trip.name}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {format(
                            new Date(trip.dates.start),
                            'p OOO, PPP'
                          )}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {format(
                            new Date(trip.dates.end),
                            'p OOO, PPP'
                          )}
                        </StyledTableCell>

                        {isCompleted && (
                          <React.Fragment>
                            <StyledTableCell caller={caller}>
                              {trip.dates.startedAt
                                ? format(
                                    new Date(trip.dates.startedAt),
                                    'p OOO, PPP'
                                  )
                                : '-'}
                            </StyledTableCell>
                            <StyledTableCell caller={caller}>
                              {format(
                                new Date(trip.dates.endedAt),
                                'p OOO, PPP'
                              )}
                            </StyledTableCell>
                          </React.Fragment>
                        )}
                        <StyledTableCell caller={caller}>
                          <a
                            href={`${tripUrl}/${trip._id}`}
                            rel='noreferrer'
                            target='_blank'
                            style={{
                              userSelect: 'none',
                              msTouchSelect: 'none',
                              msUserSelect: 'none',
                              MozUserSelect: 'none',
                              WebkitUserSelect: 'none'
                            }}
                          >
                            <Button black>
                              <Text
                                color={theme.WHITE}
                                QS
                                size='0.875rem'
                              >
                                View
                              </Text>
                            </Button>
                          </a>
                        </StyledTableCell>
                      </TableRow>
                    )
                  })}
                </React.Fragment>
              ) : null}
            </TableBody>
          )}
        </Table>
        <PaginationView
          total={data?.totalPages}
          current={data?.page}
          hasNext={data?.hasNextPage}
          hasPrev={data?.hasPrevPage}
          handler={handler}
        />
      </TableContainer>
    </React.Fragment>
  )
}

export default CustomTable
