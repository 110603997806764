import React, { useEffect, useState } from 'react'
import { countries, stripeCountries } from 'lib'
import { Col, Spinner } from 'components'
import StripeConnect from './stripeConnect'
import HostDetails from './hostDetails'

function AccountDetails({ isMobile, user }) {
  const [stripeSupported, setStripeSupported] = useState('loading')

  useEffect(() => {
    if (user && user.country) {
      const selectedCountry = countries.find(
        c => c.name === user.country
      )
      const isStripSupported = stripeCountries.some(
        sc => sc.name === selectedCountry.name
      )
      setStripeSupported(isStripSupported ? 'yes' : 'no')
    }
  }, [user])

  return (
    <Col marg={isMobile ? '1.25rem 1.5rem' : '0 0 0 4.6875rem'}>
      {stripeSupported === 'yes' ? (
        <StripeConnect isMobile={isMobile} user={user} />
      ) : stripeSupported === 'no' ? (
        <HostDetails user={user} />
      ) : (
        <Col centerAll>
          <Spinner />
        </Col>
      )}
    </Col>
  )
}

export default AccountDetails
