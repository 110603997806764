import React from 'react'
import { Col, Icon, Clickable } from 'components'
import styled from 'styled-components'
import { theme } from 'lib'

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: ${props =>
    props.containerHt ? props.containerHt : '100vh'};
  position: fixed;
  top: 0;
  left: 0;
  ${props => props.left && `left: ${props.left}`};
  ${props => props.top && `top: ${props.top}`};
  ${props => props.right && `right: ${props.right}`};
  ${props => props.bottom && `bottom: ${props.bottom}`};
  z-index: 9999;
`

const Container = styled.div`
  width: 100vw;
  height: ${props =>
    props.fullHt ? '100vh' : props.ht ? props.ht : 'auto'};
  background-color: ${props => (props.bg ? props.bg : theme.WHITE)};
  position: absolute;
  bottom: 0;
  ${props => props.scroll && `overflow-y: scroll;`}
`

const CloseButton = styled(Icon)`
  position: absolute;
  right: 26px;
  top: 19px;
`

function ModalSheet({
  toggleModal,
  visible,
  fullHt,
  noCloseButton,
  children,
  overflowModal,
  bg,
  ht,
  scroll,
  left,
  top,
  right,
  bottom,
  containerHt
}) {
  if (!visible) return null
  return (
    <Overlay
      left={left}
      top={top}
      right={right}
      bottom={bottom}
      containerHt={containerHt}
    >
      <Container fullHt={fullHt} ht={ht} bg={bg} scroll>
        {noCloseButton ? (
          <React.Fragment>{children}</React.Fragment>
        ) : (
          <Col
            relative
            pad='22px 0'
            style={
              overflowModal
                ? {
                    overflowY: 'scroll',
                    height: '100vh'
                  }
                : {}
            }
          >
            <Clickable onClick={toggleModal}>
              <CloseButton type='close' size='18px' />
            </Clickable>
            {children}
          </Col>
        )}
      </Container>
    </Overlay>
  )
}

export default ModalSheet
