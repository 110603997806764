import React, { useEffect, useState, useMemo } from 'react'
import DesktopView from './desktopView'
import MobileView from './mobileView'
import ResetTrialModal from './components/resetTrialModal'
import { env, Api, countries, stripeCountries } from 'lib'
import { useParams, useHistory } from 'react-router-dom'
import { isBefore } from 'date-fns'
import ApproveModal from './components/approveModal'
import MessageModal from './components/messageModal'
import { useDispatch, useSelector } from 'react-redux'

const Index = () => {
  let { menu } = useParams()

  let { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [host, setHost] = useState(null)
  const [approveFlag, setApproveFlag] = useState(false)
  const [rejectFlag, setRejectFlag] = useState(false)
  const [deactivateFlag, setDeactivateFlag] = useState(false)
  const [activateFlag, setActivateFlag] = useState(false)
  const [successFlag, setSuccessFlag] = useState('')
  const [reloadData, setReloadData] = useState(false)
  const [resetModal, setResetModal] = useState(false)
  const [hostId, setHostId] = useState(false)
  const [reloadUser, setReloadUser] = useState(false)
  const [selectedMenu, setSelectedMenu] = useState('host')
  const [stripeSupported, setStripeSupported] = useState('loading')
  const [modal, setModal] = useState({
    visible: false,
    type: 'first_time_approve',
    selectedItem: null
  })
  const [messageModal, setMessageModal] = useState({
    visible: false,
    message: ''
  })
  const { screenDimensions } = useSelector(state => state.appState)

  const toggleModal = () => {
    setModal({
      visible: false,
      type: 'first_time_approve',
      selectedItem: null
    })
  }
  const toggleMessageModal = () => {
    setMessageModal({
      visible: false,
      message: ''
    })
  }

  const toggleReset = () => {
    setResetModal(!resetModal)
  }

  useEffect(() => {
    if (menu && menu.length > 0) {
      setSelectedMenu(menu)
    } else {
      setSelectedMenu('host')
    }
  }, [menu])

  useEffect(() => {
    setLoading(true)
    async function fetchData() {
      const res = await Api.get('/users/get/' + id + '/all')
      if (res) {
        setHost(res)
        setHostId(res?._id)
      } else {
        console.log('error in fetching host')
      }
      setLoading(false)
    }
    fetchData()
  }, [
    id,
    approveFlag,
    rejectFlag,
    deactivateFlag,
    activateFlag,
    setHostId,
    reloadUser,
    reloadData
  ])

  useEffect(() => {
    setLoading(true)
    async function fetchData() {
      const res = await Api.get('/users/get/' + id + '/all')
      if (res) {
        setHost(res)
        setHostId(res?._id)
      } else {
        console.log('error in fetching host')
      }
      setLoading(false)
      setReloadData(false)
    }

    if (reloadData) {
      fetchData()
    }
  }, [reloadData, id, setHostId])

  const onSuccess = async name => {
    let status
    switch (name) {
      case 'approve':
        status = 'approved'
        break
      case 'activate':
        status = 'activated'
        break
      case 'reject':
        status = 'rejected'
        break
      case 'deactivate':
        status = 'deactivated'
        break
      default:
        break
    }
    setSuccessFlag(status)
    setTimeout(() => {
      setSuccessFlag('')
    }, 3000)
  }

  const pendingHasTrips = useMemo(() => {
    let latestTrip = null
    if (
      host &&
      host.status === 'first_trial' &&
      host.trips &&
      host.trips.length > 0
    ) {
      latestTrip = host.trips.find(
        t => t.trialStatus && t.trialStatus === 'first_trial'
      )
    } else if (
      host &&
      host.status === 'second_trial' &&
      host.trips &&
      host.trips.length > 0
    ) {
      latestTrip = host.trips.find(
        t => t.trialStatus && t.trialStatus === 'second_trial'
      )
    }

    let checkIfTripSuccess = false
    let now = new Date()
    if (latestTrip && !latestTrip.published) {
      checkIfTripSuccess = 'not-published'
    } else if (
      latestTrip &&
      latestTrip.dates &&
      latestTrip.dates.length > 0
    ) {
      checkIfTripSuccess =
        latestTrip.dates[0] &&
        isBefore(new Date(latestTrip.dates[0].start), now) &&
        isBefore(new Date(latestTrip.dates[0].end), now) &&
        !latestTrip.dates[0].endedAt
          ? 'missed'
          : latestTrip.dates[0] && latestTrip.dates[0].endedAt
          ? 'completed'
          : 'not-started'
    }

    return {
      trip: latestTrip,
      tripSuccess: checkIfTripSuccess
    }
  }, [host])

  const goToTrialTrip = id => {
    window.open(
      `
    ${
      env.API_URL &&
      (env.API_URL.includes('localhost') ||
        env.API_URL.includes('192.168'))
        ? 'http://localhost:3000'
        : `https://${
            env.API_URL.includes('stagingapi.trally')
              ? 'staging.trally.com'
              : 'app.trally.com'
          }`
    }/explore/${id}`,
      '_blank'
    )
  }

  const cancelConfirm = async () => {
    dispatch({
      type: 'LOADING_BUTTON',
      payload: 'cancelConfirm'
    })
    const res = await Api.patch(
      `/admin/updatePayoutStatus/${host._id}`,
      {
        status: 'cancel_confirm',
        paymentId: modal.selectedItem._id
      }
    )

    if (res && res.success) {
      setReloadData(true)
    }
    toggleModal()
    dispatch({
      type: 'LOADING_BUTTON',
      payload: null
    })
  }
  const rejectApproval = async ({ type, message }) => {
    dispatch({
      type: 'LOADING_BUTTON',
      payload: 'rejectPayout'
    })
    const res = await Api.patch(
      `/admin/updatePayoutStatus/${host._id}`,
      {
        status: type,
        paymentId: modal.selectedItem._id,
        amount: modal.selectedItem.amount,
        rejection_message: message
      }
    )

    if (res && res.success) {
      setReloadData(true)
    }
    toggleModal()
    dispatch({
      type: 'LOADING_BUTTON',
      payload: null
    })
  }

  const acceptApproval = async type => {
    dispatch({
      type: 'LOADING_BUTTON',
      payload:
        type === 'first_time'
          ? 'firstTimeApproval'
          : 'afterFirstTimeApproval'
    })
    const res = await Api.patch(
      `/admin/updatePayoutStatus/${host._id}`,
      {
        status: type,
        paymentId: modal.selectedItem._id
      }
    )

    if (res && res.success) {
      setReloadData(true)
    }
    toggleModal()
    dispatch({
      type: 'LOADING_BUTTON',
      payload: null
    })
  }

  const confirmPayout = async () => {
    dispatch({
      type: 'LOADING_BUTTON',
      payload: 'confirmPayout'
    })
    const res = await Api.patch(
      `/admin/updatePayoutStatus/${host._id}`,
      {
        status: 'confirm',
        paymentId: modal.selectedItem._id,
        amount: modal.selectedItem.amount
      }
    )

    if (res && res.success) {
      setReloadData(true)
    }
    toggleModal()
    dispatch({
      type: 'LOADING_BUTTON',
      payload: null
    })
  }

  useEffect(() => {
    if (host && host.country) {
      const selectedCountry = countries.find(
        c => c.name === host.country
      )
      const isStripSupported = stripeCountries.some(
        sc => sc.name === selectedCountry.name
      )
      setStripeSupported(isStripSupported ? 'yes' : 'no')
    }
  }, [host])

  return (
    <React.Fragment>
      {screenDimensions.width > env.SCREEN_WIDTH ? (
        <React.Fragment>
          <div style={{ marginTop: '80px' }}>
            <DesktopView
              toggleReset={toggleReset}
              selectedMenu={selectedMenu}
              setSelectedMenu={setSelectedMenu}
              windowWidth={screenDimensions.width}
              id={id}
              history={history}
              host={host}
              approveFlag={approveFlag}
              setApproveFlag={setApproveFlag}
              onSuccess={onSuccess}
              rejectFlag={rejectFlag}
              setRejectFlag={setRejectFlag}
              deactivateFlag={deactivateFlag}
              setDeactivateFlag={setDeactivateFlag}
              activateFlag={activateFlag}
              setActivateFlag={setActivateFlag}
              pendingHasTrips={pendingHasTrips}
              goToTrialTrip={goToTrialTrip}
              successFlag={successFlag}
              loading={loading}
              setModal={setModal}
              setMessageModal={setMessageModal}
              stripeSupported={stripeSupported}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <MobileView
            id={id}
            history={history}
            host={host}
            selectedMenu={selectedMenu}
            toggleReset={toggleReset}
            pendingHasTrips={pendingHasTrips}
            goToTrialTrip={goToTrialTrip}
            successFlag={successFlag}
            approveFlag={approveFlag}
            setApproveFlag={setApproveFlag}
            onSuccess={onSuccess}
            rejectFlag={rejectFlag}
            setRejectFlag={setRejectFlag}
            deactivateFlag={deactivateFlag}
            setDeactivateFlag={setDeactivateFlag}
            loading={loading}
            activateFlag={activateFlag}
            setActivateFlag={setActivateFlag}
            setModal={setModal}
            setMessageModal={setMessageModal}
            stripeSupported={stripeSupported}
          />
        </React.Fragment>
      )}
      <ResetTrialModal
        showReset={resetModal}
        toggleReset={toggleReset}
        setReloadUser={setReloadUser}
        hostId={hostId}
        isMobile={screenDimensions.width <= env.SCREEN_WIDTH}
      />
      <ApproveModal
        visible={modal.visible}
        toggleVisible={toggleModal}
        isMobile={screenDimensions.width <= env.SCREEN_WIDTH}
        type={modal.type}
        rejectApproval={rejectApproval}
        acceptApproval={acceptApproval}
        confirmPayout={confirmPayout}
        cancelConfirm={cancelConfirm}
      />
      <MessageModal
        visible={messageModal.visible}
        toggleVisible={toggleMessageModal}
        isMobile={screenDimensions.width <= env.SCREEN_WIDTH}
        message={messageModal.message}
      />
    </React.Fragment>
  )
}

export default Index
