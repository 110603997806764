import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles
} from '@material-ui/core'
import { Col, Text, Spinner } from 'components'
import PaginationView from './paginationView'
import NoFeedbackImage from 'assets/host.png'

const StyledTableCell = withStyles(theme => ({
  head: {
    fontSize: 14,
    lineHeight: '12px',
    fontWeight: 'bold',
    backgroundColor: '#F4F3F3',
    color: '#22191A',
    width: props =>
      props.width
        ? props.width
        : props.caller === 'mobile'
        ? '20vw'
        : ''
  },
  body: {
    fontSize: 12
  }
}))(TableCell)

const CustomTable = ({ caller, data, handler, fetchingData }) => {
  return (
    <React.Fragment>
      <TableContainer component={Paper} style={{}}>
        <Table
          aria-label='customized table'
          style={caller === 'desktop' ? {} : { display: 'contents' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell caller={caller}>ID</StyledTableCell>

              <React.Fragment>
                <React.Fragment>
                  <StyledTableCell caller={caller}>
                    Tour Name
                  </StyledTableCell>
                  <StyledTableCell caller={caller}>
                    Tour ID
                  </StyledTableCell>
                  <StyledTableCell>Host</StyledTableCell>
                </React.Fragment>
              </React.Fragment>
              <StyledTableCell caller={caller}>
                Scheduled Date Time
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Actual Date Time
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Status
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Total Guests
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {fetchingData ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={12}>
                  <Col>
                    <Spinner />
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : data && data.totalDocs <= 0 ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={9}>
                  <Col centerAll>
                    <img
                      src={NoFeedbackImage}
                      width={caller === 'desktop' ? 130 : 100}
                      alt='no feedback'
                      style={{
                        marginTop: 50,
                        marginBottom: 10
                      }}
                    />
                    <Text
                      weight='700'
                      size={caller === 'desktop' ? '16px' : '14px'}
                      lineHeight='40px'
                      QS
                    >
                      No tour found
                    </Text>
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {data && data.docs ? (
                <React.Fragment>
                  {data.docs.map((trip, index) => {
                    return (
                      <TableRow key={index}>
                        <StyledTableCell
                          component='th'
                          scope='row'
                          caller={caller}
                        >
                          {(data?.page - 1) * 10 + (index + 1)}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {trip.tourName}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {trip.tourId}
                        </StyledTableCell>
                        <StyledTableCell>{trip.host}</StyledTableCell>
                        <StyledTableCell>
                          {trip.scheduledDateTime}
                        </StyledTableCell>
                        <StyledTableCell>
                          {trip.actualDateTime}
                        </StyledTableCell>
                        <StyledTableCell>
                          {trip.status}
                        </StyledTableCell>
                        <StyledTableCell>
                          {trip.totalGuests}
                        </StyledTableCell>
                      </TableRow>
                    )
                  })}
                </React.Fragment>
              ) : null}
            </TableBody>
          )}
        </Table>
        <PaginationView
          total={data?.totalPages}
          current={data?.page}
          hasNext={data?.hasNextPage}
          hasPrev={data?.hasPrevPage}
          handler={handler}
        />
      </TableContainer>
    </React.Fragment>
  )
}

export default CustomTable
