import React from 'react'
import styled from 'styled-components'
import { Text, Col } from '.'
import { theme } from 'lib'

const Container = styled.div`
  display: flex;
  align-items: ${props => (props.leftTitle ? 'center' : 'unset')};
  flex-direction: ${props => (props.leftTitle ? 'row' : 'column')};
`

const InputContainer = styled.div`
  height: 54px;
  border: 1px solid ${theme.LIGHT_GRAY};
  border-radius: 8px;
  width: 100%;
`

const StyledInput = styled.input`
  color: ${theme.BLACK};
  background-color: ${theme.gray1};
  font-weight: bold;
  height: 52px;
  font-size: 16px;
  border-radius: 8px;
  width: 100%;
  padding: 0 12px;

  ::placeholder {
    color: ${theme.gray2};
  }
`

const WebInput = props => {
  // const { title, topTitle, ref, value, onChange, placeholder } = props
  const { title, ref, value, onChange, placeholder } = props

  return (
    <Container {...props}>
      {title && (
        <Col>
          <Text inputHeader size='14px'>
            {title.toUpperCase()}
          </Text>
        </Col>
      )}

      <InputContainer {...props}>
        <StyledInput
          placeholderTextColor={theme.gray2}
          ref={ref}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      </InputContainer>
    </Container>
  )
}

export default WebInput
