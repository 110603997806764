import styled from 'styled-components'

const CustomClickable = styled.div`
  cursor: pointer;
  ${props => props.marg && `margin: ${props.marg};`}

  &:active {
    opacity: 0.6;
  }
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

export default CustomClickable
