import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Text,
  Clickable,
  Pagination,
  Spinner
} from 'components'
import { theme } from 'lib'
import ActivityItem from './activityItem'
import styled from 'styled-components'

const Tab = styled(Clickable)`
  background-color: ${props =>
    props.selected ? theme.LIGHT_BLUE : 'transparent'};
  padding: 0.3125rem 0.75rem;
  border-radius: 1.875rem;
  margin: ${props =>
    props.windowWidth < 1206 || props.isMobile ? '1rem 0 0 0' : '0'};
  ${props => !props.isLast && 'margin-right: 1.25rem;'}
`

function Activity({
  windowWidth,
  isMobile,
  user,
  verificationStatus,
  setModal,
  setMessageModal
}) {
  const tabs = ['All', 'Requests']
  const [activeTab, setActiveTab] = useState('All')
  const [activePage, setActivePage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getData = (pageNo, totalItems, type) => {
      let tempData = user.paymentHistory.sort(function (a, b) {
        var dateA = new Date(a.createdAt).getTime()
        var dateB = new Date(b.createdAt).getTime()
        return dateA < dateB ? 1 : -1 // ? -1 : 1 for ascending/increasing order
      })

      if (type === 'Requests') {
        tempData = tempData.filter(
          ph => ph.type && ph.type.includes('_request')
        )
      }
      let totalPages = Math.ceil(tempData.length / totalItems)
      setTotalPages(totalPages <= 0 ? 1 : totalPages)
      setData(
        tempData.slice(
          totalItems * pageNo - totalItems,
          totalItems * pageNo
        )
      )
      setLoading(false)
    }

    let totalItems = 8
    if (
      user &&
      user.paymentHistory &&
      user.paymentHistory.length > 0
    ) {
      setLoading(true)
      getData(parseInt(activePage), totalItems, activeTab)
    }
  }, [user, activePage, activeTab])

  const handleTab = tab => {
    setActiveTab(tab)
  }

  const handlePagination = pageNo => {
    setActivePage(pageNo)
  }

  return (
    <Col noFlex wid='100%'>
      <Col marg={isMobile ? '0 2rem' : '0'}>
        <Row noFlex center wrap='wrap' wid='100%' between>
          <Text
            QS
            weight='700'
            size='1.5rem'
            lineHeight='1.8rem'
            marg='0 5.9375rem 0 0'
            wid={isMobile ? '100%' : 'auto'}
          >
            Activities
          </Text>
          <Row noFlex={!isMobile} wrap='wrap'>
            {tabs.map((tab, i) => (
              <Tab
                selected={tab === activeTab}
                onClick={() => handleTab(tab)}
                key={tab}
                isLast={i + 1 === tabs.length}
                isMobile={isMobile}
                windowWidth={windowWidth}
              >
                <Text
                  color={
                    tab === activeTab ? theme.BLUE : theme.gray17
                  }
                  QS
                  weight='700'
                  size='0.875rem'
                  lineHeight='1.375rem'
                >
                  {tab}
                </Text>
              </Tab>
            ))}
          </Row>
        </Row>
        <Col noFlex wid='100%' marg='2.25rem 0 0 0'>
          {data && data.length > 0 ? (
            data.map(item => (
              <ActivityItem
                key={item._id}
                type={item.type}
                details={item}
                verificationStatus={verificationStatus}
                setModal={setModal}
                setMessageModal={setMessageModal}
                isMobile={isMobile}
              />
            ))
          ) : loading ? (
            <Spinner />
          ) : (
            <Text
              center
              QS
              size='14px'
              lineHeight='21px'
              weight='700'
            >
              No Activities
            </Text>
          )}
          <Row centerAll noFlex marg='2.625rem 0'>
            <Pagination
              total={totalPages}
              handler={handlePagination}
              customCss='activityPaginationStyles.css'
              classProps={{
                containerClassName: 'activityContainer',
                activeClassName: 'activityActiveLink',
                pageClassName: 'activityListItem',
                disabledClassName: 'activityDisabledButton',
                nextClassName: 'activityNextButton',
                previousClassName: 'activityPrevButton'
              }}
            />
          </Row>
        </Col>
      </Col>
    </Col>
  )
}

export default Activity
