import { theme } from '../lib'

const ClickableInputStyles = props => {
  return `
    justify-content: center;
    align-items: flex-start;

    ${
      props.input &&
      `
      height: 56px;
      padding: 0 12px;
      border-radius: 8px;
      background-color: ${theme.gray1};
    `
    };

    ${
      props.option &&
      `
      height: 56px;
      padding: 0 16px;
      border-bottom-width: 1px;
      border-bottom-color: ${theme.gray4};
      border-top-width: 1px;
      border-top-color: ${theme.gray4};
      background-color: ${theme.WHITE};
    `
    };

    ${
      props.icon &&
      `
      height: 46px;
      border: 1px solid ${theme.gray4};
      border-radius: 8px;
      padding: 0 12px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    `
    };
  `
}

export default ClickableInputStyles
