import React, {
  useState,
  useEffect,
  useRef,
  useCallback
} from 'react'
import Slider from 'react-slick'
import { Row, Col, Text } from './'
import { theme } from 'lib'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Tab = styled(Row)`
  cursor: pointer;
  &:active {
    opacity: 0.6;
  }
`

const FixedRow = styled(Row)`
  position: fixed;
  z-index: 1;
  background-color: ${theme.WHITE};
  width: 100vw;
`

function SwipeableTab(props) {
  const sliderRef = useRef()
  const history = useHistory()
  const { tabItems, marg, pad, initialSelected } = props
  const [activeTab, setActiveTab] = useState(0)

  const changeSlide = useCallback(
    (i, moveSlide) => {
      setActiveTab(i)
      moveSlide && sliderRef?.current?.slickGoTo(i)
      tabItems[i].link && history.push(`${tabItems[i].link}`)
    },
    [history, tabItems]
  )

  useEffect(() => {
    if (initialSelected && initialSelected >= 0) {
      changeSlide(initialSelected, true)
    }
  }, [initialSelected, sliderRef, changeSlide])

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    swipe: true,
    arrows: false,
    ...props.sliderSettings
  }

  return (
    <Col marg={marg} pad={pad}>
      <Col relative minHt='4.125rem'>
        <FixedRow
          center
          between
          hasBorderBottom={`0.0625rem solid ${theme.GRAY_77}`}
        >
          {tabItems &&
            tabItems.length > 0 &&
            tabItems.map((ti, i) => (
              <Tab
                pad='1.0625rem 0'
                centerAll
                hasBorderBottom={
                  i === activeTab
                    ? `0.125rem solid ${theme.BLACK_TRALLY}`
                    : 'none'
                }
                marg={
                  i === activeTab ? '10px 0 -0.125rem' : '10px 0 0'
                }
                onClick={() => changeSlide(i, true)}
              >
                <Text
                  QS
                  weight='700'
                  size='0.9375rem'
                  lineHeight='1.25rem'
                  spacing='0.0187rem'
                  color={
                    i === activeTab
                      ? theme.BLACK_TRALLY
                      : theme.GRAY_20
                  }
                >
                  {ti.name}
                </Text>
              </Tab>
            ))}
        </FixedRow>
      </Col>
      <Slider
        {...settings}
        ref={sliderRef}
        beforeChange={(i, n) => changeSlide(n)}
      >
        {props.children}
      </Slider>
    </Col>
  )
}

export default SwipeableTab
