import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles
} from '@material-ui/core'
import { Button, Col, Text, Spinner } from 'components'
import PaginationView from './paginationView'
import { useHistory } from 'react-router-dom'
import NoFeedbackImage from 'assets/host.png'

const StyledTableCell = withStyles(theme => ({
  head: {
    fontSize: 14,
    lineHeight: '12px',
    fontWeight: 'bold',
    backgroundColor: '#F4F3F3',
    textAlign: 'center',
    color: '#22191A',
    width: props =>
      props.width
        ? props.width
        : props.caller === 'mobile'
        ? '20vw'
        : ''
  },
  body: {
    fontSize: 12,
    textAlign: 'center'
  }
}))(TableCell)

const CustomTable = ({ caller, data, handler, fetchingData }) => {
  const history = useHistory()
  return (
    <React.Fragment>
      <TableContainer component={Paper} style={{}}>
        <Table
          aria-label='customized table'
          style={caller === 'desktop' ? {} : { display: 'contents' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell caller={caller}>ID</StyledTableCell>

              <React.Fragment>
                {caller === 'desktop' ? (
                  <React.Fragment>
                    <StyledTableCell caller={caller}>
                      First Name
                    </StyledTableCell>
                    <StyledTableCell>Last Name</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                  </React.Fragment>
                ) : (
                  <StyledTableCell>Name</StyledTableCell>
                )}
              </React.Fragment>
              <StyledTableCell caller={caller}>
                Comments
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Rating
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {fetchingData ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={6}>
                  <Col>
                    <Spinner />
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : data && data.totalDocs <= 0 ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={9}>
                  <Col centerAll>
                    <img
                      src={NoFeedbackImage}
                      width={caller === 'desktop' ? 130 : 100}
                      alt='no feedback'
                      style={{
                        marginTop: 50,
                        marginBottom: 10
                      }}
                    />
                    <Text
                      weight='700'
                      size={caller === 'desktop' ? '16px' : '14px'}
                      lineHeight='40px'
                      QS
                    >
                      No feedback found
                    </Text>
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {data && data.docs ? (
                <React.Fragment>
                  {data.docs.map((row, index) => {
                    const host = row.trip && row.trip.host
                    return (
                      <TableRow key={index}>
                        <StyledTableCell
                          component='th'
                          scope='row'
                          caller={caller}
                        >
                          {(data?.page - 1) * 10 + (index + 1)}
                        </StyledTableCell>

                        <React.Fragment>
                          {caller === 'desktop' ? (
                            <React.Fragment>
                              <StyledTableCell caller={caller}>
                                {host
                                  ? host.name?.split(' ')[0]
                                  : '-'}
                              </StyledTableCell>
                              <StyledTableCell>
                                {host
                                  ? host.name?.split(' ')[1]
                                  : '-'}
                              </StyledTableCell>
                              <StyledTableCell>
                                {host ? host.email : '-'}
                              </StyledTableCell>
                            </React.Fragment>
                          ) : (
                            <StyledTableCell caller={caller}>
                              {host ? host.name : '-'}
                            </StyledTableCell>
                          )}
                        </React.Fragment>
                        <StyledTableCell
                          caller={caller}
                          style={{
                            textTransform: 'capitalize'
                          }}
                        >
                          {row?.text}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row?.rating}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          <Button
                            black
                            text='View'
                            onClick={() =>
                              host &&
                              host._id &&
                              history.push('/view/' + host._id)
                            }
                          />
                        </StyledTableCell>
                      </TableRow>
                    )
                  })}
                </React.Fragment>
              ) : null}
            </TableBody>
          )}
        </Table>
        <PaginationView
          total={data?.totalPages}
          current={data?.page}
          hasNext={data?.hasNextPage}
          hasPrev={data?.hasPrevPage}
          handler={handler}
        />
      </TableContainer>
    </React.Fragment>
  )
}

export default CustomTable
