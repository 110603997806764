import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles
} from '@material-ui/core'
import { Col, Text, Spinner, Link } from 'components'
import PaginationView from './paginationView'
import NoFeedbackImage from 'assets/host.png'

const StyledTableCell = withStyles(theme => ({
  head: {
    fontSize: 14,
    lineHeight: '12px',
    fontWeight: 'bold',
    backgroundColor: '#F4F3F3',
    textAlign: 'center',
    color: '#22191A',
    width: props =>
      props.width
        ? props.width
        : props.caller === 'mobile'
        ? '20vw'
        : ''
  },
  body: {
    fontSize: 12,
    textAlign: 'center'
  }
}))(TableCell)

const CustomTable = ({
  caller,
  data,
  handler,
  fetchingData,
  type
}) => {
  return (
    <React.Fragment>
      <TableContainer component={Paper} style={{}}>
        <Table
          aria-label='customized table'
          style={caller === 'desktop' ? {} : { display: 'contents' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell caller={caller}>ID</StyledTableCell>

              <StyledTableCell>Reporter's Username</StyledTableCell>
              <StyledTableCell>Reporter's Email</StyledTableCell>
              <StyledTableCell caller={caller}>
                {type === 'trip' ? 'Reported Tour' : 'Reported User'}
              </StyledTableCell>
              {type === 'trip' && (
                <StyledTableCell caller={caller}>
                  Reported Host's Username
                </StyledTableCell>
              )}
              <StyledTableCell caller={caller}>
                Comments
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {fetchingData ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={6}>
                  <Col>
                    <Spinner />
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : data && data.totalDocs <= 0 ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={9}>
                  <Col centerAll>
                    <img
                      src={NoFeedbackImage}
                      width={caller === 'desktop' ? 130 : 100}
                      alt='No reports'
                      style={{
                        marginTop: 50,
                        marginBottom: 10
                      }}
                    />
                    <Text
                      weight='700'
                      size={caller === 'desktop' ? '16px' : '14px'}
                      lineHeight='40px'
                      QS
                    >
                      No reports found
                    </Text>
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {data && data.docs ? (
                <React.Fragment>
                  {data.docs.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <StyledTableCell
                          component='th'
                          scope='row'
                          caller={caller}
                        >
                          {(data?.page - 1) * 10 + (index + 1)}
                        </StyledTableCell>

                        <StyledTableCell caller={caller}>
                          <Link
                            url={`/profile/${row?.reportedBy?._id}`}
                          >
                            {row.reportedBy
                              ? row.reportedBy.username
                              : '-'}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.reportedBy
                            ? row.reportedBy.email
                            : '-'}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          <Link
                            url={`${
                              type === 'trip'
                                ? '/explore/'
                                : '/profile/'
                            }${row?.reported?._id}`}
                          >
                            {row?.reported?.name}
                          </Link>
                        </StyledTableCell>
                        {type === 'trip' && (
                          <StyledTableCell caller={caller}>
                            <Link
                              url={`/profile/${row?.reported?.host?._id}`}
                            >
                              {row?.reported?.host?.username}
                            </Link>
                          </StyledTableCell>
                        )}
                        <StyledTableCell>{row?.text}</StyledTableCell>
                      </TableRow>
                    )
                  })}
                </React.Fragment>
              ) : null}
            </TableBody>
          )}
        </Table>
        <PaginationView
          total={data?.totalPages}
          current={data?.page}
          hasNext={data?.hasNextPage}
          hasPrev={data?.hasPrevPage}
          handler={handler}
        />
      </TableContainer>
    </React.Fragment>
  )
}

export default CustomTable
