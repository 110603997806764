import React from 'react'
import ReactPaginate from 'react-paginate'
import 'styles/paginationStyles.css'
import { Icon } from 'components'

const PaginationView = ({
  total,
  current,
  hasNext,
  hasPrev,
  handler
}) => {
  const onPageChange = page => {
    handler(page.selected + 1)
  }

  return (
    <div>
      <ReactPaginate
        previousLabel={<Icon type='back' size='15px' />}
        nextLabel={<Icon type='next' size='15px' />}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={total ? total : 1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        forcePage={current ? current - 1 : 0}
        onPageChange={selectedItem => onPageChange(selectedItem)}
        containerClassName={'container'}
        activeClassName={'activeLink'}
      />
    </div>
  )
}

export default PaginationView
