import React, { useMemo } from 'react'
import { Drawer } from '@material-ui/core'
import { Col, Text } from 'components'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from 'actions/user'
import { theme } from 'lib'
import styled from 'styled-components'

const MenuOption = styled.div`
  cursor: pointer;
  background-color: ${theme.WHITE};
  height: 3.5rem;
  ${props => !props.noTop && `border-top: 1px solid ${theme.gray4};`}
  border-bottom: 1px solid ${theme.gray4};
  padding: 0 0 0 1.0938rem;
  display: flex;
  align-items: center;
  ${props =>
    props.lastOption &&
    `
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
  `}

  &:active {
    opacity: 0.6;
  }
`

function MobileDrawer({ openSideBar, toggleSideBar }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  function handleLogout() {
    dispatch(logout({ action: history.push('/') }))
  }

  function moveToPage(page) {
    history.push(`${page}`)
  }

  const menuPages = useMemo(() => {
    return [
      {
        name: 'Hosts',
        link: '/home'
      },
      {
        name: 'Guests',
        link: '/guests'
      },
      {
        name: 'Payout',
        link: '/payout',
        props: {
          noTop: true
        }
      },
      {
        name: 'Feedback',
        link: '/feedback',
        props: {
          noTop: true
        }
      },
      {
        name: 'Tours',
        link: '/tours',
        props: {
          noTop: true
        }
      }
    ]
  }, [])

  return (
    <div>
      <Drawer
        anchor='left'
        open={openSideBar}
        onClose={toggleSideBar}
      >
        <Col noFlex marg='2.5rem 0'>
          {menuPages.map(mp => (
            <MenuOption
              onClick={() =>
                mp.link !== pathname && moveToPage(mp.link)
              }
              {...mp.props}
              key={mp.name}
            >
              <Text
                QS
                bold
                size='1rem'
                color={
                  mp.link === pathname
                    ? theme.BLUE
                    : theme.NEUTRAL_DARK
                }
              >
                {mp.name}
              </Text>
            </MenuOption>
          ))}
        </Col>
        <Col
          ht='3.5rem'
          wid='50vw'
          noFlex
          style={{ justifyContent: 'center' }}
        >
          <Text marg='0 0 0 1.4375rem' inputHeader>
            SUPPORT
          </Text>
        </Col>

        <MenuOption onClick={handleLogout}>
          <Text QS bold size='1rem'>
            Log out
          </Text>
        </MenuOption>
      </Drawer>
    </div>
  )
}

export default MobileDrawer
