const Icons = {
  play: require('../../assets/icons/play.png').default,
  star: require('../../assets/icons/star.png').default,
  'star-blank': require('../../assets/icons/star-blank.png').default,
  'star-gray': require('../../assets/icons/star-gray.png').default,
  add: require('../../assets/icons/add.png').default,
  'right-arrow': require('../../assets/icons/right-arrow.png')
    .default,
  'down-arrow': require('../../assets/icons/down-arrow.png').default,
  'tick-active': require('../../assets/icons/tick-active.png')
    .default,
  'tick-blank': require('../../assets/icons/tick-blank.png').default,
  'tick-white': require('../../assets/icons/tick-white.png').default,
  'radio-blank': require('../../assets/icons/radio-blank.png')
    .default,
  'radio-active': require('../../assets/icons/radio-active.png')
    .default,
  'tour-guide': require('../../assets/icons/tour-guide.png').default,
  media: require('../../assets/icons/media.png').default,
  'danger-media': require('../../assets/icons/danger-media.png')
    .default,
  reviews: require('../../assets/icons/reviews.png').default,
  // 'attachment': require('../../assets/icons/attachment.png').default,
  back: require('../../assets/icons/back.png').default,
  'back-gray': require('../../assets/icons/back-gray.png').default,
  'back-white': require('../../assets/icons/back-white.png').default,
  next: require('../../assets/icons/next.png').default,
  // 'book': require('../../assets/icons/book.png').default,
  calendar: require('../../assets/icons/calendar.png').default,
  'camera-filled': require('../../assets/icons/camera-filled.png')
    .default,
  'flip-camera': require('../../assets/icons/flip-camera.png')
    .default,
  // 'camera': require('../../assets/icons/camera.png').default,
  heart: require('../../assets/icons/heart.png').default,
  // 'certified': require('../../assets/icons/certified.png').default,
  // 'chat': require('../../assets/icons/chat.png').default,
  close: require('../../assets/icons/close.png').default,
  'close-white': require('../../assets/icons/close-white.png')
    .default,
  'close-red': require('../../assets/icons/close-red.png').default,
  'close-outline': require('../../assets/icons/close-outline.png')
    .default,
  coin: require('../../assets/icons/coin.png').default,
  // 'comment': require('../../assets/icons/comment.png').default,
  complete: require('../../assets/icons/complete.png').default,
  // 'crown': require('../../assets/icons/crown.png').default,
  // 'data': require('../../assets/icons/data.png').default,
  date: require('../../assets/icons/date.png').default,
  'date-white': require('../../assets/icons/date-white.png').default,
  notifications: require('../../assets/icons/notifications.png')
    .default,
  'notifications-outlined':
    require('../../assets/icons/notifications-outlined.png').default,
  delete: require('../../assets/icons/delete.png').default,
  device: require('../../assets/icons/device.png').default,
  // 'drag': require('../../assets/icons/drag.png').default,
  edit: require('../../assets/icons/edit.png').default,
  'edit-gray': require('../../assets/icons/edit-gray.png').default,
  // 'email': require('../../assets/icons/email.png').default,
  // 'error': require('../../assets/icons/error.png').default,
  // 'facebook-filled': require('../../assets/icons/facebook-filled.png').default,
  facebook: require('../../assets/icons/facebook.png').default,
  apple: require('../../assets/icons/apple.png').default,
  // 'favorite-filled': require('../../assets/icons/favorite-filled.png').default,
  // 'favorite': require('../../assets/icons/favorite.png').default,
  // 'full-size': require('../../assets/icons/full-size.png').default,
  google: require('../../assets/icons/google.png').default,
  // 'group': require('../../assets/icons/group.png').default,
  // 'info-outlined': require('../../assets/icons/info-outlined.png').default,
  info: require('../../assets/icons/info.png').default,
  like: require('../../assets/icons/like.png').default,
  // 'instagram-outlined': require('../../assets/icons/instagram-outlined.png').default,
  // 'instagram': require('../../assets/icons/instagram.png').default,
  // 'investigate': require('../../assets/icons/investigate.png').default,
  language: require('../../assets/icons/language.png').default,
  // 'link-1': require('../../assets/icons/link-1.png').default,
  // 'link': require('../../assets/icons/link.png').default,
  // 'loading': require('../../assets/icons/loading.png').default,
  // 'locate': require('../../assets/icons/locate.png').default,
  // location: require('../../assets/icons/location.png').default,
  // 'location-circle': require('../../assets/location.png').default,
  'location-black': require('../../assets/icons/location-black.png')
    .default,
  map: require('../../assets/icons/map.png').default,
  'menu-white': require('../../assets/icons/menu-white.png').default,
  'hamburger-menu': require('../../assets/icons/hamburger-menu.png')
    .default,
  // 'message-1': require('../../assets/icons/message-1.png').default,
  // 'message': require('../../assets/icons/message.png').default,
  // 'microphone Filled': require('../../assets/icons/microphone Filled.png').default,
  'mic-white': require('../../assets/icons/mic-white.png').default,
  'mic-white-muted': require('../../assets/icons/mic-white-muted.png')
    .default,
  more: require('../../assets/icons/more.png').default,
  // 'music': require('../../assets/icons/music.png').default,
  // 'notification-filled': require('../../assets/icons/notification-filled.png').default,
  // 'notification': require('../../assets/icons/notification.png').default,
  // 'profile-filled': require('../../assets/icons/profile-filled.png').default,
  profile: require('../../assets/icons/profile.png').default,
  // 'question': require('../../assets/icons/question.png').default,
  // 'rain': require('../../assets/icons/rain.png').default,
  // 'safety': require('../../assets/icons/safety.png').default,
  'trips-filled': require('../../assets/icons/trips-filled.png')
    .default,
  bookmark: require('../../assets/icons/bookmark.png').default,
  'bookmark-red': require('../../assets/icons/bookmark-red.png')
    .default,
  search: require('../../assets/icons/search.png').default,
  'search-bold': require('../../assets/icons/search-bold.png')
    .default,
  'search-white': require('../../assets/icons/search-white.png')
    .default,
  send: require('../../assets/icons/send.png').default,
  settings: require('../../assets/icons/settings.png').default,
  // 'shopping': require('../../assets/icons/shopping.png').default,
  // 'stabilzer': require('../../assets/icons/stabilzer.png').default,
  // 'sun': require('../../assets/icons/sun.png').default,
  // 'travel': require('../../assets/icons/travel.png').default,
  // 'trending': require('../../assets/icons/trending.png').default,
  // 'twitter-outlined': require('../../assets/icons/twitter-outlined.png').default,
  // 'twitter': require('../../assets/icons/twitter.png').default,
  video: require('../../assets/icons/video.png').default,
  'video-filled': require('../../assets/icons/video-filled.png')
    .default,
  // 'vote': require('../../assets/icons/vote.png').default,
  walk: require('../../assets/icons/walk.png').default,
  share: require('../../assets/icons/share.png').default,
  'share-white': require('../../assets/icons/share-white.png')
    .default,
  web: require('../../assets/icons/web.png').default,
  watching: require('../../assets/icons/watching.png').default,
  // 'whatsApp': require('../../assets/icons/whatsApp.png').default,
  // 'youtube': require('../../assets/icons/youtube.png').default,
  // 'zoom-in': require('../../assets/icons/zoom-in.png').default,
  // 'zoom-out': require('../../assets/icons/zoom-out.png').default,
  'headset-grey': require('../../assets/icons/headset-grey.png')
    .default,
  'stabiliser-grey': require('../../assets/icons/stabiliser-grey.png')
    .default,
  'wifi-grey': require('../../assets/icons/wifi-grey.png').default,
  right: require('../../assets/icons/right.png').default,
  left: require('../../assets/icons/left.png').default,
  // 'home-banner': require('../../assets/home-banner.png').default,
  'facebook-black': require('../../assets/icons/facebook-black.png')
    .default,
  instagram: require('../../assets/icons/instagram.png').default,
  linkedIn: require('../../assets/icons/linkedin.png').default,
  youtube: require('../../assets/icons/youtube.png').default,
  twitter: require('../../assets/icons/twitter.png').default,
  'web-gray': require('../../assets/icons/web-gray.png').default,
  'arrow-left': require('../../assets/icons/arrow-left.png').default,
  clock: require('../../assets/icons/clock.png').default,
  devices: require('../../assets/icons/devices.png').default,
  message: require('../../assets/icons/message.png').default,
  tag: require('../../assets/icons/tag.png').default,
  users: require('../../assets/icons/users.png').default,
  'web-search': require('../../assets/icons/web-search.png').default,
  'calendar-blue': require('../../assets/icons/calendar-blue.png')
    .default,
  'wechat-circle': require('../../assets/icons/wechat-circle.png')
    .default,
  'facebook-circle': require('../../assets/icons/facebook-circle.png')
    .default,
  'twitter-circle': require('../../assets/icons/twitter-circle.png')
    .default,
  'instagram-circle':
    require('../../assets/icons/instagram-circle.png').default,
  'linkedin-circle': require('../../assets/icons/linkedin-circle.png')
    .default,
  'delete-filled': require('../../assets/icons/delete-filled.png')
    .default,
  'notification-stop':
    require('../../assets/icons/notification-stop.png').default,
  'down-filled': require('../../assets/icons/down-filled.png')
    .default,
  visa: require('../../assets/icons/visa.png').default,
  'check-active': require('../../assets/icons/check-active.png')
    .default,
  'blue-tick': require('../../assets/icons/blue-tick.png').default,
  'check-ouline': require('../../assets/icons/check-ouline.png')
    .default,
  'close-red-circle':
    require('../../assets/icons/close-red-circle.png').default,
  warning: require('../../assets/icons/warning.png').default,
  'info-blue': require('../../assets/icons/info-blue.png').default,
  refresh: require('../../assets/icons/refresh.png').default,
  'refresh-grey': require('../../assets/icons/refresh-grey.png')
    .default,
  'add-rounded': require('../../assets/icons/add-rounded.png')
    .default,
  'money-rounded': require('../../assets/icons/money-rounded.png')
    .default,
  'trally-coin': require('../../assets/icons/trally-coin.png')
    .default,
  outcome: require('../../assets/icons/outcome.png').default,
  income: require('../../assets/icons/income.png').default,
  'rejected-request':
    require('../../assets/icons/rejected-request.png').default,
  'pending-request': require('../../assets/icons/pending-request.png')
    .default,
  review: require('../../assets/icons/review.png').default,
  'circle-yellow': require('../../assets/icons/circle-yellow.png')
    .default,
  bank: require('../../assets/icons/bank.png').default,
  'manual-request': require('../../assets/icons/manual-request.png')
    .default,
  'message-filled': require('../../assets/icons/message-filled.png')
    .default,
  menu: require('../../assets/icons/menu.png').default
}

export default Icons
