import React from 'react'
import { Col, Row, Text, Input } from 'components'
import { Table, Menu } from './components'
import PastTable from './components/pastTable'

const DesktopTours = props => {
  let {
    fetchingData,
    allTours,
    onAllPageChange,
    onSearchChange,
    selectedMenu,
    setSelectedMenu,
    toggleRefresh,
    allPastTours,
    onPastAllPageChange
  } = props
  return (
    <div style={{ margin: '8rem 4rem' }}>
      <Row>
        <Col>
          <Row>
            <Text QS size='32px' lineHeight='140%' weight='bold'>
              Tours
            </Text>
          </Row>
          <Row>
            <Menu
              selectedMenu={selectedMenu}
              setSelectedMenu={setSelectedMenu}
            />
          </Row>
        </Col>
        <Col>
          <Row
            style={{ alignItems: 'flex-end', marginBottom: '0.2rem' }}
          >
            <Text QS size='20px' lineHeight='140%' weight='bold'>
              Search Tours
            </Text>
          </Row>
          <Row>
            <Input
              placeholder={'Search tours'}
              wid={window.innerWidth > 998 ? '80%' : '100%'}
              borderRadius='30px'
              rounded
              onChange={onSearchChange}
            />
          </Row>
        </Col>
      </Row>

      <Row marg='1rem 0rem'>
        {selectedMenu === 'past' ? (
          <Table
            caller='desktop'
            viewer='all'
            data={allTours}
            handler={onAllPageChange}
            fetchingData={fetchingData}
            selectedMenu={selectedMenu}
            toggleRefresh={toggleRefresh}
        />
        ) : (
          <Table
            caller='desktop'
            viewer='all'
            data={allTours}
            handler={onAllPageChange}
            fetchingData={fetchingData}
            selectedMenu={selectedMenu}
            toggleRefresh={toggleRefresh}
          />
        )}
      </Row>
    </div>
  )
}

export default DesktopTours
