import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles
} from '@material-ui/core'
import { Button, Col, Text, Spinner, Link, Row } from 'components'
import PaginationView from './paginationView'
import { useHistory } from 'react-router-dom'
import NoFeedbackImage from 'assets/host.png'
import { format } from 'date-fns'
import { closestScheduleDate, checkStatus } from 'lib/helpers'
import { Api, theme } from 'lib'
import DeleteModal from './deleteModal'
import { useDispatch } from 'react-redux'

const StyledTableCell = withStyles(theme => ({
  head: {
    fontSize: 14,
    lineHeight: '12px',
    fontWeight: 'bold',
    backgroundColor: '#F4F3F3',
    color: '#22191A',
    width: props =>
      props.width
        ? props.width
        : props.caller === 'mobile'
        ? '20vw'
        : ''
  },
  body: {
    fontSize: 12
  }
}))(TableCell)

const CustomTable = ({
  caller,
  data,
  handler,
  fetchingData,
  selectedMenu,
  toggleRefresh
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [isDeleteVisible, setIsDeleteVisible] = useState(false)
  const [deleteTripId, setDeleteTripId] = useState(null)

  const toggleDelete = tripId => {
    setDeleteTripId(tripId ? tripId : null)
    setIsDeleteVisible(prev => !prev)
  }
  const handleDelete = async () => {
    dispatch({
      type: 'LOADING_BUTTON',
      payload: 'deleteRecordedTour'
    })

    await Api.delete(`/admin/deletePastTour/${deleteTripId}`)

    toggleRefresh()
    toggleDelete()
    dispatch({
      type: 'LOADING_BUTTON',
      payload: null
    })
  }

  const totalGuestsCount = trip => {
    let tempGuests = []
    if (trip && trip.dates && trip.dates.length > 0) {
      tempGuests = trip.dates
        .filter(d => d.users.length > 0)
        .reduce((result, first) => {
          return first.users.reduce((result, second) => {
            result.push(second)
            return result
          }, result)
        }, [])
      return tempGuests.length
    } else if (trip && trip.dates && trip.dates.length === 0) {
      return trip.interested.length
    }
    return tempGuests.length
  }

  return (
    <React.Fragment>
      <TableContainer component={Paper} style={{}}>
        <Table
          aria-label='customized table'
          style={caller === 'desktop' ? {} : { display: 'contents' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell caller={caller}>ID</StyledTableCell>

              <React.Fragment>
                <React.Fragment>
                  <StyledTableCell caller={caller}>
                    Tour Name
                  </StyledTableCell>
                  <StyledTableCell caller={caller}>
                    Tour ID
                  </StyledTableCell>
                  <StyledTableCell>Host</StyledTableCell>
                </React.Fragment>
              </React.Fragment>
              <StyledTableCell caller={caller}>
                Scheduled Date Time
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Actual Date Time
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Status
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Total Guests
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Action
              </StyledTableCell>
            </TableRow>
          </TableHead>
          {fetchingData ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={12}>
                  <Col>
                    <Spinner />
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : data && data.totalDocs <= 0 ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={9}>
                  <Col centerAll>
                    <img
                      src={NoFeedbackImage}
                      width={caller === 'desktop' ? 130 : 100}
                      alt='no feedback'
                      style={{
                        marginTop: 50,
                        marginBottom: 10
                      }}
                    />
                    <Text
                      weight='700'
                      size={caller === 'desktop' ? '16px' : '14px'}
                      lineHeight='40px'
                      QS
                    >
                      No tour found
                    </Text>
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {data && data.docs ? (
                <React.Fragment>
                  {data.docs.map((trip, index) => {
                    return (
                      <TableRow key={index}>
                        <StyledTableCell
                          component='th'
                          scope='row'
                          caller={caller}
                        >
                          {(data?.page - 1) * 10 + (index + 1)}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          <Link url={`/explore/${trip._id}`}>
                            {trip.name}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {trip._id}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            isAdmin={true}
                            url={`/view/${trip.host._id}`}
                          >
                            {trip.host.name}
                          </Link>
                        </StyledTableCell>

                        <StyledTableCell caller={caller}>
                          {trip.dates && trip.dates.length > 0 ? (
                            <React.Fragment>
                              {format(
                                new Date(
                                  closestScheduleDate(
                                    trip.dates
                                  ).start
                                ),
                                'p OOO, PPP'
                              )}{' '}
                              -{' '}
                              {format(
                                new Date(
                                  closestScheduleDate(trip.dates).end
                                ),
                                'p OOO, PPP'
                              )}
                            </React.Fragment>
                          ) : (
                            'Coming Soon'
                          )}
                        </StyledTableCell>
                        {trip.dates &&
                        trip.dates.length >= 1 &&
                        closestScheduleDate(trip.dates).startedAt &&
                        closestScheduleDate(trip.dates).endedAt ? (
                          <StyledTableCell caller={caller}>
                            {format(
                              new Date(
                                closestScheduleDate(
                                  trip.dates
                                ).startedAt
                              ),
                              'p OOO, PPP'
                            )}{' '}
                            -{' '}
                            {format(
                              new Date(
                                closestScheduleDate(
                                  trip.dates
                                ).endedAt
                              ),
                              'p OOO, PPP'
                            )}
                          </StyledTableCell>
                        ) : (
                          <StyledTableCell caller={caller}>
                            -
                          </StyledTableCell>
                        )}
                        <StyledTableCell
                          caller={caller}
                          style={{
                            textTransform: 'capitalize'
                          }}
                        >
                          {checkStatus(trip)}
                        </StyledTableCell>
                        <StyledTableCell>
                          {totalGuestsCount(trip)}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          <Row>
                            <Button
                              black
                              text='View'
                              onClick={() =>
                                history.push(
                                  '/tour/guests/' + trip._id
                                )
                              }
                            />
                            {selectedMenu &&
                              (selectedMenu === 'recorded' || selectedMenu === 'past') && (
                                <Button
                                  noShadow
                                  marg='0 0 0 4px'
                                  text='Delete'
                                  color={theme.RED_2}
                                  bordered
                                  borderColor={theme.RED_2}
                                  onClick={() => {
                                    toggleDelete(trip._id)
                                  }}
                                />
                              )}
                          </Row>
                        </StyledTableCell>
                      </TableRow>
                    )
                  })}
                </React.Fragment>
              ) : null}
            </TableBody>
          )}
        </Table>
        <PaginationView
          total={data?.totalPages}
          current={data?.page}
          hasNext={data?.hasNextPage}
          hasPrev={data?.hasPrevPage}
          handler={handler}
        />
      </TableContainer>
      <DeleteModal
        isVisible={isDeleteVisible}
        handleDelete={handleDelete}
        toggleVisible={toggleDelete}
        isMobile={caller === 'mobile'}
      />
    </React.Fragment>
  )
}

export default CustomTable
