import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  withStyles
} from '@material-ui/core'
import { format } from 'date-fns'
import { Button, Col, Text, Spinner } from 'components'
import PaginationView from './paginationView'
import { useHistory } from 'react-router-dom'
import NoHostImage from 'assets/host.png'
import { theme } from 'lib'
import { isBefore } from 'date-fns'

const StyledTableCell = withStyles(theme => ({
  head: {
    fontSize: 14,
    lineHeight: '12px',
    fontWeight: 'bold',
    backgroundColor: '#F4F3F3',
    textAlign: 'center',
    color: '#22191A',
    width: props => (props.caller === 'mobile' ? '20vw' : '')
  },
  body: {
    fontSize: 12,
    textAlign: 'center'
  }
}))(TableCell)

const CustomTable = ({
  caller,
  viewer,
  data,
  handler,
  fetchingData,
  selectHost,
  selectSingleHost,
  selectAll,
  checked
}) => {
  const history = useHistory()
  return (
    <React.Fragment>
      <TableContainer component={Paper} style={{}}>
        <Table
          aria-label='customized table'
          style={caller === 'desktop' ? {} : { display: 'contents' }}
        >
          <TableHead>
            {viewer === 'requested' ? (
              <TableRow>
                {selectHost && (
                  <StyledTableCell
                    style={{
                      minWidth: 'auto'
                    }}
                    caller={caller}
                  >
                    <Checkbox
                      checked={
                        data &&
                        data.docs &&
                        checked &&
                        (data.docs.length !== 0 ||
                          checked.length !== 0) &&
                        data.docs.length === checked.length
                      }
                      onChange={e => selectAll(e.target.checked)}
                      style={{
                        color: theme.BLUE
                      }}
                    />
                  </StyledTableCell>
                )}
                <StyledTableCell
                  caller={caller}
                  style={
                    caller === 'mobile' && selectHost
                      ? {
                          minWidth: '16vw'
                        }
                      : {}
                  }
                >
                  ID
                </StyledTableCell>
                <StyledTableCell caller={caller}>
                  First Name
                </StyledTableCell>
                <React.Fragment>
                  {caller === 'desktop' ? (
                    <React.Fragment>
                      <StyledTableCell>Last Name</StyledTableCell>
                      <StyledTableCell>Email Address</StyledTableCell>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
                <StyledTableCell caller={caller}>
                  Trial
                </StyledTableCell>
                <StyledTableCell caller={caller}>
                  Request
                </StyledTableCell>
                <React.Fragment>
                  {caller === 'desktop' ? (
                    <React.Fragment>
                      <StyledTableCell>
                        Country Origin
                      </StyledTableCell>
                      <StyledTableCell>
                        Requested Date
                      </StyledTableCell>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
                <StyledTableCell caller={caller}>
                  Action
                </StyledTableCell>
              </TableRow>
            ) : (
              <TableRow>
                <StyledTableCell caller={caller}>ID</StyledTableCell>
                <StyledTableCell caller={caller}>
                  Name
                </StyledTableCell>
                <React.Fragment>
                  {caller === 'desktop' ? (
                    <React.Fragment>
                      <StyledTableCell>
                        Country Origin
                      </StyledTableCell>
                      <StyledTableCell>
                        Requested Date
                      </StyledTableCell>
                      <StyledTableCell>Trip Hosted</StyledTableCell>
                      <StyledTableCell>Followers</StyledTableCell>
                      <StyledTableCell>Following</StyledTableCell>
                      <StyledTableCell>
                        Reviews Received
                      </StyledTableCell>
                    </React.Fragment>
                  ) : null}
                </React.Fragment>
                <StyledTableCell caller={caller}>
                  Rating Received
                </StyledTableCell>
                <StyledTableCell caller={caller}>
                  Action
                </StyledTableCell>
              </TableRow>
            )}
          </TableHead>
          {fetchingData ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={9}>
                  <Col>
                    <Spinner />
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : data && data.totalDocs <= 0 ? (
            <TableBody
              style={{
                width: '100vw',
                height: '40vh'
              }}
            >
              <TableRow>
                <StyledTableCell colSpan={9}>
                  <Col centerAll>
                    <img
                      src={NoHostImage}
                      width={caller === 'desktop' ? 130 : 100}
                      alt='no host'
                      style={{
                        marginTop: 50,
                        marginBottom: 10
                      }}
                    />
                    <Text
                      weight='700'
                      size={caller === 'desktop' ? '16px' : '14px'}
                      lineHeight='40px'
                      QS
                    >
                      No {viewer === 'requested' && 'requested'} host
                      found
                    </Text>
                  </Col>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          ) : viewer === 'requested' ? (
            <TableBody>
              {data && data.docs ? (
                <React.Fragment>
                  {data.docs.map((row, index) => {
                    let latestTrip = null
                    if (
                      row &&
                      row.status === 'first_trial' &&
                      row.trips &&
                      row.trips.length > 0
                    ) {
                      latestTrip = row.trips.find(
                        t =>
                          t.trialStatus &&
                          t.trialStatus === 'first_trial'
                      )
                    } else if (
                      row &&
                      row.status === 'second_trial' &&
                      row.trips &&
                      row.trips.length > 0
                    ) {
                      latestTrip = row.trips.find(
                        t =>
                          t.trialStatus &&
                          t.trialStatus === 'second_trial'
                      )
                    }

                    let checkIfTripSuccess = false
                    let now = new Date()

                    if (latestTrip && !latestTrip.published) {
                      checkIfTripSuccess = 'not-published'
                    } else if (
                      latestTrip &&
                      latestTrip.dates &&
                      latestTrip.dates.length > 0
                    ) {
                      checkIfTripSuccess =
                        latestTrip.dates[0] &&
                        isBefore(
                          new Date(latestTrip.dates[0].start),
                          now
                        ) &&
                        isBefore(
                          new Date(latestTrip.dates[0].end),
                          now
                        ) &&
                        !latestTrip.dates[0].endedAt
                          ? 'missed'
                          : latestTrip.dates[0] &&
                            latestTrip.dates[0].endedAt
                          ? 'completed'
                          : 'not-started'
                    }
                    const pendingHasTrips = {
                      trip: latestTrip,
                      tripSuccess: checkIfTripSuccess
                    }

                    return (
                      <TableRow key={index}>
                        {selectHost && (
                          <StyledTableCell caller={caller}>
                            <Checkbox
                              checked={
                                checked &&
                                checked.some(c => c === index)
                              }
                              onClick={() =>
                                selectSingleHost({ host: row, index })
                              }
                              style={{
                                color: theme.BLUE
                              }}
                            />
                          </StyledTableCell>
                        )}
                        <StyledTableCell
                          component='th'
                          scope='row'
                          caller={caller}
                        >
                          {(data?.page - 1) * 10 + (index + 1)}
                        </StyledTableCell>
                        <StyledTableCell caller={caller}>
                          {row?.name?.split(' ')[0]}
                        </StyledTableCell>
                        <React.Fragment>
                          {caller === 'desktop' ? (
                            <React.Fragment>
                              <StyledTableCell>
                                {row?.name?.split(' ')[1]}
                              </StyledTableCell>
                              <StyledTableCell>
                                {row?.email}
                              </StyledTableCell>
                            </React.Fragment>
                          ) : null}
                        </React.Fragment>
                        <StyledTableCell caller={caller}>
                          <Text
                            weight='700'
                            size='0.75rem'
                            lineHeight='1.054rem'
                            spacing='0.063rem'
                            marg='0.375rem 0 0 0'
                            color={
                              pendingHasTrips && pendingHasTrips.trip
                                ? pendingHasTrips.tripSuccess ===
                                  'missed'
                                  ? theme.alert
                                  : pendingHasTrips.tripSuccess ===
                                    'completed'
                                  ? theme.BLUE
                                  : pendingHasTrips.tripSuccess ===
                                    'not-started'
                                  ? theme.warning
                                  : theme.GRAY_3
                                : theme.GRAY_3
                            }
                          >
                            <span
                              style={{
                                fontSize: '0.5rem'
                              }}
                            >
                              ●
                            </span>{' '}
                            {pendingHasTrips && pendingHasTrips.trip
                              ? pendingHasTrips.tripSuccess ===
                                'missed'
                                ? 'Missed'
                                : pendingHasTrips.tripSuccess ===
                                  'completed'
                                ? 'Completed'
                                : pendingHasTrips.tripSuccess ===
                                  'not-started'
                                ? 'Not Started'
                                : pendingHasTrips.tripSuccess ===
                                  'not-published'
                                ? 'Not Published'
                                : 'Not Created'
                              : 'Not Created'}
                          </Text>
                        </StyledTableCell>
                        <StyledTableCell
                          caller={caller}
                          style={{
                            textTransform: 'capitalize'
                          }}
                        >
                          {row.status
                            ? row.status === 'first_trial' ||
                              row.status === 'second_trial'
                              ? 'Pending'
                              : row.status
                            : 'Unknown'}
                        </StyledTableCell>
                        <React.Fragment>
                          {caller === 'desktop' ? (
                            <React.Fragment>
                              <StyledTableCell>
                                {row?.country ? row?.country : '-'}
                              </StyledTableCell>
                              <StyledTableCell>
                                {row.requestedDate
                                  ? format(
                                      new Date(row.requestedDate),
                                      'p OOO, PPP'
                                    )
                                  : '-'}
                              </StyledTableCell>
                            </React.Fragment>
                          ) : null}
                        </React.Fragment>
                        <StyledTableCell caller={caller}>
                          <Button
                            black
                            text='View'
                            onClick={() =>
                              row._id &&
                              history.push('/view/' + row._id)
                            }
                          />
                        </StyledTableCell>
                      </TableRow>
                    )
                  })}
                </React.Fragment>
              ) : null}
            </TableBody>
          ) : (
            <TableBody>
              {data && data.docs ? (
                <React.Fragment>
                  {data.docs.map((row, index) => (
                    <TableRow key={index}>
                      <StyledTableCell component='th' scope='row'>
                        <strong>
                          {(data?.page - 1) * 10 + (index + 1)}
                        </strong>
                      </StyledTableCell>
                      <StyledTableCell>{row?.name}</StyledTableCell>
                      <React.Fragment>
                        {caller === 'desktop' ? (
                          <React.Fragment>
                            <StyledTableCell>
                              {row.country ? row.country : '-'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.requestedDate
                                ? format(
                                    new Date(row.requestedDate),
                                    'p OOO, PPP'
                                  )
                                : '-'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.trips ? row.trips.length : '0'}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.followersCount
                                ? row.followersCount
                                : 0}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.followingCount
                                ? row.followingCount
                                : 0}
                            </StyledTableCell>
                            <StyledTableCell>
                              {row.reviews ? row.reviews.length : '-'}
                            </StyledTableCell>
                          </React.Fragment>
                        ) : null}
                      </React.Fragment>
                      <StyledTableCell>
                        {row.totalRating
                          ? row.totalRating / row.reviews.length
                            ? (
                                row.totalRating / row.reviews.length
                              ).toFixed(1)
                            : 0
                          : '0'}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Button
                          black
                          wid='100px'
                          text='View'
                          onClick={() =>
                            row._id &&
                            history.push('/view/' + row._id)
                          }
                        />
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ) : null}
            </TableBody>
          )}
        </Table>
        <PaginationView
          total={data?.totalPages}
          current={data?.page}
          hasNext={data?.hasNextPage}
          hasPrev={data?.hasPrevPage}
          handler={handler}
        />
      </TableContainer>
    </React.Fragment>
  )
}

export default CustomTable
