import React from 'react'
import { Text, Row } from '../'
import styled from 'styled-components'

const Span = styled.span`
  margin-left: 4px;
  font-size: 14px;
  padding: 0 0 2px;
  line-height: 16px;
  font-weight: ${props => (props.bold ? '700' : '400')};
`
const ItemText = ({ item }) => {
  let { type } = item

  return (
    <Row wrap='wrap'>
      {type === 'welcome' && (
        <Text>
          <Text pad='0 0 2px' size='14px' lineHeight='16px' bold>
            Welcome
            <Text pad='0 0 2px' size='14px'>
              {' Your future notifications will be displayed here.'}
            </Text>
          </Text>
        </Text>
      )}

      {type === 'follow' && (
        <Text bold size='14px' pad='0 0 2px'>
          {item.username}
          <Span>started following you.</Span>
        </Text>
      )}

      {type === 'publish' && (
        <Text>
          <Text bold size='14px' pad='0 0 2px'>
            {item.username}
            <Span>published a new trip.</Span>
          </Text>
        </Text>
      )}

      {type === 'signUp' && (
        <Text>
          <Text bold size='14px' pad='0 0 2px'>
            {item.username}
            <Span>has booked </Span>
            <Span bold>{item.tripName}</Span>
            <Span>.</Span>
          </Text>
        </Text>
      )}

      {type === 'review' && (
        <Text>
          <Text bold size='14px' pad='0 0 2px'>
            Trip review,
            <Span>tell us about your recent experience.</Span>
          </Text>
        </Text>
      )}

      {type === 'aboutToStart' && (
        <Text>
          <Text bold size='14px' pad='0 0 2px'>
            {item.username}
            <Span>
              is reminding you that your upcoming trip is about to
              start.
            </Span>
          </Text>
        </Text>
      )}
    </Row>
  )
}

export default ItemText
