import React, { useMemo } from 'react'
import { Row, Col, Avatar, Clickable, Icon, Text } from 'components'
import ModalViewer from '../modalViewer'
import { env, theme } from 'lib'
import NoProfilePic from 'assets/no-profile-picture.png'
import styled from 'styled-components'
import { format } from 'date-fns'

const Heading = styled.label`
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: #22191a;
`
const Label = styled.label`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #2c2c2c;
`
const DetailContainer = styled.div`
  margin: 0.2rem 1rem;
  display: flex;
  flex-direction: column;
`
const LabelButton = styled.button`
  font-family: Quicksand;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: ${props =>
    props.requested
      ? '#FFBF63'
      : props.active
      ? '#4E6BEE'
      : '#EB5757'};
  background: ${props =>
    props.requested
      ? '#FFF3E1'
      : props.active
      ? '#EDF0FD'
      : '#FADDDD'};
  padding: 12px 24px;
  border-radius: 24px;
  margin-top: 12px;
`
const Button = styled.button`
  margin: 1rem 0.5rem;
  font-family: Quicksand;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: ${props =>
    props.approve ? '#F9F9F9' : props.reject ? '#EB5757' : '#CACACA'};
  background: ${props =>
    props.approve
      ? '#22191A'
      : props.reject
      ? 'transparent'
      : '#EAEAEA'};
  border: ${props => (props.reject ? '1px solid #EB5757' : '')};
  padding: 12px 24px;
  border-radius: 24px;
  cursor: pointer;
`
const ButtonContainer = styled.div`
  margin: 0.2rem 1rem;
  display: flex;
`

function DesktopHostMenu({
  host,
  toggleReset,
  id,
  approveFlag,
  setApproveFlag,
  onSuccess,
  rejectFlag,
  setRejectFlag,
  deactivateFlag,
  setDeactivateFlag,
  activateFlag,
  setActivateFlag,
  pendingHasTrips,
  goToTrialTrip
}) {
  const amplitudeUrl = useMemo(
    () =>
      `https://analytics.amplitude.com/trally/project/${env.AMPLITUDE_PROJECT_ID}/search/user_id%3D${id}`,
    [id]
  )

  return (
    <Row style={{ marginTop: '1rem' }}>
      <Col
        style={{
          flex: 0.15,
          marginRight: '2rem',
          alignItems: 'center'
        }}
      >
        <Avatar
          uri={
            host.avatar
              ? host.avatar + '?time="' + new Date() + '"'
              : NoProfilePic
          }
          size='128'
        />
        <LabelButton
          requested={
            host.status === 'pending' ||
            host.status === 'first_trial' ||
            host.status === 'second_trial'
              ? true
              : false
          }
          active={host.status === 'active' ? true : false}
        >
          {host.status === 'pending' ||
          host.status === 'first_trial' ||
          host.status === 'second_trial'
            ? 'Requested'
            : host.status === 'active'
            ? 'Active Host'
            : host.status === 'deactivated'
            ? 'Deactivated'
            : 'Rejected'}
        </LabelButton>
        <Button
          approve
          black
          noShadow
          pad='0 0.9375rem'
          marg='1rem 0'
        >
          <a
            href={amplitudeUrl}
            rel='noreferrer'
            target='_blank'
            style={{
              userSelect: 'none',
              msTouchSelect: 'none',
              msUserSelect: 'none',
              MozUserSelect: 'none',
              WebkitUserSelect: 'none'
            }}
          >
            <Text color={theme.WHITE} QS size='0.875rem'>
              Open Amplitude
            </Text>
          </a>
        </Button>
      </Col>
      <Col style={{ flex: 0.4 }}>
        <DetailContainer>
          <Heading>Host ID</Heading>
          <Label>{id}</Label>
        </DetailContainer>
        <DetailContainer>
          <Heading>First Name</Heading>
          <Label>
            {host.name && host?.name?.split(' ')[0]
              ? host?.name?.split(' ')[0]
              : '-'}
          </Label>
        </DetailContainer>
        <DetailContainer>
          <Heading>Last Name</Heading>
          <Label>
            {host.name && host?.name?.split(' ')[1]
              ? host?.name?.split(' ')[1]
              : '-'}
          </Label>
        </DetailContainer>
        <DetailContainer>
          <Heading>Email Address</Heading>
          <Label>{host.email}</Label>
        </DetailContainer>
        <DetailContainer>
          <Heading>Phone Number</Heading>
          <Label>{host.phone ? host.phone : '-'}</Label>
        </DetailContainer>
        <DetailContainer>
          <Heading>Requested Date</Heading>
          <Label>
            {host.requestedDate
              ? format(new Date(host.requestedDate), 'p OOO, PPP')
              : '-'}
          </Label>
        </DetailContainer>
        <DetailContainer>
          <Heading>Country Origin</Heading>
          <Label>{host.country ? host.country : 'undefined'}</Label>
        </DetailContainer>
        {host.status === 'pending' ||
        host.status === 'first_trial' ||
        host.status === 'second_trial' ? (
          <React.Fragment>
            {(host.status === 'first_trial' ||
              host.status === 'second_trial') && (
              <DetailContainer>
                <Clickable
                  onClick={() =>
                    host.status === 'first_trial' &&
                    host.quota <= 0 &&
                    toggleReset()
                  }
                >
                  <Row align='center' marg='1.5rem 0 0.5rem'>
                    <Icon
                      type={
                        host.status === 'first_trial' &&
                        host.quota <= 0
                          ? 'refresh'
                          : 'refresh-grey'
                      }
                      size='1.063rem'
                      marg='0 0.375rem 0 0'
                    />
                    <Text
                      QS
                      weight='700'
                      size='16px'
                      lineHeight='22.4px'
                      color={
                        host.status === 'first_trial' &&
                        host.quota <= 0
                          ? theme.BLUE
                          : theme.gray2
                      }
                    >
                      Reset the trial tour
                    </Text>
                  </Row>
                </Clickable>
              </DetailContainer>
            )}

            <ButtonContainer>
              <ModalViewer
                id={id}
                name={'approve'}
                flag={approveFlag}
                setFlag={() => setApproveFlag(false)}
                setSuccessFlag={name => onSuccess(name)}
              />
              <Button approve onClick={() => setApproveFlag(true)}>
                Approve
              </Button>
              <ModalViewer
                id={id}
                name={'reject'}
                flag={rejectFlag}
                setFlag={() => setRejectFlag(false)}
                setSuccessFlag={name => onSuccess(name)}
              />
              <Button reject onClick={() => setRejectFlag(true)}>
                Reject
              </Button>
            </ButtonContainer>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {host.status === 'active' ? (
              <ButtonContainer>
                <ModalViewer
                  id={id}
                  name={'deactivate'}
                  flag={deactivateFlag}
                  setFlag={() => setDeactivateFlag(false)}
                  setSuccessFlag={name => onSuccess(name)}
                />
                <Button
                  reject
                  onClick={() => setDeactivateFlag(true)}
                >
                  Deactivate
                </Button>
              </ButtonContainer>
            ) : (
              <React.Fragment>
                {host.status === 'deactivated' ? (
                  <ButtonContainer>
                    <ModalViewer
                      id={id}
                      name={'activate'}
                      flag={activateFlag}
                      setFlag={() => setActivateFlag(false)}
                      setSuccessFlag={name => onSuccess(name)}
                    />
                    <Button
                      approve
                      onClick={() => setActivateFlag(true)}
                    >
                      Approve Again
                    </Button>
                  </ButtonContainer>
                ) : (
                  <ButtonContainer>
                    <Button>Approve</Button>
                    <Button>Reject</Button>
                  </ButtonContainer>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Col>
      {host.status === 'pending' ||
      host.status === 'first_trial' ||
      host.status === 'second_trial' ? (
        <Col>
          <Text QS weight='700' size='16px' lineHeight='22.4px'>
            Trial tour
          </Text>
          <Row>
            <Col>
              <Text
                QS
                weight='700'
                size='14px'
                lineHeight='16.8px'
                marg='9px 0 0 0'
              >
                Link
              </Text>
              <Col
                noFlex
                minHt='24px'
                justify='center'
                pad='0 20px 0 0'
              >
                <Clickable
                  onClick={() =>
                    pendingHasTrips.trip &&
                    goToTrialTrip(host.trips[0]._id)
                  }
                >
                  <Text
                    weight='400'
                    size='14px'
                    lineHeight='19.8px'
                    wid='200px'
                    style={{
                      wordBreak: 'break-all'
                    }}
                    textDecorationLine={
                      pendingHasTrips.trip ? 'underline' : 'none'
                    }
                  >
                    {pendingHasTrips.trip
                      ? env.API_URL &&
                        (env.API_URL.includes('localhost') ||
                          env.API_URL.includes('192.168'))
                        ? 'http://localhost:3000'
                        : `https://${
                            env.API_URL.includes('stagingapi.trally')
                              ? 'staging.trally.com'
                              : 'app.trally.com'
                          }`
                      : '-'}
                  </Text>
                  {pendingHasTrips.trip && (
                    <Text
                      weight='400'
                      size='14px'
                      lineHeight='19.8px'
                      wid='200px'
                      style={{
                        wordBreak: 'break-all'
                      }}
                      textDecorationLine='underline'
                    >
                      explore/{host.trips[0]._id}
                    </Text>
                  )}
                </Clickable>
              </Col>
            </Col>
            <Col>
              <Text
                QS
                weight='700'
                size='14px'
                lineHeight='16.8px'
                marg='9px 0 0 0'
              >
                Status:
              </Text>
              <Text
                weight='700'
                size='0.75rem'
                lineHeight='1.054rem'
                spacing='0.063rem'
                marg='0.375rem 0 0 0'
                color={
                  pendingHasTrips && pendingHasTrips.trip
                    ? pendingHasTrips.tripSuccess === 'missed'
                      ? theme.alert
                      : pendingHasTrips.tripSuccess === 'completed'
                      ? theme.BLUE
                      : pendingHasTrips.tripSuccess === 'not-started'
                      ? theme.warning
                      : theme.GRAY_3
                    : theme.GRAY_3
                }
              >
                <span
                  style={{
                    fontSize: '0.5rem'
                  }}
                >
                  ●
                </span>{' '}
                {pendingHasTrips && pendingHasTrips.trip
                  ? pendingHasTrips.tripSuccess === 'missed'
                    ? 'Missed'
                    : pendingHasTrips.tripSuccess === 'completed'
                    ? 'Completed'
                    : pendingHasTrips.tripSuccess === 'not-started'
                    ? 'Not Started'
                    : pendingHasTrips.tripSuccess === 'not-published'
                    ? 'Not Published'
                    : 'Not Created'
                  : 'Not Created'}
              </Text>
            </Col>
          </Row>
        </Col>
      ) : (
        <Col>
          <DetailContainer>
            <Heading>Trips Hosted</Heading>
            <Label>{host.trips ? host.trips.length : '0'}</Label>
          </DetailContainer>
          <DetailContainer>
            <Heading>Followers</Heading>
            <Label>{host.followersCount}</Label>
          </DetailContainer>
          <DetailContainer>
            <Heading>Following</Heading>
            <Label>{host.followingCount}</Label>
          </DetailContainer>
          <DetailContainer>
            <Heading>Rating Recieved by G</Heading>
            <Label>{host.reviews.length}</Label>
          </DetailContainer>
          <DetailContainer>
            <Heading>Average Rating by G</Heading>
            <Label>
              {host.totalRating
                ? host.totalRating / host.reviews.length
                  ? (host.totalRating / host.reviews.length).toFixed(
                      1
                    )
                  : 0
                : '0'}
            </Label>
          </DetailContainer>
        </Col>
      )}
    </Row>
  )
}

export default DesktopHostMenu
