import React from 'react'
import styled from 'styled-components'
import { Icons } from 'lib'

const MyIcon = styled.img`
  width: ${props => props.size || '18px'} !important;
  height: ${props =>
    props.ht ? props.ht : props.size || '18px'} !important;
  margin: ${props => props.marg || '0'};
  position: ${props => props.pos || ''};
  left: ${props => props.left};
  right: ${props => props.right};
  top: ${props => props.top};
`

const Icon = props => {
  return <MyIcon {...props} src={Icons[props.type]} />
}

export default Icon
