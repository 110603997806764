import React from 'react'
import { Col, Row, Text, Input, Button, Icon } from 'components'
import { FilterButton, Table } from './components'
import { theme } from 'lib'

const DesktopPayout = props => {
  let {
    activeButton,
    changeFilter,
    fetchingData,
    selectPayout,
    toggleSelectPayout,
    selectSinglePayout,
    selectAll,
    checked,
    approveBulkPayouts
  } = props
  return (
    <div style={{ margin: '8rem 4rem' }}>
      <Row>
        <Col>
          <Row>
            <Text QS size='32px' lineHeight='140%' weight='bold'>
              Payouts
            </Text>
          </Row>
          <Row>
            {activeButton ? (
              <React.Fragment>
                <FilterButton
                  text='Requested'
                  activeButton={activeButton}
                  changeFilter={changeFilter}
                />
                <FilterButton
                  text='All Requests'
                  activeButton={activeButton}
                  changeFilter={changeFilter}
                />
              </React.Fragment>
            ) : null}
          </Row>
        </Col>
        <Col
          style={{
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            marginBottom: 8,
            marginRight: window.innerWidth < 998 ? 30 : 63
          }}
        >
          {activeButton !== 'Requested' ||
          !props.requestedUsers.docs ||
          props.requestedUsers.docs.length <= 0 ? (
            <React.Fragment></React.Fragment>
          ) : selectPayout ? (
            <Row
              style={{
                alignItems: 'flex-end'
              }}
            >
              <Button
                text='Cancel'
                wid='98px'
                ht='48px'
                color={theme.BLACK}
                bordered
                borderColor={theme.BLACK}
                bgColor='transparent'
                onClick={() => toggleSelectPayout()}
                marg='0 20px 0 0'
                noShadow
              />
              <Button
                wid='162px'
                ht='48px'
                color={theme.WHITE}
                bgColor={theme.BLUE}
                onClick={() => approveBulkPayouts()}
                noShadow
              >
                <Row centerAll>
                  <Text
                    QS
                    weight='700'
                    size='16px'
                    lineHeight='29px'
                    color={theme.WHITE}
                    marg='0 5px 0 0'
                  >
                    Approve All
                  </Text>
                  <Icon type='tick-white' size='17px' />
                </Row>
              </Button>
            </Row>
          ) : (
            <Button
              text='Select Items'
              wid='143px'
              ht='48px'
              color={theme.WHITE}
              bgColor={theme.BLUE}
              onClick={() => toggleSelectPayout()}
              noShadow
            />
          )}
        </Col>
        <Col>
          <Row
            style={{ alignItems: 'flex-end', marginBottom: '0.2rem' }}
          >
            <Text QS size='20px' lineHeight='140%' weight='bold'>
              Search Host
            </Text>
          </Row>
          <Row>
            <Input
              placeholder={
                activeButton === 'Requested'
                  ? 'Search requesting hosts'
                  : 'Search hosts'
              }
              wid={window.innerWidth > 998 ? '80%' : '100%'}
              borderRadius='30px'
              rounded
              onChange={props.onSearchChange}
            />
          </Row>
        </Col>
      </Row>
      <Row marg='1rem 0rem'>
        {activeButton === 'Requested' ? (
          <Table
            caller='desktop'
            viewer='requested'
            data={props.requestedUsers}
            handler={props.onRequestedPageChange}
            fetchingData={fetchingData}
            selectPayout={selectPayout}
            selectSinglePayout={selectSinglePayout}
            selectAll={selectAll}
            checked={checked}
          />
        ) : (
          <Table
            caller='desktop'
            viewer='all'
            data={props.allUsers}
            handler={props.onAllPageChange}
            fetchingData={fetchingData}
          />
        )}
      </Row>
    </div>
  )
}

export default DesktopPayout
