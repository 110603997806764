import React, { useState } from 'react'
import { Col, ResponsiveModal, Text, Row, Button } from 'components'
import { theme, Api } from 'lib'
import { useDispatch } from 'react-redux'

function ResetTrialModal({
  showReset,
  toggleReset,
  isMobile,
  hostId,
  setReloadUser
}) {
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0)
  const dispatch = useDispatch()

  const handleResetTour = async () => {
    setLoading(true)
    dispatch({
      type: 'LOADING_BUTTON',
      payload: 'resettingHost'
    })

    if (hostId) {
      const res = await Api.patch('/admin/resetTrialHost', {
        hostId: hostId
      })
      if (res && res.success) {
        setStep(1)
        setReloadUser(true)
      } else {
        setStep(2)
      }
    } else {
      setStep(2)
    }

    dispatch({
      type: 'LOADING_BUTTON',
      payload: null
    })
    setLoading(false)
  }

  const resetValues = () => {
    dispatch({
      type: 'LOADING_BUTTON',
      payload: null
    })
    setLoading(false)
    setStep(0)
  }
  return (
    <ResponsiveModal
      visible={showReset}
      toggleVisible={() => {
        resetValues()
        toggleReset()
      }}
      desktopModalWid='23.5rem'
      desktopModalHt='auto'
      desktopModalBorderRadius='1.25rem'
      isMobile={isMobile}
    >
      {step === 0 ? (
        <Col bg={theme.WHITE} centerAll pad='2.5rem 1.188rem'>
          <Text
            QS
            wid='21.125rem'
            weight='700'
            size='1.5rem'
            lineHeight='1.8rem'
            color={theme.BLACK_TRALLY}
            center
          >
            Are you sure you want to reset the trial tour?
          </Text>

          <Row marg='1.5rem 0 0'>
            <Button
              bordered
              borderColor={theme.BLACK_TRALLY}
              text='Cancel'
              wid='6.625rem'
              ht='3.625rem'
              marg='0 0.75rem 0 0'
              onClick={() => {
                resetValues()
                toggleReset()
              }}
              disabled={loading}
              noShadow
            />
            <Button
              black
              text='Reset Tour'
              wid='9.5rem'
              ht='3.625rem'
              action='resettingHost'
              onClick={() => handleResetTour()}
              noShadow
            />
          </Row>
        </Col>
      ) : (
        <Col bg={theme.WHITE} centerAll pad='2.5rem 1.188rem'>
          <Text
            QS
            wid='21.125rem'
            weight='700'
            size='1.5rem'
            lineHeight='1.8rem'
            marg='0 0 1.5rem'
            color={theme.BLACK_TRALLY}
            center
          >
            {step === 1
              ? 'Trial tour successfully resetted!'
              : 'There was some error resetting trail tour for host!'}
          </Text>

          <Button
            black={step === 1}
            bg={step === 2 ? theme.RED : null}
            color={step === 2 ? theme.WHITE : null}
            text='OK'
            wid='16rem'
            ht='3.625rem'
            marg='0 0.75rem 0 0'
            onClick={() => {
              resetValues()
              toggleReset()
            }}
            noShadow
          />
        </Col>
      )}
    </ResponsiveModal>
  )
}

export default ResetTrialModal
