import { Api } from 'lib'

const addNewCategory = ({ data, action }) => {
  return async dispatch => {
    const res = await Api.post('/admin/addCategory', data)

    dispatch({
      type: 'LOADING_BUTTON',
      payload: null
    })

    if (!res) return

    action && action(res)
  }
}

export default addNewCategory
