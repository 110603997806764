import React from 'react'
import styled, { keyframes } from 'styled-components'
import Loading from '../assets/icons/loading.png'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const Container = styled.div`
  animation: ${rotate} 1s linear infinite;
  margin: 0 auto;
`

const Spinner = ({ dimensions = 25 }) => (
  <Container>
    <img
      style={{ width: dimensions, height: dimensions }}
      src={Loading}
      alt='loading'
    />
  </Container>
)

export default Spinner
