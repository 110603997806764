import React from 'react'
import styled, { css } from 'styled-components'
import { inputStyles } from 'styles'
import { Text, Icon, Row } from '.'
import { theme } from 'lib'
import WebInput from './WebInput'

const MainContainer = styled.div`
  display: flex;
  ${props => inputStyles(props, css).container}
`

const IconContainer = styled.div`
  display: flex;
  width: 45px;
  align-items: center;
  justify-content: center;
`

const StyledInput = styled.input`
  ${props => props.wid && `width: ${props.wid}`};
  ${props => props.hasBorder && `border: ${props.hasBorder}`};
  ${props => inputStyles(props, css).input};
`

const CustomTextArea = styled.textarea`
  height: 164px;
  ${props => props.wid && `width: ${props.wid}`};
  ${props => inputStyles(props, css).textarea};
`

const MyInput = props => {
  const {
    limit,
    icon,
    title,
    length,
    webInput,
    titleTextSize,
    titleFontFamily,
    iconSize,
    placeholderTextColor,
    multiline
  } = props

  if (webInput) return <WebInput {...props} />

  return (
    <div style={{ width: '100%' }}>
      {title && (
        <Text
          style={{
            fontWeight: 'bold',
            color: theme.gray2,
            fontSize: titleTextSize ? titleTextSize : 12,
            letterSpacing: 1,
            marginBottom: 8,
            fontFamily: titleFontFamily
              ? titleFontFamily
              : 'SFProDisplay'
          }}
        >
          {title.toUpperCase()}
        </Text>
      )}

      <MainContainer {...props}>
        {icon && (
          <IconContainer>
            <Icon type={props.icon} size={iconSize ? iconSize : 16} />
          </IconContainer>
        )}

        {multiline ? (
          <CustomTextArea
            placeholderTextColor={
              placeholderTextColor
                ? placeholderTextColor
                : theme.gray6
            }
            {...props}
          />
        ) : (
          <StyledInput
            placeholderTextColor={
              placeholderTextColor
                ? placeholderTextColor
                : props.rounded
                ? theme.gray5
                : theme.gray6
            }
            {...props}
          />
        )}
        {props.limit && (
          <Text style={{ fontSize: 16, letterSpacing: 0.2 }}>
            {length}/{limit}
          </Text>
        )}
      </MainContainer>
      {props.err && props.errMessage && (
        <Row noFlex center marg='6px 0'>
          <Icon type='close-red' size='9px' />
          <Text
            marg='0 0 0 10px'
            color={theme.RED}
            weight='400'
            size='14px'
            spacing='0.5px'
          >
            {props.errMessage}
          </Text>
        </Row>
      )}
    </div>
  )
}
export default MyInput
