import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Login } from 'screens'

function NonUserRoutes() {
  return (
    <Switch>
      <Route exact path='/login' component={Login} />
      <Redirect from='*' to='/login' />
    </Switch>
  )
}

export default NonUserRoutes
