import { removeToken } from 'lib'

const logout = payload => {
  return async dispatch => {
    dispatch({ type: 'LOGOUT' })
    removeToken()
    payload.action && payload.action()
  }
}

export default logout
