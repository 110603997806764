import { Api } from 'lib'

const getAllCategories = () => {
  return async dispatch => {
    dispatch({
      type: 'IS_FETCHING',
      payload: true
    })
    const res = await Api.get('/admin/getAllCategories')
    dispatch({
      type: 'IS_FETCHING',
      payload: false
    })
    if (!res) return
    dispatch({
      type: 'SET_CATEGORIES',
      payload: res
    })
  }
}

export default getAllCategories
