import { differenceInSeconds, differenceInDays } from 'date-fns'

const getNotifTime = updatedAt => {
  const date = new Date(updatedAt)
  const now = new Date()
  const seconds = differenceInSeconds(now, date)

  let text = ''

  switch (true) {
    case seconds < 3600:
      text = `${(seconds / 60).toFixed(0)} m`
      break

    case seconds < 86400:
      text = `${(seconds / 3600).toFixed(0)} h`
      break

    default:
      text = `${differenceInDays(now, date)} d`
  }

  return text
}

export default getNotifTime
