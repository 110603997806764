const defaultState = {
  loadingButton: null,
  loadingUser: false,
  searchResults: null,
  searchText: null,
  tripData: {},
  tags: [],
  appLang: null,
  authModalVisible: false,
  screenDimensions: {
    width: null,
    height: null
  }
}

const appState = (state = defaultState, action) => {
  const { payload, type } = action

  switch (type) {
    // keeping this in case we'll need a global err res handler
    case 'HANDLE_RES':
      return {
        ...state,
        errRes: {
          err: payload.err ? payload.err : null,
          res: payload.res ? payload.res : null,
          isErr: payload.isErr
        }
      }

    case 'LOADING_BUTTON':
      return {
        ...state,
        loadingButton: payload
      }

    case 'LOADING_USER':
      return {
        ...state,
        loadingUser: payload
      }

    case 'TOGGLE_AUTH_MODAL': {
      return {
        ...state,
        authModalVisible: !state.authModalVisible
      }
    }

    case 'SET_TAGS':
      return {
        ...state,
        tags: payload
      }

    case 'CREATE_TAG':
      if (state && state.tags && state.tags.length > 0) {
        const tagExists = state.tags.find(
          x => x._id === payload.tag._id
        )
        if (tagExists) return state.tags

        return {
          ...state,
          tags: [...state.tags, payload.tag]
        }
      }
      return {
        ...state,
        tags: [payload.tag]
      }

    case 'TOGGLE_CREATE_TRIP':
      return {
        ...state,
        creatingTrip: payload
      }

    case 'SET_TRIP_DATA':
      return {
        ...state,
        tripData: payload
      }

    case 'SET_APP_LANG':
      return {
        ...state,
        appLang: payload
      }

    case 'LOGOUT':
      return defaultState

    case 'SEARCH':
      return { ...state, searchResults: payload }

    case 'SET_SEARCH':
      return { ...state, searchText: payload }

    case 'CLEAR_SEARCH':
      return { ...state, searchResults: null }

    case 'SET_SCREEN_DIMENSION':
      return {
        ...state,
        screenDimensions: payload
      }
    default:
      return state
  }
}

export default appState
