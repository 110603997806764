import React from 'react'
import AccountDetails from '../accountDetails'
import { Col } from 'components'

function MobileAccountDetails({ host }) {
  return (
    <Col
      noFlex
      style={{
        textAlign: 'start'
      }}
    >
      <AccountDetails isMobile={true} user={host} />
    </Col>
  )
}

export default MobileAccountDetails
