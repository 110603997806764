import React, { useEffect, useState, useMemo } from 'react'
import { Api, env } from 'lib'
import DesktopTours from './DesktopTourGuests'
import MobileTours from './MobileTourGuests'
import { useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'

function TourGuestsDetails() {
  const { id, menu } = useParams()
  const history = useHistory()

  const [fetchingData, setFetchingData] = useState(false)
  const [tourBooked, setTourBooked] = useState(undefined)
  const [tourWatched, setTourWatched] = useState(undefined)
  const [tourInterested, setTourInterested] = useState(undefined)
  const [searchTourBooked, setSearchTourBooked] = useState(undefined)
  const [searchTourWatched, setSearchTourWatched] =
    useState(undefined)
  const [searchBooked, setSearchBooked] = useState('')
  const [searchWatched, setSearchWatched] = useState('')
  const [searchInterested, setSearchInterested] = useState('')
  const [selectedMenu, setSelectedMenu] = useState('booked')
  const [allPageNo, setAllPageNo] = useState(1)
  const { screenDimensions } = useSelector(state => state.appState)

  useEffect(() => {
    if (menu && menu.length > 0) {
      setSelectedMenu(menu)
    } else {
      setSelectedMenu('booked')
    }
  }, [menu])

  useEffect(() => {
    async function fetchData() {
      if (id !== 'null') {
        setFetchingData(true)
        const res = await Api.get('/admin/getTourGuests/' + id)
        if (res) {
          setTourBooked(res.tripBooked)
          setTourWatched(res.tripWatched)
        } else {
          console.log('error in fetching tour guests')
        }
        setFetchingData(false)
      }
    }
    fetchData()
  }, [id])

  useEffect(() => {
    async function fetchData() {
      setFetchingData(true)
      const res = await Api.get(
        `/admin/getAllGuestsInterested/${id}/${allPageNo}?search=${searchInterested}`
      )
      if (res) {
        setTourInterested({
          ...res,
          docs:
            res.docs && res.docs.length > 0 ? res.docs.reverse() : []
        })
      } else {
        console.log('error in fetching getAllGuestsInterested')
      }
      setFetchingData(false)
    }
    fetchData()
  }, [allPageNo, id, searchInterested])

  useMemo(() => {
    if (
      tourBooked &&
      tourBooked.totalGuests &&
      tourBooked.totalGuests.length > 0
    ) {
      const guests = tourBooked.totalGuests.filter(
        item =>
          item.user &&
          item.user.name
            .toLocaleLowerCase()
            .includes(searchBooked.toLocaleLowerCase())
      )
      setSearchTourBooked({
        ...tourBooked,
        totalGuests: guests
      })
    }
  }, [searchBooked, tourBooked])

  useMemo(() => {
    if (
      tourWatched &&
      tourWatched.totalGuests &&
      tourWatched.totalGuests.length > 0
    ) {
      const guests = tourWatched.totalGuests.filter(
        item =>
          item.user &&
          item.user.name
            .toLocaleLowerCase()
            .includes(searchWatched.toLocaleLowerCase())
      )
      setSearchTourWatched({
        ...tourWatched,
        totalGuests: guests
      })
    }
  }, [searchWatched, tourWatched])

  const onBookedSearchChange = ({ target: { value } }) => {
    setSearchBooked(value)
  }

  const onWatchedSearchChange = ({ target: { value } }) => {
    setSearchWatched(value)
  }

  const onInterestedSearchChange = ({ target: { value } }) => {
    setSearchInterested(value)
  }

  const onAllPageChange = page => {
    setAllPageNo(page)
  }

  return (
    <React.Fragment>
      {screenDimensions.width > env.SCREEN_WIDTH ? (
        <div style={{ marginTop: '80px' }}>
          <DesktopTours
            fetchingData={fetchingData}
            tourBooked={
              searchBooked && searchTourBooked
                ? searchTourBooked
                : tourBooked
            }
            tourWatched={
              searchWatched && searchTourWatched
                ? searchTourWatched
                : tourWatched
            }
            tourInterested={tourInterested}
            onBookedSearchChange={onBookedSearchChange}
            onWatchedSearchChange={onWatchedSearchChange}
            onInterestedSearchChange={onInterestedSearchChange}
            history={history}
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            tourId={id}
            handler={onAllPageChange}
          />
        </div>
      ) : (
        <MobileTours
          fetchingData={fetchingData}
          tourBooked={
            searchBooked && searchTourBooked
              ? searchTourBooked
              : tourBooked
          }
          tourWatched={
            searchWatched && searchTourWatched
              ? searchTourWatched
              : tourWatched
          }
          tourInterested={tourInterested}
          onBookedSearchChange={onBookedSearchChange}
          onWatchedSearchChange={onWatchedSearchChange}
          onInterestedSearchChange={onInterestedSearchChange}
          history={history}
          tourId={id}
          handler={onAllPageChange}
        />
      )}
    </React.Fragment>
  )
}

export default TourGuestsDetails
