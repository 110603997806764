import React from 'react'
import { Row, Text, Clickable } from 'components'
import { theme } from 'lib'
import { useHistory } from 'react-router-dom'

const menuOptions = [
  {
    title: 'Live Tours',
    link: '',
    menu: 'live'
  },
  {
    title: 'Scheduled Tours',
    link: '/scheduled',
    menu: 'schedule'
  },
  {
    title: 'Recorded Tours',
    link: '/recorded',
    menu: 'recorded'
  },
  {
    title: 'Past Tours',
    link: '/past',
    menu: 'past'
  }
]
function Menu({ selectedMenu, setSelectedMenu }) {
  const history = useHistory()

  const changeMenu = ({ link, menu }) => {
    setSelectedMenu(menu)
    history.push(`/tours${link}`)
  }

  return (
    <Row marg='1.25rem 0'>
      {menuOptions.map(m => (
        <Clickable
          onClick={() => changeMenu({ link: m.link, menu: m.menu })}
          key={m.menu}
        >
          <Text
            QS
            bold
            size='1.25rem'
            lineHeight='1.5rem'
            marg='0 3.0625rem 0 0'
            color={
              m.menu === selectedMenu
                ? theme.FULL_BLACK
                : theme.gray16
            }
          >
            {m.title}
          </Text>
        </Clickable>
      ))}
    </Row>
  )
}

export default Menu
