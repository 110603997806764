import React from 'react'
import { Row, Text, Clickable } from 'components'
import { theme } from 'lib'

const menuOptions = [
  {
    title: 'Guest Details',
    link: '',
    menu: 'guest'
  },
  {
    title: 'Trips Watched',
    link: '/tripsWatched',
    menu: 'tripsWatched'
  },
  {
    title: 'Trips Booked',
    link: '/tripsBooked',
    menu: 'tripsBooked'
  }
]
function Menu({ selectedMenu, setSelectedMenu, guestId, history }) {
  const changeMenu = ({ link, menu }) => {
    setSelectedMenu(menu)
    history.push(`/guest/${guestId}${link}`)
  }

  return (
    <Row marg='0 0 4.875rem 0'>
      {menuOptions.map(m => (
        <Clickable
          onClick={() => changeMenu({ link: m.link, menu: m.menu })}
          key={m.menu}
        >
          <Text
            QS
            bold
            size='2rem'
            lineHeight='2.8rem'
            marg='0 2.25rem 0 0'
            color={
              m.menu === selectedMenu
                ? theme.FULL_BLACK
                : theme.gray16
            }
          >
            {m.title}
          </Text>
        </Clickable>
      ))}
    </Row>
  )
}

export default Menu
