import React, { useState } from 'react'
import Login from './login'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { login } from 'actions/user'

const Index = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [checkEmailError, setCheckEmailError] = useState(false)
  const [checkPasswordError, setCheckPasswordError] = useState(false)
  const [checkLogin, setCheckLogin] = useState(false)

  const [error, setError] = useState('')

  const onEmailChange = e => {
    setError('')
    const testRegex = /^[\w-]+@([\w-]+\.)+[\w-]{2,4}$/g.test(e)
    if (e && !testRegex) {
      setCheckEmailError(true)
    } else {
      setCheckEmailError(false)
    }
    setEmail(e)
  }

  const onPasswordChange = e => {
    setError('')
    setCheckPasswordError(false)
    setPassword(e)
  }

  const handleLogin = async () => {
    dispatch({
      type: 'LOADING_BUTTON',
      payload: 'login'
    })
    setCheckLogin(true)
    let err = false
    if (email.length <= 0) {
      setCheckEmailError(true)
      err = true
      setCheckLogin(false)
      dispatch({
        type: 'LOADING_BUTTON',
        payload: null
      })
    }
    if (password.length <= 5) {
      setCheckPasswordError(true)
      err = true
      setCheckLogin(false)
      dispatch({
        type: 'LOADING_BUTTON',
        payload: null
      })
    }
    if (!err) {
      dispatch(
        login({
          data: { email: email, password: password },
          action: res => {
            if (res.status === 'success') {
              history.push('/home')
            } else {
              setError('Wrong email or password')
              setCheckLogin(false)
              dispatch({
                type: 'LOADING_BUTTON',
                payload: null
              })
            }
          }
        })
      )
    }
  }
  return (
    <Login
      email={email}
      checkEmailError={checkEmailError}
      onEmailChange={onEmailChange}
      password={password}
      checkPasswordError={checkPasswordError}
      onPasswordChange={onPasswordChange}
      checkLogin={checkLogin}
      handleLogin={handleLogin}
      error={error}
    />
  )
}

export default Index
