import React from 'react'
import { Col, Row, Text, Spinner, Input } from 'components'
import Table from './table'

const BookedTourGuests = ({
  tourBooked,
  fetchingData,
  onBookedSearchChange,
  isMobile
}) => {
  return (
    <div
      style={{
        margin:
          tourBooked && !tourBooked.totalGuests ? '8rem 4rem' : '0'
      }}
    >
      {fetchingData && !tourBooked ? (
        <Col>
          <Spinner />
        </Col>
      ) : tourBooked && !tourBooked.totalGuests ? (
        <Col centerAll>
          <Text weight='700' size={'16px'} lineHeight='40px' QS>
            No Guests found
          </Text>
        </Col>
      ) : (
        <React.Fragment>
          <Col>
            {isMobile && (
              <Row marg='0.5rem 0'>
                <Text QS size='20px' lineHeight='140%' weight='bold'>
                  {tourBooked?.tripTitle} Guest's
                </Text>
              </Row>
            )}
            {!isMobile && (
              <Row
                style={{
                  alignItems: 'flex-end',
                  marginBottom: '0.2rem'
                }}
              >
                <Text QS size='20px' lineHeight='140%' weight='bold'>
                  Search Guests
                </Text>
              </Row>
            )}
            <Row>
              <Input
                placeholder={'Search Guests'}
                wid={window.innerWidth > 998 ? '80%' : '100%'}
                borderRadius='30px'
                rounded
                onChange={onBookedSearchChange}
              />
            </Row>
          </Col>

          <Row marg='1rem 0rem'>
            <Table
              caller='desktop'
              viewer='all'
              data={tourBooked?.totalGuests}
              tourId={tourBooked?.tripId}
            />
          </Row>
        </React.Fragment>
      )}
    </div>
  )
}

export default BookedTourGuests
