import { theme } from '../lib'

const buttonStyles = props => {
  return `
    background-color: ${
      props.bgColor
        ? props.bgColor
        : props.black
        ? theme.BLACK
        : theme.WHITE
    };
    color: ${props.color || props.black ? theme.WHITE : theme.BLACK};
    height: ${props.ht || '48px'};
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    border-radius: 30px;
    padding: ${props.pad || '0 24px'};

    ${
      (props.disabled || props.loading) &&
      `
      background-color: ${theme.gray3};`
    };

    ${
      props.icon &&
      !props.text &&
      `
      background-color: ${props.big ? theme.GRAY_6 : theme.WHITE};
      width: ${props.big ? '48px' : '40px'};
      height: ${props.big ? '48px' : '40px'};
      border-radius: 50px;
      padding: 0;
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
      elevation: 8;
    `
    };

    ${
      props.shadow
        ? props.defShadow && props.defShadow
        : props.noShadow
        ? ``
        : `
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
      elevation: 8;
    `
    };
    ${
      (props.icon || props.rightIcon) &&
      props.text &&
      `flex-direction: row;`
    };
    ${
      props.bordered &&
      `border: 1px solid ${props.borderColor || theme.GRAY_5}`
    };
    ${props.marg && `margin: ${props.marg}`};
    ${props.secondary && `border-radius: 16px;`};
    ${props.textButton && `background-color: transparent;`};
    ${props.fullWid && `width: 100%;`};
    ${props.wid && `width: ${props.wid};`};
    ${props.hasRadius && `border-radius: ${props.hasRadius};`};
    ${props.bg && `background-color: ${props.bg};`};
  `
}

export default buttonStyles
