import axios from 'axios'
import env from '../lib/env'
import getToken from '../lib/getToken'
import { store } from '../store'

const API_URL = env.API_URL

// set headers
const setHeaders = async () => {
  let token = await getToken()
  if (token) {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${token}`
    }
  }
}

// global api
const Api = {
  async get(endpoint, params) {
    await setHeaders()

    try {
      const req = await axios.get(`${API_URL}${endpoint}`)
      if (req.status === 202) {
        store.dispatch({
          type: 'HANDLE_RES',
          payload: { res: req.data }
        })
        return true
      }

      return req.data
    } catch (e) {
      store.dispatch({
        type: 'HANDLE_RES',
        payload: {
          err:
            e && e.response
              ? e.response.data
              : 'Something went wrong!'
        }
      })
      return false
    }
  },
  async post(endpoint, params) {
    await setHeaders()

    try {
      const req = await axios.post(`${API_URL}${endpoint}`, params)
      if (req.status === 202) {
        store.dispatch({
          type: 'HANDLE_RES',
          payload: { res: req.data }
        })
        return true
      }

      return req.data
    } catch (e) {
      store.dispatch({
        type: 'HANDLE_RES',
        payload: {
          err:
            e && e.response
              ? e.response.data
              : 'Something went wrong!'
        }
      })

      return false
    }
  },
  async put(endpoint, params) {
    await setHeaders()

    try {
      const req = await axios.put(`${API_URL}${endpoint}`, params)
      if (req.status === 202) {
        store.dispatch({
          type: 'HANDLE_RES',
          payload: { res: req.data }
        })
        return true
      }

      return req.data
    } catch (e) {
      store.dispatch({
        type: 'HANDLE_RES',
        payload: {
          err:
            e && e.response
              ? e.response.data
              : 'Something went wrong!'
        }
      })

      return { err: e?.response?.data }
    }
  },
  async patch(endpoint, params) {
    await setHeaders()

    try {
      const req = await axios.patch(`${API_URL}${endpoint}`, params)

      if (req.status === 202) {
        store.dispatch({
          type: 'HANDLE_RES',
          payload: { res: req.data }
        })
        return true
      }

      return req.data
    } catch (e) {
      store.dispatch({
        type: 'HANDLE_RES',
        payload: {
          err:
            e && e.response
              ? e.response.data
              : 'Something went wrong!'
        }
      })
      return false
    }
  },
  async delete(endpoint) {
    await setHeaders()

    try {
      const req = await axios.delete(`${API_URL}${endpoint}`)
      if (req.status === 202) {
        store.dispatch({
          type: 'HANDLE_RES',
          payload: { res: req.data }
        })
        return true
      }
    } catch (e) {
      store.dispatch({
        type: 'HANDLE_RES',
        payload: {
          err:
            e && e.response
              ? e.response.data
              : 'Something went wrong!'
        }
      })
      return false
    }
  }
}

export default Api
