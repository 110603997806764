import React from 'react'
import { Modal, Text, Button, Row, Col } from 'components'
import { Api } from 'lib'

const ModalViewer = ({ id, name, flag, setFlag, setSuccessFlag }) => {
  const onAction = async () => {
    let status
    switch (name) {
      case 'approve':
        status = 'active'
        break
      case 'activate':
        status = 'active'
        break
      case 'reject':
        status = 'rejected'
        break
      case 'deactivate':
        status = 'deactivated'
        break
      default:
        break
    }

    const res = await Api.patch('/admin/updateUserStatus/' + id, {
      status: status
    })
    if (res) {
      setFlag()
      setSuccessFlag(name)
    } else {
      console.log('error in updating host status')
    }
  }

  return (
    <Modal visible={flag} noCloseButton wid='326px'>
      <Col style={{ alignItems: 'center' }}>
        <Text size='20px' heigt='140%' weight='700' QS>
          Are you sure you want to {name} this host?
        </Text>
        <Row
          style={{
            marginTop: '1rem',
            justifyContent: 'center'
          }}
        >
          <Button
            bordered
            borderColor='#22191A'
            text='Cancel'
            onClick={setFlag}
            style={{ marginRight: '0.5rem' }}
          />
          <Button
            bgColor={
              name === 'approve' || name === 'activate'
                ? '#22191A'
                : '#EB5757'
            }
            color='#F9F9F9'
            text={name}
            capitalize
            onClick={onAction}
          />
        </Row>
      </Col>
    </Modal>
  )
}

export default ModalViewer
