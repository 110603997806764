import React from 'react'
import {
  ResponsiveModal,
  Col,
  Text,
  Clickable,
  Icon,
  Row
} from 'components'
import { theme } from 'lib'

function MessageModal({ visible, toggleVisible, isMobile, message }) {
  return (
    <ResponsiveModal
      visible={visible}
      toggleVisible={() => toggleVisible()}
      desktopModalWid='28.375rem'
      desktopModalHt='auto'
      desktopModalBorderRadius='0.625rem'
      isMobile={isMobile}
      mobileClose={true}
    >
      <Col
        pad={isMobile ? '1.5rem 0.6875rem 0.6875rem' : '0.6875rem'}
      >
        <Row noFlex>
          <Clickable onClick={() => toggleVisible()}>
            <Icon type='close' size='0.875rem' />
          </Clickable>
        </Row>
        <Col centerAll>
          <Text
            QS
            weight='700'
            size='1.5rem'
            lineHeight='1.8rem'
            color={theme.BLACK_TRALLY}
            marg='0.625rem 0 0 0'
            wid='21.375rem'
            center
          >
            Your transaction is rejected by admin.
          </Text>
          <Text
            size='1.125rem'
            lineHeight='1.575rem'
            color={theme.BLACK_TRALLY}
            marg='2.1875rem 0 3rem'
            center
          >
            {message}
          </Text>
        </Col>
      </Col>
    </ResponsiveModal>
  )
}

export default MessageModal
