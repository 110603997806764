import React, { useMemo, useState } from 'react'
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import DraggableTableRow from './DragableTableRow'
import { StaticTableRow } from './StaticTableRow'
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core'

import styled from 'styled-components'
import { StyledTableCell, Col, Text } from 'components'
import { theme } from 'lib'

const StyledTable = styled(Table)`
  & > tbody > tr {
    border: 5px solid white;
    border-right: 0;
    border-left: 0;
  }
`

function DraggableTable({
  caller,
  selectedTrips,
  setSelectedTrips,
  onRemoveSelectedTrip
}) {
  const [activeId, setActiveId] = useState()
  const items = useMemo(
    () => selectedTrips.map(({ _id }) => _id),
    [selectedTrips]
  )

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  )

  function handleDragStart(event) {
    setActiveId(event.active.id)
  }

  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      setSelectedTrips(trips => {
        const oldIndex = items.indexOf(active.id)
        const newIndex = items.indexOf(over.id)
        return arrayMove(trips, oldIndex, newIndex)
      })
    }

    setActiveId(null)
  }

  function handleDragCancel() {
    setActiveId(null)
  }

  const selectedTrip = useMemo(() => {
    if (!activeId) {
      return null
    }
    const tripIndex = selectedTrips.findIndex(
      ({ _id }) => _id === activeId
    )
    const trip = selectedTrips[tripIndex]

    return { trip, index: tripIndex }
  }, [activeId, selectedTrips])

  const isEmpty = selectedTrips.length === 0

  return (
    <TableContainer
      component={Paper}
      style={{ height: isEmpty ? 200 : 300, overflow: 'scroll' }}
    >
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
      >
        <StyledTable
          aria-label='customized table'
          style={
            caller === 'desktop'
              ? {
                  borderCollapse: 'collapse'
                }
              : { display: 'contents' }
          }
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <StyledTableCell caller={caller}></StyledTableCell>
              <StyledTableCell caller={caller}>No.</StyledTableCell>
              <StyledTableCell caller={caller}>
                Tour Name
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Tour ID
              </StyledTableCell>
              <StyledTableCell>Host</StyledTableCell>
              <StyledTableCell caller={caller}>
                Actual Date Time
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Status
              </StyledTableCell>
              <StyledTableCell caller={caller}></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              width: '100vw',
              height: isEmpty ? '20vh' : '5vh'
            }}
          >
            {isEmpty ? (
              <TableRow>
                <StyledTableCell colSpan={12}>
                  <Col centerAll>
                    <Text
                      weight='400'
                      size={caller === 'desktop' ? '16px' : '14px'}
                      lineHeight='20px'
                      QS
                      color={theme.GRAY_91}
                    >
                      No Tours Selected, Select your tours below
                    </Text>
                  </Col>
                </StyledTableCell>
              </TableRow>
            ) : (
              <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
              >
                {selectedTrips.map((trip, index) => {
                  return (
                    <DraggableTableRow
                      caller={caller}
                      key={trip._id}
                      trip={trip}
                      index={index}
                      onRemoveSelectedTrip={onRemoveSelectedTrip}
                    />
                  )
                })}
              </SortableContext>
            )}
          </TableBody>
        </StyledTable>
        {!isEmpty && (
          <DragOverlay>
            {activeId && (
              <Table style={{ width: '100%' }}>
                <TableBody>
                  <StaticTableRow
                    caller={caller}
                    trip={selectedTrip.trip}
                    index={selectedTrip.index}
                  />
                </TableBody>
              </Table>
            )}
          </DragOverlay>
        )}
      </DndContext>
    </TableContainer>
  )
}

export default DraggableTable
