import React, { useEffect, useState } from 'react'
import {
  ResponsiveModal,
  Col,
  Row,
  Text,
  Clickable,
  Icon,
  Button
} from 'components'
import { theme } from 'lib'
import styled from 'styled-components'

const Input = styled.textarea`
  border: 1px solid ${theme.LIGHT_GRAY};
  background-color: ${theme.gray1};
  padding: 0.8125rem 0.6875rem;
  width: 25.3125rem;
  height: 11.625rem;
  border-radius: 0.5rem;
  margin: 1.5rem 0 0;
  font-family: Quicksand;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: ${theme.BLACK_TRALLY};
  &::placeholder {
    color: ${theme.gray2};
  }

  ${props =>
    props.err &&
    `border: 1px solid ${theme.DANGER};
    color: ${theme.RED};
    &::placeholder {
      color: ${theme.RED};
    }
  `}
  &:hover {
    border: 1px solid ${theme.gray16};
    ${props =>
      props.err &&
      `
      border: 1px solid ${theme.DANGER} !important;
    `}
  }

  &:focus {
    border: 1px solid ${theme.BLUE} !important;
    ${props =>
      props.err &&
      `
      border: 1px solid ${theme.DANGER} !important;
    `}
  }
`

function ApproveModal({
  visible,
  toggleVisible,
  isMobile,
  type,
  rejectApproval,
  acceptApproval,
  confirmPayout,
  cancelConfirm
}) {
  const [confirmReject, setConfirmReject] = useState({
    visible: false,
    type: 'first_time'
  })
  const [rejectionMessage, setRejectionMessage] = useState('')
  const [rejectionError, setRejectionError] = useState(false)

  useEffect(() => {
    if (!visible) {
      setConfirmReject({
        visible: false,
        type: 'first_time'
      })
      setRejectionMessage('')
      setRejectionError('')
    }
  }, [visible])

  const reject = type => {
    if (rejectionMessage && rejectionMessage.length > 0) {
      setRejectionError(false)
      rejectApproval({
        type: 'reject_' + type,
        message: rejectionMessage
      })
    } else {
      setRejectionError(true)
    }
  }

  return (
    <ResponsiveModal
      visible={visible}
      toggleVisible={() => toggleVisible()}
      desktopModalWid='28.375rem'
      desktopModalHt='auto'
      desktopModalBorderRadius='0.625rem'
      isMobile={isMobile}
      mobileClose={true}
    >
      <Col pad='0.6875rem'>
        <Row noFlex>
          <Clickable onClick={() => toggleVisible()}>
            <Icon type='close' size='0.875rem' />
          </Clickable>
        </Row>
        {confirmReject.visible ? (
          <Col noFlex centerAll>
            <Text
              QS
              weight='700'
              size='1.5rem'
              lineHeight='1.8rem'
              color={theme.BLACK_TRALLY}
              marg='0.625rem 0 0 0'
              wid='21.375rem'
              center
            >
              Are you sure to reject the cash out?
            </Text>
            <Input
              placeholder='Give reason why you reject this transaction'
              multiline
              onChange={e => {
                if (e.target.value.length <= 0) {
                  setRejectionError(true)
                } else {
                  setRejectionError(false)
                }
                setRejectionMessage(e.target.value)
              }}
              value={rejectionMessage}
              inputPad='0.8125rem 0.6875rem'
              wid='25.3125rem'
              ht='11.625rem'
              bg={theme.gray1}
              err={rejectionError}
              hasBorder={`1px solid ${theme.LIGHT_GRAY}`}
            />
            <Row centerAll marg='1.5rem 0 2rem'>
              <Button
                noShadow
                ht='3.625rem'
                wid='9.5rem'
                bordered
                borderColor={theme.RED}
                color={theme.RED}
                text='Cancel'
                onClick={() => toggleVisible()}
              />
              <Button
                noShadow
                marg='0 0 0 0.75rem'
                ht='3.625rem'
                wid='9.5rem'
                black
                text='Yes, Reject'
                action='rejectPayout'
                disabled={
                  !rejectionMessage ||
                  (rejectionMessage && rejectionMessage.length <= 0)
                }
                onClick={() => reject(confirmReject.type)}
              />
            </Row>
          </Col>
        ) : type === 'first_time_approve' ? (
          <Col noFlex centerAll>
            <Text
              QS
              weight='700'
              size='1.5rem'
              lineHeight='1.8rem'
              color={theme.BLACK_TRALLY}
              marg='0.625rem 0 0 0'
              center
            >
              Are you sure to approve this first transaction?
            </Text>
            <Text
              weight='400'
              size='0.875rem'
              lineHeight='1.225rem'
              color={theme.BLACK_TRALLY}
              marg='1.3125rem 0 0 0'
              center
            >
              Make sure all the fields in payment details are correct.
              If you approve this, we will verify the host account.
            </Text>
            <Row centerAll marg='1.5rem 0 2rem'>
              <Button
                noShadow
                ht='3.625rem'
                wid='9.5rem'
                bordered
                borderColor={theme.RED}
                color={theme.RED}
                text='Reject'
                onClick={() =>
                  setConfirmReject({
                    visible: true,
                    type: 'first_time'
                  })
                }
              />
              <Button
                noShadow
                marg='0 0 0 0.75rem'
                ht='3.625rem'
                wid='9.5rem'
                black
                text='Yes, Approve'
                action='firstTimeApproval'
                onClick={() => acceptApproval('first_time')}
              />
            </Row>
          </Col>
        ) : type === 'after_first_time_approve' ? (
          <Col noFlex centerAll>
            <Text
              QS
              weight='700'
              size='1.5rem'
              lineHeight='1.8rem'
              color={theme.BLACK_TRALLY}
              marg='0.625rem 0 0 0'
              wid='23.375rem'
              center
            >
              Are you sure to approve this cash out?
            </Text>
            <Row centerAll marg='2.625rem 0 2.25rem'>
              <Button
                noShadow
                ht='3.625rem'
                wid='9.5rem'
                bordered
                borderColor={theme.RED}
                color={theme.RED}
                text='Reject'
                onClick={() =>
                  setConfirmReject({
                    visible: true,
                    type: 'after_first_time'
                  })
                }
              />
              <Button
                noShadow
                marg='0 0 0 0.75rem'
                ht='3.625rem'
                wid='9.5rem'
                black
                action='afterFirstTimeApproval'
                text='Yes, Approve'
                onClick={() => acceptApproval('after_first_time')}
              />
            </Row>
          </Col>
        ) : (
          type === 'confirm' && (
            <Col noFlex centerAll>
              <Text
                QS
                weight='700'
                size='1.5rem'
                lineHeight='1.8rem'
                color={theme.BLACK_TRALLY}
                marg='0.625rem 0 0 0'
                wid='23.375rem'
                center
              >
                Confirm the cash out to this host?
              </Text>
              <Row centerAll marg='2.625rem 0 2.25rem'>
                <Button
                  noShadow
                  ht='3.625rem'
                  wid='9.5rem'
                  bordered
                  borderColor={theme.RED}
                  color={theme.RED}
                  text='Cancel'
                  action='cancelConfirm'
                  onClick={() => cancelConfirm()}
                />
                <Button
                  noShadow
                  marg='0 0 0 0.75rem'
                  ht='3.625rem'
                  wid='9.5rem'
                  black
                  action='confirmPayout'
                  text='Yes, Confirm'
                  onClick={() => confirmPayout()}
                />
              </Row>
            </Col>
          )
        )}
      </Col>
    </ResponsiveModal>
  )
}

export default ApproveModal
