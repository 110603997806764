import React, { useCallback, useEffect, useState } from 'react'
import { Api } from 'lib'
import DesktopPayout from './desktopPayout'
import MobilePayout from './mobilePayout'
import { Toast } from 'components'

const Payout = () => {
  const [screenDimensions, setScreenDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [fetchingData, setFetchingData] = useState(false)
  const [selectPayout, setSelectPayout] = useState(false)
  const [selectedPayouts, setSelectedPayouts] = useState([])
  const [checked, setChecked] = useState([])
  const [activeButton, setActiveButton] = useState('Requested')
  const [requestedUsers, setRequestedUsers] = useState([])
  const [requestedPageNo, setRequestedPageNo] = useState(1)
  const [allUsers, setAllUsers] = useState([])
  const [allPageNo, setAllPageNo] = useState(1)
  const [search, setSearch] = useState('')

  const [showToast, setShowToast] = useState({
    show: false,
    message: '',
    type: 'success' //sucees, error, warning, info
  })

  useEffect(() => {
    let timeout
    const handleResize = () => {
      clearTimeout(timeout)

      timeout = setTimeout(() => {
        setScreenDimensions({
          width: window.innerWidth,
          height: window.innerHeight
        })
      }, 400)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    async function fetchData() {
      setFetchingData(true)
      const res = await Api.get(
        '/admin/getAllPayouts/' + allPageNo + '?search=' + search
      )
      if (res) {
        let tempData =
          res && res.docs && res.docs.length > 0
            ? res.docs.sort(function (a, b) {
                var dateA = new Date(
                  a.paymentHistory.createdAt
                ).getTime()
                var dateB = new Date(
                  b.paymentHistory.createdAt
                ).getTime()
                return dateA < dateB ? 1 : -1 // ? -1 : 1 for ascending/increasing order
              })
            : []
        setAllUsers({
          ...res,
          docs: tempData
        })
      } else {
        console.log('error in fetching payouts')
      }
      setFetchingData(false)
    }
    fetchData()
  }, [allPageNo, search])

  const fetchRequestedData = useCallback(async () => {
    setFetchingData(true)

    const res = await Api.get(
      '/admin/getAllPayouts/' +
        requestedPageNo +
        '?status=pendingAll&search=' +
        search
    )
    if (res) {
      let tempData =
        res && res.docs && res.docs.length > 0
          ? res.docs.sort(function (a, b) {
              var dateA = new Date(
                a.paymentHistory.createdAt
              ).getTime()
              var dateB = new Date(
                b.paymentHistory.createdAt
              ).getTime()
              return dateA < dateB ? 1 : -1 // ? -1 : 1 for ascending/increasing order
            })
          : []
      setRequestedUsers({ ...res, docs: tempData })
    } else {
      console.log('error in fetching payouts')
    }
    setFetchingData(false)
  }, [requestedPageNo, search])

  useEffect(() => {
    fetchRequestedData()
  }, [requestedPageNo, search, fetchRequestedData])

  const onSearchChange = ({ target: { value } }) => {
    setSelectedPayouts([])
    setSelectPayout(false)
    setChecked([])
    setSearch(value)
  }
  const onAllPageChange = page => {
    setAllPageNo(page)
  }

  const onRequestedPageChange = page => {
    setRequestedPageNo(page)
  }

  const toggleSelectPayout = () => {
    setSelectPayout(!selectPayout)
  }

  const selectAll = checked => {
    if (!checked) {
      setSelectedPayouts([])
      setChecked([])
    } else {
      let verifiedHosts =
        requestedUsers.docs &&
        requestedUsers.docs.filter(
          ru =>
            ru.hostDetails &&
            ru.hostDetails.verified &&
            ru.hostDetails.verified.state &&
            ru.hostDetails.verified.state === 'verified'
        )
      setSelectedPayouts(verifiedHosts)
      setChecked(Array.from(Array(verifiedHosts.length).keys()))
    }
  }

  const selectSinglePayout = ({ host, index }) => {
    const prevHosts = selectedPayouts
    let newHosts = []
    if (
      prevHosts.some(
        h => h.paymentHistory._id === host.paymentHistory._id
      )
    ) {
      newHosts = prevHosts.filter(
        h => h.paymentHistory._id !== host.paymentHistory._id
      )
      setChecked(checked.filter(c => c !== index))
    } else {
      newHosts = [...prevHosts, host]
      setChecked([...checked, index])
    }

    setSelectedPayouts(newHosts)
  }

  const approveBulkPayouts = async () => {
    if (selectedPayouts && selectedPayouts.length > 0) {
      const res = await Api.patch('/admin/approveBulkPayouts/', {
        payouts: selectedPayouts.map(h => {
          return {
            userId: h._id,
            paymentId: h.paymentHistory._id,
            amount: h.paymentHistory.amount
          }
        }),
        status: 'successful_request'
      })
      if (res) {
        setSelectedPayouts([])
        setSelectPayout(false)
        setChecked([])
        fetchRequestedData()
        setShowToast({
          show: true,
          message: `Approved payout of ${selectedPayouts.length} hosts`,
          type: 'success'
        })
      } else {
        setShowToast({
          show: true,
          message: `There was an error approving payout of hosts`,
          type: 'error'
        })
      }
    } else {
      setShowToast({
        show: true,
        message: `Please select a host to approve`,
        type: 'error'
      })
    }
  }

  const dismissToast = () => {
    setShowToast({
      show: false,
      message: '',
      type: ''
    })
  }

  const changeFilter = text => {
    setSelectedPayouts([])
    setSelectPayout(false)
    setChecked([])
    setActiveButton(text)
  }
  return (
    <React.Fragment>
      {screenDimensions.width > 720 ? (
        <React.Fragment>
          <div style={{ marginTop: '80px' }}>
            <DesktopPayout
              activeButton={activeButton}
              changeFilter={changeFilter}
              allUsers={allUsers}
              onAllPageChange={onAllPageChange}
              requestedUsers={requestedUsers}
              onRequestedPageChange={onRequestedPageChange}
              search={search}
              onSearchChange={onSearchChange}
              fetchingData={fetchingData}
              selectPayout={selectPayout}
              toggleSelectPayout={toggleSelectPayout}
              selectSinglePayout={selectSinglePayout}
              selectAll={selectAll}
              checked={checked}
              approveBulkPayouts={approveBulkPayouts}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <MobilePayout
            activeButton={activeButton}
            changeFilter={changeFilter}
            allUsers={allUsers}
            onAllPageChange={onAllPageChange}
            requestedUsers={requestedUsers}
            onRequestedPageChange={onRequestedPageChange}
            search={search}
            onSearchChange={onSearchChange}
            fetchingData={fetchingData}
            selectPayout={selectPayout}
            toggleSelectPayout={toggleSelectPayout}
            selectSinglePayout={selectSinglePayout}
            selectAll={selectAll}
            checked={checked}
            approveBulkPayouts={approveBulkPayouts}
          />
        </React.Fragment>
      )}
      <Toast
        msg={showToast.message}
        type={showToast.type}
        show={showToast.show}
        dismissToast={dismissToast}
      />
    </React.Fragment>
  )
}

export default Payout
