import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import Close from 'assets/icons/close.png'
const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const OuterContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 5000;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Overlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5000;
`

const Container = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  width: ${props => (props.wid ? props.wid : '100vw')};
  height: ${props => (props.ht ? props.ht : '100vh')};
  z-index: 5001;
`

const ModalWrapper = styled.div`
  position: fixed;
  box-shadow: 0px 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  background: white;
  width: ${props =>
    props.wid ? `${props.wid}` : props.freeWid ? 'unset' : '600px'};
  height: ${props => props.ht && `${props.ht}`};
  border-radius: ${props =>
    props.borderRadius && `${props.borderRadius}`};
  padding: ${props => !props.noModalPadding && `20px 40px`};
  animation: ${fadeIn} 0.3s linear;
  overflow-y: auto;
  text-align: center;
`

const CloseButton = styled.div`
  position: absolute;
  right: 25px;
  top: 25px;
  cursor: pointer;
`

const Modal = ({
  visible,
  children,
  closeModal,
  freeWid,
  wid,
  ht,
  borderRadius,
  noCloseButton,
  noModalPadding,
  clickOnOutside
}) => {
  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress)

    return () =>
      document.removeEventListener('keydown', handleKeyPress)
  })

  const handleKeyPress = e => {
    if (!noCloseButton) {
      if (e.keyCode !== 27 || !visible) return

      closeModal()
    }
  }

  if (!visible) return null

  return (
    <React.Fragment>
      <OuterContainer>
        <Overlay
          modalOpen={visible}
          onClick={() => {
            clickOnOutside && clickOnOutside()
            closeModal && closeModal()
          }}
        />
        <Container wid={wid} ht={ht}>
          <ModalWrapper
            modalOpen={visible}
            freeWid={freeWid}
            wid={wid}
            ht={ht}
            borderRadius={borderRadius}
            noModalPadding={noModalPadding}
          >
            {!noCloseButton && (
              <CloseButton onClick={closeModal}>
                <img src={Close} width={10} alt='close' />
              </CloseButton>
            )}
            {children}
          </ModalWrapper>
        </Container>
      </OuterContainer>
    </React.Fragment>
  )
}

export default Modal
