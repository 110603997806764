import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Icon, Text } from './'
import { theme } from 'lib'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`

const ToastAbs = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 20px;
`

const ToastDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: '100%';
  animation: ${props => (props.fade === 'in' ? fadeIn : fadeOut)} 0.3s
    ease;
`
const ToastContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: ${props => (props.color ? props.color : theme.BLACK)};
  border-radius: 10px;
  width: 375px;
`

const StyledIcon = styled(Icon)`
  ${props =>
    props.toastType &&
    props.toastType === 'success' &&
    'filter: brightness(10);'}
`

const toastTypes = {
  success: {
    containerColor: theme.GREEN,
    iconType: 'blue-tick',
    textColor: theme.WHITE
  },
  error: {
    containerColor: '#f9cccc',
    iconType: 'close-red-circle',
    textColor: theme.RED
  },
  warning: {
    containerColor: '#fff2cc',
    iconType: 'warning',
    textColor: '#ffc000'
  },
  info: {
    containerColor: '#edf0fd',
    iconType: 'info-blue',
    textColor: '#4169e1'
  }
}
function Toast({ msg, type, show, dismissToast }) {
  const [fade, setFade] = useState('in')
  useEffect(() => {
    if (show && fade === 'in') {
      if (fade === 'out') setFade('in')
      setTimeout(() => {
        setFade('out')
      }, 2000)
    }
  }, [show, dismissToast, fade])

  const settings = toastTypes[type ? type : 'info']

  return (
    <React.Fragment>
      {show && (
        <ToastAbs>
          <ToastDiv
            fade={fade}
            onAnimationEnd={() => {
              if (fade === 'out' && show) {
                dismissToast()
                setFade('in')
              }
            }}
          >
            <ToastContainer color={settings.containerColor}>
              <StyledIcon
                type={settings.iconType}
                size='20px'
                marg='0 0.5rem 0 0'
                toastType={type}
              />
              <Text
                color={settings.textColor}
                size='16px'
                height='22px'
                weight='700'
                QS
              >
                {msg}
              </Text>
            </ToastContainer>
          </ToastDiv>
        </ToastAbs>
      )}
    </React.Fragment>
  )
}

export default Toast
