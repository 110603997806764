import React from 'react'
import styled from 'styled-components'
import { theme } from '../../../main/lib'

const FilterButtonStyle = styled.button`
  font-family: Quicksand;
  font-weight: bold;
  font-size: 16px;
  line-height: 140%;
  color: ${props => (props.active ? theme.BLUE : theme.gray15)};
  background: ${props =>
    props.active ? theme.LIGHT_BLUE : 'transparent'};
  padding: 12px 24px;
  border-radius: 24px;
  cursor: pointer;
  margin: 0.5rem 0rem;
`
const FilterButton = ({ text, activeButton, changeFilter }) => {
  return (
    <FilterButtonStyle
      active={activeButton === text ? true : false}
      onClick={() => changeFilter(text)}
    >
      {text}
    </FilterButtonStyle>
  )
}

export default FilterButton
