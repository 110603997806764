import React, { useCallback, useEffect, useState } from 'react'
import { Api, env } from 'lib'
import DesktopCategories from './DesktopCategories'
import MobileHosts from '../dashboard/mobileHosts'
import { Toast } from 'components'
import { useSelector } from 'react-redux'

const Categories = () => {
  const [fetchingData, setFetchingData] = useState(false)
  const [selectHost, setSelectHost] = useState(false)
  const [selectedHosts, setSelectedHosts] = useState([])
  const [checked, setChecked] = useState([])
  const [activeButton, setActiveButton] = useState('Requested')
  const [requestedUsers, setRequestedUsers] = useState([])
  const [requestedPageNo, setRequestedPageNo] = useState(1)
  const [allUsers, setAllUsers] = useState([])
  const [allPageNo, setAllPageNo] = useState(1)
  const [search, setSearch] = useState('')

  const [showToast, setShowToast] = useState({
    show: false,
    message: '',
    type: 'success' //sucees, error, warning, info
  })
  const { screenDimensions } = useSelector(state => state.appState)

  useEffect(() => {
    async function fetchData() {
      setFetchingData(true)
      const res = await Api.get(
        '/admin/getAllUsers/' + allPageNo + '?search=' + search
      )
      if (res) {
        setAllUsers({
          ...res,
          docs: res.docs && res.docs.length > 0 ? res.docs : []
        })
      } else {
        console.log('error in fetching users')
      }
      setFetchingData(false)
    }
    fetchData()
  }, [allPageNo, search])

  const fetchRequestedData = useCallback(async () => {
    setFetchingData(true)

    const res = await Api.get(
      '/admin/getAllUsers/' +
        requestedPageNo +
        '?status=pendingAll&search=' +
        search
    )
    if (res) {
      setRequestedUsers({
        ...res,
        docs:
          res.docs && res.docs.length > 0 ? res.docs.reverse() : []
      })
    } else {
      console.log('error in fetching users')
    }
    setFetchingData(false)
  }, [requestedPageNo, search])

  useEffect(() => {
    fetchRequestedData()
  }, [requestedPageNo, search, fetchRequestedData])

  const onSearchChange = ({ target: { value } }) => {
    setSelectedHosts([])
    setSelectHost(false)
    setChecked([])
    setSearch(value)
  }
  const onAllPageChange = page => {
    setAllPageNo(page)
  }

  const onRequestedPageChange = page => {
    setRequestedPageNo(page)
  }

  const toggleSelectHost = () => {
    setSelectHost(!selectHost)
  }

  const selectAll = checked => {
    if (!checked) {
      setSelectedHosts([])
      setChecked([])
    } else {
      setSelectedHosts(requestedUsers.docs)
      setChecked(Array.from(Array(requestedUsers.docs.length).keys()))
    }
  }

  const selectSingleHost = ({ host, index }) => {
    const prevHosts = selectedHosts
    let newHosts = []
    if (prevHosts.some(h => h._id === host._id)) {
      newHosts = prevHosts.filter(h => h._id !== host._id)
      setChecked(checked.filter(c => c !== index))
    } else {
      newHosts = [...prevHosts, host]
      setChecked([...checked, index])
    }

    setSelectedHosts(newHosts)
  }

  const approveBulkHosts = async () => {
    if (selectedHosts && selectedHosts.length > 0) {
      const res = await Api.patch('/admin/approveBulkHosts/', {
        hosts: selectedHosts.map(h => h._id),
        status: 'active'
      })
      if (res) {
        setSelectedHosts([])
        setSelectHost(false)
        setChecked([])
        fetchRequestedData()
        setShowToast({
          show: true,
          message: `Approved ${selectedHosts.length} hosts`,
          type: 'success'
        })
      } else {
        setShowToast({
          show: true,
          message: `There was an error approving hosts`,
          type: 'error'
        })
      }
    } else {
      setShowToast({
        show: true,
        message: `Please select a host to approve`,
        type: 'error'
      })
    }
  }

  const dismissToast = () => {
    setShowToast({
      show: false,
      message: '',
      type: ''
    })
  }

  const changeFilter = text => {
    setSelectedHosts([])
    setSelectHost(false)
    setChecked([])
    setActiveButton(text)
  }
  return (
    <React.Fragment>
      {screenDimensions.width > env.SCREEN_WIDTH ? (
        <React.Fragment>
          <div style={{ marginTop: '80px' }}>
            <DesktopCategories
              activeButton={activeButton}
              changeFilter={changeFilter}
              allUsers={allUsers}
              onAllPageChange={onAllPageChange}
              requestedUsers={requestedUsers}
              onRequestedPageChange={onRequestedPageChange}
              search={search}
              onSearchChange={onSearchChange}
              fetchingData={fetchingData}
              selectHost={selectHost}
              toggleSelectHost={toggleSelectHost}
              selectSingleHost={selectSingleHost}
              selectAll={selectAll}
              checked={checked}
              approveBulkHosts={approveBulkHosts}
            />
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <MobileHosts
            activeButton={activeButton}
            changeFilter={changeFilter}
            allUsers={allUsers}
            onAllPageChange={onAllPageChange}
            requestedUsers={requestedUsers}
            onRequestedPageChange={onRequestedPageChange}
            search={search}
            onSearchChange={onSearchChange}
            fetchingData={fetchingData}
            selectHost={selectHost}
            toggleSelectHost={toggleSelectHost}
            selectSingleHost={selectSingleHost}
            selectAll={selectAll}
            checked={checked}
            approveBulkHosts={approveBulkHosts}
          />
        </React.Fragment>
      )}
      <Toast
        msg={showToast.message}
        type={showToast.type}
        show={showToast.show}
        dismissToast={dismissToast}
      />
    </React.Fragment>
  )
}

export default Categories
