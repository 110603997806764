export const closestScheduleDate = dates => {
  const temp = dates.map(d =>
    Math.abs(new Date() - new Date(d.start).getTime())
  )
  const idx = temp.indexOf(Math.min(...temp))
  return dates[idx]
}

export const checkStatus = trip => {
  if (trip.live) return 'Ongoing'
  if (trip.dates && trip.dates.length === 0) return 'Coming Soon'

  let closestSchedule = closestScheduleDate(trip.dates)

  if (
    trip.dates &&
    trip.dates.length >= 1 &&
    (closestSchedule.endedAt ||
      new Date(closestSchedule.end) < new Date())
  )
    return 'Past'

  if (
    trip.dates &&
    trip.dates.length >= 1 &&
    !closestSchedule.startedAt
  )
    return 'Scheduled'

  return trip.status
}
