import React, { useEffect, useState } from 'react'
import { Api, env } from 'lib'
import DesktopTours from './desktopTours'
import MobileTours from './mobileTours'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { staticPastTours } from './components/staticPastTours'

function Tours() {
  const params = useParams()
  const [fetchingData, setFetchingData] = useState(false)
  const [allTours, setAllTours] = useState([])
  const [allPageNo, setAllPageNo] = useState(1)
  const [search, setSearch] = useState('')
  const [selectedMenu, setSelectedMenu] = useState(
    params.menu ? params.menu : 'live'
  )
  const [refreshData, setRefreshData] = useState(false)
  const { screenDimensions } = useSelector(state => state.appState)

  const [allPastTours, setAllPastTours] = useState([])
  const [allPastPageNo, setAllPastPageNo] = useState(0)

  useEffect(() => {
    async function fetchData() {
      setFetchingData(true)
      const res = await Api.get(
        `/admin/getAllTours/${false}/` +
          allPageNo +
          '/' +
          selectedMenu +
          '?search=' +
          search
      )
      if (res) {
        setAllTours({
          ...res,
          docs:
            res.docs && res.docs.length > 0 ? res.docs.reverse() : []
        })
      } else {
        console.log('error in fetching tours')
      }
      setFetchingData(false)
    }
    fetchData()
    
  }, [allPageNo, search, selectedMenu, refreshData, allPastPageNo])

  const onPastAllPageChange = page => {
    setAllPastPageNo(page - 1)
  }

  const onSearchChange = ({ target: { value } }) => {
    setSearch(value)
  }
  const onAllPageChange = page => {
    setAllPageNo(page)
  }

  const toggleRefresh = () => {
    setRefreshData(prev => !prev)
  }

  return (
    <React.Fragment>
      {screenDimensions.width > env.SCREEN_WIDTH ? (
        <div style={{ marginTop: '80px' }}>
          <DesktopTours
            fetchingData={fetchingData}
            allTours={allTours}
            onAllPageChange={onAllPageChange}
            onSearchChange={onSearchChange}
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            toggleRefresh={toggleRefresh}
            allPastTours={allTours}
            onPastAllPageChange={onPastAllPageChange}
          />
        </div>
      ) : (
        <MobileTours
          fetchingData={fetchingData}
          allTours={allTours}
          onAllPageChange={onAllPageChange}
          onSearchChange={onSearchChange}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          toggleRefresh={toggleRefresh}
          allPastTours={allTours}
          onPastAllPageChange={onPastAllPageChange}
        />
      )}
    </React.Fragment>
  )
}

export default Tours
