import React, { useState, useEffect, useMemo } from 'react'
import { Col, Row, Icon, Text, Spinner } from 'components'
import styled from 'styled-components'
import { Api, theme } from 'lib'
import StripeStatus from './stripeStatus'
import StripeBlueLogo from 'assets/stripe-logo-blue.png'

const StripeLogo = styled.img`
  width: 4.938rem !important;
  object-fit: contain;
  margin: 0 0.75rem 0 0;
`

function StripeConnect({ user }) {
  const [notCompleted, setNotCompleted] = useState(false)
  const [loading, setLoading] = useState(false)
  const [stripeInfo, setStripeInfo] = useState(null)

  const connected = useMemo(() => {
    return (
      user && user.stripe && user.stripe.onboarded && !notCompleted
    )
  }, [user, notCompleted])

  useEffect(() => {
    async function getStripeDetails() {
      const { stripeDetails } = await Api.get(
        `/users/getStripeAccount/${user.stripe.id}/all`
      )

      let externalAccounts =
        stripeDetails && stripeDetails.external_accounts

      if (
        externalAccounts &&
        externalAccounts.data &&
        externalAccounts.data.length > 0
      ) {
        let firstAccount = externalAccounts.data[0]
        setNotCompleted(false)
        setStripeInfo({
          country: stripeDetails.country,
          bankName: firstAccount.bank_name,
          bankCode: firstAccount.routing_number,
          accountNumber: `**** ${firstAccount.last4}`,
          accountName: firstAccount.account_holder_name
            ? firstAccount.account_holder_name
            : 'Unknown Name'
        })
      } else {
        setNotCompleted(true)
        setStripeInfo(null)
      }
      setLoading(false)
    }

    if (
      user &&
      user.stripe &&
      user.stripe.id &&
      user.stripe.id.length > 0
    ) {
      setLoading(true)
      getStripeDetails()
    }
    return () => {
      setLoading(false)
    }
  }, [user])

  return (
    <Col
      hasBorder={`0.063rem solid ${theme.gray18}`}
      bg={theme.gray19}
      ht='13.75rem'
      pad='1.5rem'
      noFlex
      hasRadius='0.625rem'
      centerAll={loading}
    >
      {loading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          <Row noFlex center>
            <StripeLogo src={StripeBlueLogo} alt='stripe blue logo' />
            <StripeStatus connected={connected} />
          </Row>
          {connected && (
            <Row noFlex center marg='3rem 0 0 0'>
              <Col
                noFlex
                pad='1rem'
                hasRadius='0.313rem'
                bg={theme.gray20}
                centerAll
              >
                <Icon type='bank' size='4rem' />
              </Col>
              <Col noFlex marg='0 0 0 1rem'>
                <Text QS weight='700' size='1rem' lineHeight='1.4rem'>
                  {stripeInfo?.accountName}
                </Text>
                <Row marg='0.75rem 0 0 0'>
                  <Text
                    QS
                    weight='400'
                    size='0.75rem'
                    lineHeight='1.05rem'
                  >
                    {stripeInfo?.bankName}
                  </Text>
                  <Col
                    marg='0 0 0 0.375rem'
                    bg={theme.gray20}
                    hasRadius='0.313rem'
                    noFlex
                    pad='0.125rem 0.25rem'
                  >
                    <Text
                      QS
                      weight='700'
                      size='0.75rem'
                      lineHeight='0.9rem'
                      color={theme.gray21}
                    >
                      {stripeInfo?.country}
                    </Text>
                  </Col>
                </Row>
                <Row marg='0.75rem 0 0 0'>
                  <Text
                    QS
                    weight='400'
                    size='0.75rem'
                    lineHeight='1.05rem'
                  >
                    {stripeInfo?.bankCode}
                  </Text>

                  <Text
                    QS
                    weight='400'
                    size='0.75rem'
                    lineHeight='1.05rem'
                    marg='0 0 0 0.75rem'
                  >
                    {stripeInfo?.accountNumber}
                  </Text>
                </Row>
              </Col>
            </Row>
          )}
        </React.Fragment>
      )}
    </Col>
  )
}

export default StripeConnect
