import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { differenceInHours, subDays } from 'date-fns'
import { Row, Col, Text, Clickable } from '../'
import { theme } from 'lib'
import { markNotifsSeen } from 'actions/user'
import Item from './Item'
import styled from 'styled-components'

const StyledCol = styled(Col)`
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: ${theme.GRAY_77};
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: ${theme.GRAY_77};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${theme.gray11};
  }
`

function Notifications({
  MenuWrapper,
  menuVisible,
  notificationRef
}) {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  // useEffect(() => {
  //   if (!user) return
  //   dispatch(markNotifsSeen())
  // }, [])

  const markNotificationsSeen = () => {
    dispatch(markNotifsSeen())
  }

  // if (!user) return <AuthProfile navigation={navigation} />

  const notifications =
    user.notifications?.length > 1
      ? user?.notifications?.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      : user.notifications || []

  const now = new Date()
  const weekEnd = subDays(now, 7)
  const monthEnd = subDays(now, 23)

  const today = {
    title: 'Today',
    data: notifications.filter(
      x => differenceInHours(now, new Date(x.updatedAt)) < 24
    )
  }

  const thisWeek = {
    title: 'This Week',
    data: notifications.filter(
      x =>
        new Date(x.updatedAt) < subDays(now, 1) &&
        new Date(x.updatedAt) > weekEnd
    )
  }

  const thisMonth = {
    title: 'This Month',
    data: notifications.filter(
      x =>
        new Date(x.updatedAt) < subDays(now, 7) &&
        new Date(x.updatedAt) > monthEnd
    )
  }

  const earlier = {
    title: 'Earlier',
    data: notifications.filter(
      x => new Date(x.createdAt) < subDays(now, 23)
    )
  }

  return (
    <MenuWrapper
      className='menuWrapper'
      visible={menuVisible === 'notifications'}
      ref={notificationRef}
    >
      <Row
        ht='60.11px'
        pad='0px 23px 0px 13px'
        between
        center
        borderBottom={`1px solid ${theme.gray4}`}
      >
        <Text
          weight='700'
          QS
          size='15px'
          lineHeight='20px'
          spacing='0.3px'
        >
          Notifications
        </Text>
        <Clickable onClick={() => markNotificationsSeen()}>
          <Text
            weight='600'
            size='12px'
            lineHeight='14.32px'
            spacing='0.5px'
            textDecorationLine='underline'
            color={`${theme.BLUE_1}`}
          >
            Read all
          </Text>
        </Clickable>
      </Row>
      {today.data?.length < 0 &&
      thisWeek.data?.length < 0 &&
      thisMonth.data?.length < 0 &&
      earlier.data?.length < 0 ? (
        <Row
          centerAll
          borderBottom={`1px solid ${theme.gray4}`}
          ht='54px'
          wid='375px'
        >
          <Text weight='600' size='15px' lineHeight='14.32px'>
            No notifications
          </Text>
        </Row>
      ) : (
        <StyledCol noFlex>
          {today.data && today.data.length > 0 && (
            <NotificationRow title='TODAY' data={today.data} />
          )}
          {thisWeek.data && thisWeek.data.length > 0 && (
            <NotificationRow title='THIS WEEK' data={thisWeek.data} />
          )}
          {thisMonth.data && thisMonth.data.length > 0 && (
            <NotificationRow
              title='THIS MONTH'
              data={thisMonth.data}
            />
          )}
          {earlier.data && earlier.data.length > 0 && (
            <NotificationRow title='EARLIER' data={earlier.data} />
          )}
        </StyledCol>
      )}
    </MenuWrapper>
  )
}

const NotificationRow = ({ title, data }) => {
  return (
    <React.Fragment>
      <Row
        pad='28px 0px 15px 23px'
        flexEnd
        borderBottom={`1px solid ${theme.gray4}`}
        ht='54px'
        wid='375px'
      >
        <Text
          h6
          weight='700'
          size='12px'
          lineHeight='14.32px'
          color={`${theme.gray9}`}
        >
          {title}
        </Text>
      </Row>
      {data.map(item => (
        <Item data={item} key={item?._id} />
      ))}
    </React.Fragment>
  )
}

export default Notifications
