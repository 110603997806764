import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Col, Text, Icon, Spinner } from 'components'
import Menu from './components/menu'
import DesktopHostMenu from './components/menuPages/desktopHostMenu'
import DesktopPaymentMenu from './components/menuPages/desktopPaymentMenu'
import DesktopCompletedMenu from './components/menuPages/desktopCompletedMenu'
import DesktopScheduledMenu from './components/menuPages/desktopScheduledMenu'

const Container = styled.div`
  padding: ${props =>
    props.smallScreen ? '4rem 1rem' : '4rem 8rem'};
`

const ToastDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: '100%';
`
const ToastContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background: #edf0fd;
  border-radius: 10px;
  width: 375px;
`

const DesktopView = ({
  toggleReset,
  selectedMenu,
  setSelectedMenu,
  windowWidth,
  id,
  history,
  host,
  approveFlag,
  setApproveFlag,
  onSuccess,
  rejectFlag,
  setRejectFlag,
  deactivateFlag,
  setDeactivateFlag,
  activateFlag,
  setActivateFlag,
  pendingHasTrips,
  goToTrialTrip,
  successFlag,
  loading,
  setModal,
  setMessageModal,
  stripeSupported,
  onAllPageChange,
  onSearchChange,
  completedTours,
  fetchingData
}) => {
  useEffect(() => {
    if (selectedMenu && selectedMenu === 'account' && id) {
      history.push(`/view/${id}/payment`)
    }
  }, [selectedMenu, id, history])

  return (
    <React.Fragment>
      {host ? (
        <Container smallScreen={windowWidth <= 960}>
          <Menu
            selectedMenu={selectedMenu}
            setSelectedMenu={setSelectedMenu}
            hostId={id}
            history={history}
          />

          {selectedMenu && selectedMenu === 'host' ? (
            <DesktopHostMenu
              host={host}
              toggleReset={toggleReset}
              id={id}
              approveFlag={approveFlag}
              setApproveFlag={setApproveFlag}
              onSuccess={onSuccess}
              rejectFlag={rejectFlag}
              setRejectFlag={setRejectFlag}
              deactivateFlag={deactivateFlag}
              setDeactivateFlag={setDeactivateFlag}
              activateFlag={activateFlag}
              setActivateFlag={setActivateFlag}
              pendingHasTrips={pendingHasTrips}
              goToTrialTrip={goToTrialTrip}
            />
          ) : selectedMenu && selectedMenu === 'scheduled' ? (
            <DesktopScheduledMenu id={id} />
          ) : selectedMenu && selectedMenu === 'completed' ? (
            <DesktopCompletedMenu
              onAllPageChange={onAllPageChange}
              onSearchChange={onSearchChange}
              completedTours={completedTours}
              fetchingData={fetchingData}
              id={id}
            />
          ) : (
            <DesktopPaymentMenu
              windowWidth={windowWidth}
              host={host}
              setModal={setModal}
              setMessageModal={setMessageModal}
              stripeSupported={stripeSupported}
            />
          )}
          <React.Fragment>
            {successFlag !== '' ? (
              <ToastDiv>
                <ToastContainer>
                  <Icon
                    type='blue-tick'
                    size='20px'
                    marg='0 0.5rem 0 0'
                  />
                  <Text
                    color='#2F80ED'
                    size='16px'
                    height='22px'
                    weight='700'
                    QS
                  >
                    Host has been successfully {successFlag}
                  </Text>
                </ToastContainer>
              </ToastDiv>
            ) : null}
          </React.Fragment>
        </Container>
      ) : loading ? (
        <Col centerAll wid='100vw' ht='100vh'>
          <Spinner />
        </Col>
      ) : (
        <Col centerAll wid='100vw' ht='100vh'>
          <Text QS weight='700' size='18px'>
            Host not found
          </Text>
        </Col>
      )}
    </React.Fragment>
  )
}

export default DesktopView
