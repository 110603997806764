import React from 'react'
import { Row, Clickable } from 'components'
import { theme } from 'lib'

function MobileHeader({
  Left,
  leftClick,
  Middle,
  middleClick,
  Right,
  rightClick,
  fixed,
  pad,
  wid,
  ht,
  marg
}) {
  const LeftComp = () => {
    return Left ? Left : <React.Fragment></React.Fragment>
  }
  const MiddleComp = () => {
    return Middle ? Middle : <React.Fragment></React.Fragment>
  }
  const RightComp = () => {
    return Right ? Right : <React.Fragment></React.Fragment>
  }
  return (
    <Row
      noFlex
      marg={marg ? marg : fixed ? '0' : '0'}
      wid={wid ? wid : '100%'}
      ht={ht ? ht : '3.875rem'}
      fixed={fixed}
    >
      <Row
        noFlex
        center
        between
        style={
          fixed
            ? {
                position: 'fixed',
                height: 71,
                backgroundColor: theme.WHITE,
                top: 0,
                zIndex: 999,
                width: wid ? wid : '100%',
                padding: pad ? pad : '0'
              }
            : {
                width: wid ? wid : '100vw'
              }
        }
      >
        <Clickable onClick={leftClick}>
          <LeftComp />
        </Clickable>
        <Clickable onClick={middleClick}>
          <MiddleComp />
        </Clickable>
        <Clickable onClick={rightClick}>
          <RightComp />
        </Clickable>
      </Row>
    </Row>
  )
}

export default MobileHeader
