import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles
} from '@material-ui/core'
import { Col, Text, Link } from 'components'
import PaginationView from './PaginationView'

const StyledTableCell = withStyles(theme => ({
  head: {
    fontSize: 14,
    lineHeight: '12px',
    fontWeight: 'bold',
    backgroundColor: '#F4F3F3',
    color: '#22191A',
    width: props =>
      props.width
        ? props.width
        : props.caller === 'mobile'
        ? '20vw'
        : ''
  },
  body: {
    fontSize: 12
  }
}))(TableCell)

const CustomTable = ({ caller, handler, data, isWatched }) => {
  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table
          aria-label='customized table'
          style={caller === 'desktop' ? {} : { display: 'contents' }}
        >
          <TableHead>
            <TableRow>
              <StyledTableCell caller={caller}>ID</StyledTableCell>
              <StyledTableCell caller={caller}>
                Guest Name
              </StyledTableCell>
              <StyledTableCell caller={caller}>
                Guest User ID
              </StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>

              {isWatched && (
                <React.Fragment>
                  <StyledTableCell caller={caller}>
                    Watch Date Time
                  </StyledTableCell>
                  <StyledTableCell caller={caller}>
                    Watch Duration
                  </StyledTableCell>
                </React.Fragment>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {data && data.docs.length > 0 ? (
              <React.Fragment>
                {data.docs.map((guest, index) => {
                  return (
                    <TableRow key={index}>
                      <StyledTableCell
                        component='th'
                        scope='row'
                        caller={caller}
                      >
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell caller={caller}>
                        <Link
                          url={`/profile/${guest.interested._id}`}
                        >
                          {guest.interested.name}
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell caller={caller}>
                        {guest.interested._id}
                      </StyledTableCell>
                      <StyledTableCell>
                        {guest.interested.email}
                      </StyledTableCell>
                    </TableRow>
                  )
                })}
              </React.Fragment>
            ) : (
              <TableRow>
                <StyledTableCell colSpan={9}>
                  <Col centerAll>
                    <Text
                      weight='700'
                      size={caller === 'desktop' ? '16px' : '14px'}
                      lineHeight='40px'
                      QS
                    >
                      No guest found
                    </Text>
                  </Col>
                </StyledTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <PaginationView
          total={data?.totalPages}
          current={data?.page}
          hasNext={data?.hasNextPage}
          hasPrev={data?.hasPrevPage}
          handler={handler}
        />
      </TableContainer>
    </React.Fragment>
  )
}

export default CustomTable
